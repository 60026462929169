#video-links-list{
	display: block;
	margin: 20px auto;
}

#products-list{
	padding: 0px 0px 80px;
	width: 100%;
	position: relative;
	.product-list-btn{
		position: absolute;
		z-index: 9999;
		top: 245px;
		width: 60px;
		height: 60px;
		outline: none;
		border: none;
		box-shadow: none;
		transition: all 0.6s ease-in-out;
		background-color: transparent;
		text-align: center;
		padding: 0px;
		@include media-breakpoint-up(sm) {
			display: none;
		}
		span{
			width: 54px;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: relative;
			border-radius: 100%;
			background-color: $dark;
			transition: all 0.6s ease-in-out;
		}
		&.prev{
			left: 0px;
		}
		&.next{
			right: 0px;
		}
		&:hover,
		&:focus
		&:active{
			outline: none;
			border: none;
			box-shadow: none;
			span{
				background-color: $light-blue;
			}
		}
	}
	.products-table{
		display: flex;
		width: 100%;
		@include media-breakpoint-down(lg) {
			flex-wrap: wrap;
		}
		.products-left{
			display: flex;
			flex-direction: column;	
			width: 100%;
			max-width: 30%;
			@include media-breakpoint-down(lg) {
				width: 100%;
				max-width: 100%;
				justify-content: center;
			}			
		}
		.offer-items{
			width: 100%;
		}
		.products-rigth{			
			width: 100%;
			border-radius: 8px;
			background-color: #DEF0E7;	
			@include media-breakpoint-up(sm) {
				display: flex;
				.offer-items{
					display: flex;
					.offer-box{
						display: flex;
						flex-direction: column;
						width: 50%;
						.offer-box-form{
							display: flex;
							flex-direction: column;
						}
						
					}
				}
			}
		}
		.offers{
			display: flex;
			justify-content: center;
			align-items: center;
			min-height: 220px;
			position: relative;
			flex-wrap: wrap;
			padding: 16px 0px;
			border:2px solid transparent;
			&.bb-none{
				border: none;
				min-height: 218px;
				@include media-breakpoint-down(lg) {
					min-height: 80px;
				}
			}
			&.bb{
				border-bottom-color: $white;
				&.colored{
					border-bottom-color: #2B5780;
				}
			}
			&.br{
				border-right-color: $white;
			}
			@include media-breakpoint-down(sm) {
				border-right:none !important;
			}
			h3{
				margin: 0px;
				padding: 0px;
				color: $dark;
				font-size: 32px;					
				font-family: $font-family-monospace;
				text-align: left;
				width: 100%;
				@include media-breakpoint-down(lg) {
					text-align: center;
				}
			}
			p{
				width: 100%;
				display: block;
				margin: 0px;
				padding: 0px;
				color: $dark;
				font-size: $font-size-sm;					
				text-align: center;
			}
			.offer-price{
				display: flex;
				align-items: flex-start;
				justify-content: center;
				width: 100%;
				padding: 22px 8px 16px;
				text-align: center;
				align-content: center;
				.currency{
					color: $dark;
					font-size: $font-size-lg;
					font-weight: 700;
				}
				mark{
					color: $dark;
					font-size: 52px;
					font-weight: 700;
					line-height: 1;
					padding: 0px;
				}
			}
			.offer-benefits{
				margin: 0 auto;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;				
				label{
					margin: 0 auto;
					display: block;
					border-radius: 34px;
					border:1px solid $dark;
					padding: 12px 20px;
					text-align: center;
					color: $dark;
					font-weight: 700;
				}
				&.colored{
					label{
						border-color: $yellow-arrows;
						color: $yellow-arrows;
					}
				}

			}
		}
		.course-offers{
			display: flex;
			flex-direction: column;
			background-color: $dark;
			border-radius: 4px 0 0 4px;
			border-top: 2px solid #2B5780;
			@include media-breakpoint-down(lg) {
				display: none;
			}
		}
		.offer-row{
			display: flex;
			min-height: 90px;
			border:none;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: relative;
			@include media-breakpoint-down(lg) {
				justify-content: space-between;
				padding: 0 16px;
			}
			p,label{
				color: $dark;
				font-weight: 500;
			}
			&.bb{
				border-bottom: 2px solid $white;
				&.colored{
					border-bottom-color: #2B5780;
				}
			}
			&.br{
				border-right: 2px solid $white;
				&.colored{
					border-right-color: #2B5780;
				}
			}
			@include media-breakpoint-down(sm) {
				border-right:none !important;
				flex-wrap: wrap;
			}
			.offer-title{
				white-space: nowrap;
				padding: 0px 24px;
				color: $white;
				display: flex;
				align-items: center;
				align-content: center;
				position: relative;
				width: 100%;
				height: 100%;
				mark{
					font-weight: 500;
					color: $white;
				}
				&:before{
					content: "";
					width: 2px;
					top: 0px;
					background-color:#2B5780;
					left: 0px;
					position: absolute;
					display: flex;
					height: 100%;
				}
			}
			.offer-column{
				padding: 0 20px;
				text-align: center;
				display: flex;
				max-width: 120px;
				width: 120px;
				label{
					color: $white;
					font-size: $small-font-size;
					span{
						
						text-align: center;
						font-size: $font-size-lg;
						color: $white;
					}
				}
			}
			input{
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0;
				z-index: 2;
				~ .radio-btn{
					width: 18px;
					height: 18px;
					border:2px solid $dark;
					border-radius: 100%;
					display: inline-block;
					margin-right: 8px;
					position: relative;
					margin-left: auto;
					~ label{
						@include media-breakpoint-up(sm) {
							margin-right: auto;
						}						
					}
				}	
				&:checked{
					~ .radio-btn{
						&:after{
							content:"";
							width: 10px;
							height: 10px;
							background-color: $dark;
							position: absolute;
							top: 0px;
							left: 0px;
							right: 0px;
							bottom: 0px;
							margin: auto;
							display: block;
							border-radius: 100%;
						}
					}	
				}			
			}
		}
		.offers-button{
			display: flex;
			min-height: 96px;
			border:none;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: relative;
			&.bb{
				border-bottom: 2px solid $white;
				&.colored{
					border-bottom-color: #2B5780;
				}
			}
			&.br{
				border-right: 2px solid $white;
				&.colored{
					border-right-color: #2B5780;
				}
			}
			@include media-breakpoint-down(sm) {
				border-right:none !important;
			}
			.btn{
				display: flex;
				padding: 17px 28px;
				align-items: center;
				align-content: center;
				max-width: 230px;
				margin: 0 auto;
				width: 100%;
				text-align: center;
				justify-content: center;
				font-weight: 700;
				outline: none;
				box-shadow: none;
				&.btn-outline-primary{
					color: $dark;
				}
				&.btn-primary{
					color: $dark;

				}
			}

		}
	}
}



.custom-form-holder{
	position: relative;
	max-width: 560px;
	margin: 0 auto;
	padding-bottom: 40px;
	&.full-width{
		max-width: 980px;
	}
	h1{
		margin: 0px auto 40px;
		padding: 0px;
		color: $dark;
		font-size: 32px;					
		font-family: $font-family-monospace;
		text-align: center;
		width: 100%;
		font-weight: 400;
		@include media-breakpoint-down(lg) {
			text-align: center;
		}
	}
	textarea{
		display: block;
		width: 100%;
		background-color: transparent;
		border: 2px solid $yellow-arrows;
		border-radius: 25px;
		padding: 1rem;
		outline: none;
		color: $dark;
		font-weight: 500;
		font-size: $font-size-base;
		font-family: $font-family-sans-serif;
	}
	.form-group{
		margin-bottom: 30px;
		position: relative;
		.col-form-label{
			padding-top: 0px;
			padding-bottom: 12px;
			padding-left: 16px;
			font-size: $font-size-sm;
			font-weight: 400;
			color: $gray-800;
		} 
		.forgot-pass-link{
			position: absolute;
			right: 16px;
			top: 0px;
			font-weight: 700;
			color: $dark;
			font-size: $font-size-sm;
		}
		.btn{
			margin: 0 auto;
			min-width: 230px;
			align-self: center;
			justify-content: center;
			display: flex;
		}
		&.form-btn{
			padding-top: 28px;
		}
		&.label-content{
			text-align: center;
			label{
				color: $dark;
				font-size: $font-size-sm;
				margin: 0px;
				padding: 12px 0px;
				text-align: center;
				display: block;
			}
			a.link{
				color: $dark;
				font-size: $font-size-sm;
				margin-bottom: 12px;
				text-align: center;
				font-weight: 700;
			}
		}
		&.social-content{
			position: relative;
			width: 100%;
			a{
				display: flex;
				margin: 0 0px 20px;
				width: 100%;
				min-height: 49px;
				align-items: center;
				align-content: center;
				border-radius: 25px;
				border: 2px solid $yellow-arrows;
				padding: 8px;				
				span{
					border-radius: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					width: 33px;
					height: 33px;
					min-width: 33px;
				}
				label{
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					position: relative;
					font-weight: 500;
					color: $dark;
					text-align: center;
					line-height: 1;
					margin: 0;
					padding: 0 10px;
				}
				&.btn-google{
					span{
						background-color: #DB4437
					}
				}
				&.btn-facebook{
					span{
						background-color: #4267B2
					}
				}

			}
		}
	}
	.course-offers{
		display: flex;
		flex-direction: column;
		background-color: $dark;
		border-radius: 25px;
		border: none;
		margin-bottom: 40px;
	}
	.offer-row{
		display: flex;
		min-height: 90px;
		border:none;
		justify-content: center;
		align-items: center;
		align-content: center;
		position: relative;
		@include media-breakpoint-down(lg) {
			justify-content: space-between;
			padding: 0 16px;
		}
		p,label{
			color: $dark;
			font-weight: 500;
		}
		&.bb{
			border-bottom: 2px solid $white;
			&.colored{
				border-bottom-color: #2B5780;
			}
		}
		&.br{
			border-right: 2px solid $white;
			&.colored{
				border-right-color: #2B5780;
			}
		}
		@include media-breakpoint-down(sm) {
			border-right:none !important;
			flex-wrap: wrap;
		}
		.offer-title{
			white-space: nowrap;
			padding: 0px 24px;
			color: $white;
			display: flex;
			align-items: center;
			align-content: center;
			position: relative;
			width: 100%;
			height: 100%;
			mark{
				font-weight: 500;
				font-size: $font-size-lg;
				color: $light;
			}
			@include media-breakpoint-down(sm) {
				padding: 0px 8px;
			}
		}
		.offer-price{
			padding: 0 20px;
			text-align: center;
			display: flex;
			margin-left: auto;
			justify-content: center;
			text-align: center;
			align-content: center;
			.currency{
				color: $light;
				font-size: $font-size-sm;
				font-weight: 700;
				padding: 0px;
				margin: 0 3px 0 0;
				line-height: 1.3;
			}
			mark{
				color: $light;
				font-size: $font-size-lg;
				font-weight: 700;
				line-height: 1;
				padding: 0px;
			}
		}
	}
}



.field-box{
	width: 100%;
	border-radius: 25px;
	display: block;
	margin: 0 auto 15px;
	fieldset {
		border-style: none;
		padding: 0;
		background: transparent;
		border-radius: 25px;
		box-shadow: none;
		border: none;
		width: 100%;
		margin: 0px 0 0px;
		&.paypal{
			margin-top: auto;
		}
		.field {
		  	width: 100%;	
			&.half-width {
			  width: calc(50% - (5px / 2));
			}

			&.third-width {
			  width: calc(33% - (5px / 3));
			}

			& + .field {
			  margin-top: 6px;
			}
		}
		legend {
		  float: left;
		  width: 100%;
		  padding-top: 0px;
		  padding-bottom: 12px;
		  padding-left: 16px;
		  font-size: $font-size-sm;
		  font-weight: 400;
		  color: $gray-800;
		  margin: 0px;
		  &+* {
			  clear: both;
		  }
		}
		.card-only {
		  display: flex;
	      min-width: 70px;
	      white-space: nowrap;
	      justify-content: flex-start;
	      align-items: center;
	      align-content: center;
	      padding-top: 0px;
		  padding-bottom: 12px;
		  padding-left: 16px;
		  font-size: $font-size-sm;
		  font-weight: 400;
		  color: $gray-800;
		  margin: 0px;
	      i{
	      	margin-right: 4px;
	      	color: #007ec0;
	      }
		}
		.payment-request-available {
		  display: none;
		}
		/*input, button {
		  -webkit-appearance: none;
		  -moz-appearance: none;
		  appearance: none;
		  outline: none;
		  border-style: none;
		  color: #fff;
		}
		input{
			-webkit-animation: 1ms void-animation-out;
			&:-webkit-autofill {
			  transition: background-color 100000000s;
			  -webkit-animation: 1ms void-animation-out;
			}
		}*/
		.btn-dark{
			display: flex;
			justify-content: center;
			align-self: center;
			align-items: center;
			margin: 12px auto 0px;
			min-height: 52px;
		}
		.practice-card{
			background-color: transparent;			  
		    border-radius: 25px;
		    padding: 1rem;
		    border: 2px solid $yellow-arrows;
		}
		
		#stripe-card {
		  padding: 10px;
		  margin-bottom: 2px;
		}
	}
}
#pay-paypal-bottom{
	display: flex;
	margin: 0 0px 20px;
	width: 100%;
	min-height: 46px;
	align-items: center;
	align-content: center;
	border-radius: 25px;
	border: 2px solid $yellow-arrows;
	padding: 6px;	      
    .paypal-logo {
      	display: inline-block;
      	text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
	    font-family: Verdana, Tahoma;
	    font-weight: bold;
	    font-size: 26px;
	    margin: 0 auto;
	    i:first-child {
	      color: #253b80;
	    }
	    i:last-child {
	      color: #179bd7;
	    }
	  
    }	 

}