#loading-overlay, #loader-bg{  position: fixed; height: 100%;display: none; z-index: -1; bottom: 0; left: 0; top: 0px; right: 0px; width: 100%; background-color: rgba(255,255,255,0.92); backdrop-filter: blur(6px); }
#loading-overlay #loading-overlay-loader,#loader-bg #loader{text-align: center; width: 100%; max-width: 380px; height: 160px;; min-height: 100px; position: absolute; left: 0px; right: 0px; bottom: 0px; top: 0px; margin: auto; display: block; }
#loading-overlay.showed,#loader-bg.showed{ display: block; z-index: 2000;}
.braintree-loader__container > img{padding-left: 10px;margin-top: -16px;}
.invalid-feedback{padding-bottom: 2px;padding-left: 16px;}
.only-btn-to-cart{
	margin: 20px auto 12px;
	display: flex;
	justify-content: center;
	align-items:center;
	align-content:center;
	width: 100%;	
	&.with-description{
		margin: 32px auto 24px;
		background-color: #2B5780;
		padding: 40px 44px;
		border-radius:8px;
		justify-content: space-between;
		@include media-breakpoint-down(xxl){
			padding: 32px 24px;
		}
		@include media-breakpoint-down(xl){
			padding: 16px;
			flex-direction: column;
		}
	}
	&.only-btn{
		padding:0px !important;
		background:none !important;
		flex-direction: column;
		text-align: center;
		color: $white;
		position: relative;
		mark{
			color: $white;
			font-size: $font-size-base;
			font-family: $font-family-monospace;
			text-align: center;
			display: block;
			font-weight: 700;
		}
	}
	.centered-btn{
		margin: 20px auto 10px;
		text-align: center;
		font-size: $font-size-lg;
		min-width: 280px;
		max-width: 280px;
	}
	.lets-start-content{
		display: flex;
		flex-direction: column;
		position:relative;
		align-items:flex-start;
		h4{
			color: $white;
			font-size: 24px;
			font-family: $font-family-monospace;
			text-align: left;
			display: block;
			font-weight: 700;
			margin: 0 0 8px 0;
			padding: 0px 16px 0 0;
		}
		p{
			font-size: $font-size-sm;
			color: $gray-300;
			text-align: left;
			margin: 0px;
			padding: 0px 16px 0 0;
		}
	}
}

.form-select,
.form-control{
	min-height:54px;
	&.is-valid{
		border-color: $yellow-arrows;
		background: none !important;
	}
	&:hover,
	&:focus{
		border-color: $yellow-arrows;
		background: none !important;
	}
}
.form-select{
	line-height: 1.3;
	optgroup {
	 font-size: 0px;
	 border:none;
	 padding: 0px 25px;
	 margin: 0px;
	 line-height: 1.5;
	 option{
	 	 font-size: 18px;
	 	 line-height: 1.5;
	 }
	}
}
a{
	text-decoration: none;
}
.no-js .lazyload {
	display: none;
}
.lazyload,
.lazyloading {
	opacity: 0;
}
.was-validated .form-control:invalid, .form-control.is-invalid{
	background-position: 98% center;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 400ms;
}
img.lazyload:not([src]) {
	visibility: hidden;
}

.container-scroller{
	width: 100%;
	/*overflow-x: hidden;*/
}
.container{
	position: relative;
	@include media-breakpoint-down(sm) {
		min-width: 40px;
		padding-right: var(--bs-gutter-x, 0.40rem);
    padding-left: var(--bs-gutter-x, 0.40rem);
	}
}
.row{
	--bs-gutter-x: 0rem;
}
#notification_content,
#preview-mode-notification{
	position: fixed;
	right: 10px;
	bottom: 10px;
	z-index: 9;
	width: 280px;
	height: auto;
	.alert,
	.preview-mode-text{
		position: relative;
		width: 100%;
		p{
			label{
				display: block;
			}
		}
		.btn{
			width: 40px;
			min-width: 40px;
			max-width: 40px;
			height: 40px;
			position: absolute;
			right: 5px;
			top: 5px;
			border-radius: 100%;
			padding: 0px;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			font-size: 24px;
		}
	}
	.alert{
		p{
			padding-right: 30px;
		}
	}
}
.btn{
	outline: none;
	box-shadow: none;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-weight: 700;
	min-width: 180px;
	span,label{
		cursor: pointer;
	}
}
.btn-sm{
	min-width: 90px;
}
.btn-sm,
.btn-group-sm > .btn{
	@include media-breakpoint-down(sm) {
		min-width: 40px;
	}
}
.btn-dark{
	position: relative;
	&:hover,
	&:focus{
		background-color:#407DB6;
		border-color:#407DB6;
		color: $white;
	}
}
.btn-outline-dark{
	position: relative;
	color: $dark;
	&:hover,
	&:focus{
		background-color:transparent;
		border-color:#407DB6;
		color: #407DB6;
	}
}
.btn-primary{
	position: relative;
	color: $dark;
	&:hover,
	&:focus{
		background-color:#3AA8D6;
		border-color:#3AA8D6;
		color: $dark;
	}
}
.btn-secondary{
	position: relative;
	color: $dark;
	&:hover,
	&:focus{
		background-color:#DCB26A;
		border-color:#DCB26A;
		color: $dark;
	}
}

.buy-now-section{
	padding: 30px 0px 50px;
	position: relative;
	width: 100%;
	background-color: $dark-blue;
}

#posts-list{
	padding: 60px 0px 40px;
	position: relative;
	width: 100%;
	background-color: $dark-blue;
	.slick-track
	{
	    display: flex !important;
	}
	.slick-slide
	{
	    height: inherit !important;
	    > div{
	    	height: 100% !important;
	    }
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 50px auto 30px;
		position:relative;
		list-style: none;
		li{
			display: flex;
			margin: 0 4px;
			button{
				display: flex;
				width: 16px;
				height: 16px;
				font-size: 0px;
				background-color: $yellow-headline;
				border-radius:100%;
				border:0px solid transparent;
				outline: none;
			}
			&.slick-active{
				button{
					background-color: $yellow-arrows;
				}
			}
		}
	}
	.posts-list-btn{
		position: absolute;
		z-index: 2;
		top: 80px;
		width: 54px;
		height: 54px;
		outline: none;
		border: none;
		box-shadow: none;
		transition: all 0.4s ease-in-out;
		background-color: transparent;
		text-align: center;
		padding: 0px;
		display: none !important;
		@include media-breakpoint-down(xxl) {
			display: inline-block !important;
		}
		@include media-breakpoint-down(md) {
			top: 75px;
		}
		span{
			width: 54px;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: relative;
			border-radius: 100%;
			background-color: $yellow-arrows;
			transition: all 0.4s ease-in-out;
		}
		&.prev{
			left: 0px;
			@include media-breakpoint-up(xxl) {
				left: -10px;
			}
		}
		&.next{
			right: 0px;
			@include media-breakpoint-up(xxl) {
				right: -14px;
			}
		}
		&:hover,
		&:focus
		&:active{
			outline: none;
			border: none;
			box-shadow: none;
			span{
				background-color: $yellow-btn;
			}
		}
	}
	.posts-list-holder{
		 &.masonry{
			 column-count: 3;
	  		 column-gap: 16px;
	  		 .post-box{
	  		 	margin: 0;
				  display: grid;
				  grid-template-rows: 1fr auto;
				  margin-bottom: 10px;
				  break-inside: avoid;
				  height: 100%;
				  min-width: 350px;
				  &.landscape {
				  	grid-template-rows: 2fr auto;
				 }
				 .post-container {
					position: relative;
					padding-bottom: 84.25%;
					padding-top: 35px;
					height: 0;
					overflow: hidden;
					background-color:$white;
					iframe{
						border:none;
						overflow:hidden;
						position: absolute;
						top:0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				}
				.normal-container{
					background-color:$white;
					.twitter-tweet{
						margin: 0 !important;
						iframe{
							border:none !important;
							overflow:hidden !important;
						}
					}
				}
	  		 }
		 }
		.post-box{
			position: relative;
			padding: 8px;
			height: 100%;
			@include media-breakpoint-down(md) {
				padding: 0px;
			}
			.instagram-media{
				background:#FFF; border:0;
				border-radius:3px;
				box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15);
				margin: 1px;
				max-width:540px;
				min-width:326px;
				padding:0;
				width:99.375%;
				width:-webkit-calc(100% - 2px);
				width:calc(100% - 2px);
				.instagram-media-div-1{
					padding:16px;
					.instagram-media-link-1{
						background:#FFFFFF;
						line-height:0;
						padding:0 0;
						text-align:center;
						text-decoration:none;
						width:100%;
						.instagram-media-div-2{
							display: flex;
							flex-direction: row;
							align-items: center;
							.instagram-media-nested-1{
								background-color: #F4F4F4;
								border-radius: 50%;
								flex-grow: 0;
								height: 40px;
								margin-right: 14px;
								width: 40px;
							}
							.instagram-media-nested-2{
								display: flex;
								flex-direction: column;
								flex-grow: 1;
								justify-content: center;
								.instagram-media-nested-first{
									background-color: #F4F4F4;
									border-radius: 4px;
									flex-grow: 0;
									height: 14px;
									margin-bottom: 6px;
									width: 100px;
								}
								.instagram-media-nested-second{
									background-color: #F4F4F4;
									border-radius: 4px;
									flex-grow: 0;
									height: 14px;
									width: 60px;
								}
							}
						}
						.instagram-media-div-3{
							padding: 19% 0;
						}
						.instagram-media-div-4{
							display:block;
							height:50px;
							margin:0 auto 12px;
							width:50px;
						}
						.instagram-media-div-5{
							padding-top: 8px;
							> div {
								color:#3897f0;
								font-family:Arial,sans-serif;
								font-size:14px;
								font-style:normal;
								font-weight:550;
								line-height:18px;
							}
						}
						.instagram-media-div-6{
							padding: 12.5% 0;
						}
						.instagram-media-div-7{
							display: flex;
							flex-direction: row;
							margin-bottom: 14px;
							align-items: center;
							.nested-1{
								.instagram-media-nested-1{
									background-color: #F4F4F4;
									border-radius: 50%;
									height: 12.5px;
									width: 12.5px;
									transform: translateX(0px) translateY(7px);
								}
								.instagram-media-nested-2{
									background-color: #F4F4F4;
									height: 12.5px;
									transform: rotate(-45deg) translateX(3px) translateY(1px);
									width: 12.5px;
									flex-grow: 0;
									margin-right: 14px;
									margin-left: 2px;
								}
								.instagram-media-nested-3{
									background-color: #F4F4F4;
									border-radius: 50%;
									height: 12.5px;
									width: 12.5px;
									transform: translateX(9px) translateY(-18px);
								}
							}
							.nested-2{
								margin-left: 8px;
								.instagram-media-nested-1{
									background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 20px; width: 20px;
								}
								.instagram-media-nested-2{
									width: 0; height: 0; border-top: 2px solid transparent; border-left: 6px solid #f4f4f4; border-bottom: 2px solid transparent; transform: translateX(16px) translateY(-4px) rotate(30deg)
								}
							}
							.nested-3{
								margin-left: auto;
								.instagram-media-nested-1{
									width: 0px; border-top: 8px solid #F4F4F4; border-right: 8px solid transparent; transform: translateY(16px);
								}
								.instagram-media-nested-2{
									background-color: #F4F4F4; flex-grow: 0; height: 12px; width: 16px; transform: translateY(-4px);
								}
								.instagram-media-nested-3{
									width: 0; height: 0; border-top: 8px solid #F4F4F4; border-left: 8px solid transparent; transform: translateY(-4px) translateX(8px);
								}
							}
						}
						.instagram-media-div-8{
							display: flex;
							flex-direction: column;
							flex-grow: 1;
							justify-content: center;
							margin-bottom: 24px;
							.instagram-media-nested-1{
								background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 224px;
							}
							.instagram-media-nested-2{
								background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 144px;
							}
						}
					}
					p.instagram-media-p-1{
						color:#c9c8cd;
						font-family:Arial,sans-serif;
						font-size:14px;
						line-height:17px;
						margin-bottom:0;
						margin-top:8px;
						overflow:hidden;
						padding:8px 0 7px;
						text-align:center;
						text-overflow:ellipsis;
						white-space:nowrap;
						> a{
							color:#c9c8cd;
							font-family:Arial,sans-serif;
							font-size:14px;
							font-style:normal;
							font-weight:normal;
							line-height:17px;
							text-decoration:none;
						}

					}
				}
			}
		}
	}
}

#courses-list{
	padding: 80px 0px 30px;
	position: relative;
	width: 100%;
	.slick-track
	{
	    display: flex !important;
	}
	.slick-slide
	{
	    height: inherit !important;
	    > div{
	    	height: 100% !important;
	    }
	}
	.slick-dots{
		display: flex;
		justify-content: center;
		width: 100%;
		margin: 50px auto 30px;
		position:relative;
		list-style: none;
		li{
			display: flex;
			margin: 0 4px;
			button{
				display: flex;
				width: 16px;
				height: 16px;
				font-size: 0px;
				background-color: rgba(19, 68, 114, 0.7);
				border-radius:100%;
				border:0px solid transparent;
				outline: none;
			}
			&.slick-active{
				button{
					background-color: $dark-blue;
				}
			}
		}
	}
	.courses-list-holder{
		@include media-breakpoint-up(lg) {
			// display: flex;
			// flex-wrap: wrap;
			// width: 100%;
			.course-box{
				// max-width: 33.33%;
				// height: auto;
				span.orderNo{
					font-size: 48px !important;
				}
			}
		}
	}
	.courses-list-btn{
		position: absolute;
		z-index: 2;
		top: 255px;
		width: 54px;
		height: 54px;
		outline: none;
		border: none;
		box-shadow: none;
		transition: all 0.4s ease-in-out;
		background-color: transparent;
		text-align: center;
		padding: 0px;
		display: inline-block !important;
		@include media-breakpoint-down(md) {
			top: 75px;
		}
		span{
			width: 54px;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: relative;
			border-radius: 100%;
			background-color: $yellow-arrows;
			transition: all 0.4s ease-in-out;
		}
		&.prev{
			left: 0px;
			@include media-breakpoint-up(xxl) {
				left: -10px;
			}
		}
		&.next{
			right: 0px;
			@include media-breakpoint-up(xxl) {
				right: -14px;
			}
		}
		&:hover,
		&:focus
		&:active{
			outline: none;
			border: none;
			box-shadow: none;
			span{
				background-color: $yellow-btn;
			}
		}
	}
	.course-box{
		position: relative;
		padding: 8px;
		height: 100%;
		@include media-breakpoint-down(md) {
			padding: 0px;
		}
		.course-box-explain{
			width: 100%;
			border-radius: 14px;
			background-color: $dark;
			display: flex;
			flex-direction: column;
			padding: 22px 32px;
			height: 100%;
			box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			@include media-breakpoint-down(xl) {
				padding: 22px 22px;
			}
			@include media-breakpoint-down(sm) {
				padding: 8px;
			}
			.course-box-top{
				display: flex;
				width: 100%;
				flex-direction: column;
				max-width: 500px;
				.course-header{
					display: flex;
					width: 100%;
					min-height: 90px;
					@include media-breakpoint-down(xl) {
						flex-wrap: wrap;
					}
					.course-number{
						width: 70px;
						min-width: 70px;
						text-align: center;
						display: flex;
						align-self: flex-start;
						@include media-breakpoint-down(xl) {
							align-self: center;
							margin: auto;
						}
						span.orderNo{
							color: $light;
							font-weight: 700;
							font-size: 84px;
							line-height: 1;
							@include media-breakpoint-down(sm) {
								margin-bottom: 20px;
								margin-top: 20px;
							}
						}
					}
					.course-name{
						display: flex;
						width: 100%;
						align-items: center;
						align-content: center;
						align-self: flex-start;
						h2{
							color: $light;
							font-size: 29px;
							text-align: center;
							font-family: $font-family-monospace;
							padding: 28px 0px 0px 15px;
							margin: 0px;
							a{
								color: $light;
								font-size: 29px;
								text-align: center;
								font-family: $font-family-monospace;
								&.hover,
								&.focus{
									color: $light-green;
								}
							}
							@include media-breakpoint-up(lg) {
								padding: 12px 0px 8px;
								margin: 0 auto 0 0;
								font-size: 24px;
								text-align: left;
								a{
									font-size: 24px;
									text-align: left;
								}
							}
							@include media-breakpoint-between(sm, lg) {
								padding: 28px 0px 0px;
							}
						}
					}
				}
				.course-description{
					padding: 8px 0px 12px 0px;
					min-height: 180px;
					p{
						color: $light;
					}
					@include media-breakpoint-down(xl){
						padding-left: 0px;

					}
				}
			}
			.course-box-content{
				padding: 0px;
				position: relative;
				max-width: 500px;
				@include media-breakpoint-down(xl){
					padding-left: 0px;
				}
				.course-free-chapter{
					position: relative;
					width: 100%;
					margin-bottom: 48px;
					@include media-breakpoint-down(md){
						margin-bottom: 20px;
					}
					.chapter-image{
						position: relative;
						width: 100%;
						overflow: hidden;
						border-radius: 12px;

						picture{
						  width: 100%;
						  height: 100%;
						  display: flex;
							img{
								object-fit: cover;
							    height: auto;
							    width:100%;
							}
						}
					}
					.chapter-cta{
						position: absolute;
						bottom: -24px;
						text-align: center;
						width: 100%;
						a.btn{
							padding: 8px 28px;

							label{
								cursor: pointer;
								color: $dark;
								font-weight: 700;
								margin-right: 12px;

							}

						}
					}
				}
				.chapters-list{
					display: flex;
					flex-direction: column;
					position: relative;
					margin-bottom: 20px;
					.chapter-row{
						position: relative;
						margin-bottom: 16px;
						width: 100%;
						.chapter-title{
							width: 100%;
							h3{
								color: $light;
								font-size: $font-size-base;
								padding: 0px;
								margin: 0px;
								font-weight: 700;
							}
						}
						.chapter-description{
							position: relative;
							p{
								color: $light;
								margin-top:5px;
							}
						}

					}
				}
			}
			.course-box-cta{
				margin-top: auto;
				.course-buy-btn{
					text-align: center;
					.btn{
						cursor: pointer;
						color: $dark;
						font-weight: 700;
						margin-bottom: 12px;

					}
				}
			}
		}

	}
	#course-details{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		transition: all 0.6s ease-in-out;
		min-height: 100%;
		&.active{
			opacity: 1;
			visibility: visible;
			z-index: 3;
		}
		@include media-breakpoint-down(sm) {
			> .container{
				padding: 0 6px;
			}
		}
		.courses-list-btn{
			top: 40%;
			z-index: 4;
			@include media-breakpoint-down(md) {
				top: 75px;
			}
		}
		.close-details-view{
			position: absolute;
			width: 42px;
			height: 42px;
			right: 25px;
			top: 25px;
			z-index: 2;
			@include media-breakpoint-down(sm) {
				right: 30px;
				top: 12px;
			}
		}
	}
	.course-details-section{
		position: relative;
		padding: 6px;
		width: 100%;
		min-height: 100%;
		height: 100%;
		.course-details-explain{
			width: 100%;
			border-radius: 14px;
			background-color: $dark;
			display: flex;
			flex-direction: column;
			padding: 22px 32px;
			background: $dark;
			border-radius: 14px;
			height: 100%;
			box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			@include media-breakpoint-down(xl) {
				padding: 22px 22px;
			}
			@include media-breakpoint-down(lg) {
				> .row{
					max-width: 640px;
					margin: 0 auto;
				}
			}
			@include media-breakpoint-down(sm) {
				padding: 0px;
			}
			.course-details-top{
				display: flex;
				width: 100%;
				flex-direction: column;
				max-width: 100%;
				.course-header{
					display: flex;
					width: 100%;
					min-height: 100px;
					@include media-breakpoint-down(xl) {
						flex-wrap: wrap;
					}
					.course-number{
						width: 70px;
						min-width: 70px;
						text-align: center;
						display: flex;
						align-self: flex-start;
						@include media-breakpoint-down(lg) {
							align-self: center;
							margin: auto;
						}
						span.orderNo{
							color: $light;
							font-weight: 700;
							font-size: 84px;
							line-height: 1;
							@include media-breakpoint-down(sm) {
								margin-bottom: 20px;
								margin-top: 20px;
							}
						}
					}
					.course-name{
						display: flex;
						width: 100%;
						align-items: center;
						align-content: center;
						align-self: center;
						justify-content: center;
						h2{
							color: $light;
							font-size: 32px;
							text-align: center;
							font-family: $font-family-monospace;
							font-weight: 400;
							padding: 0px;
							margin: 0px;
						}
					}
				}
				.course-description{
					padding: 36px 0px 12px 0px;
					min-height: 100px;
					p{
						color: $light;
					}
					@include media-breakpoint-down(xl){
						padding-left: 0px;

					}
					.course-content{
						margin: 16px 0 40px;
						display: flex;
						align-items: center;
						label{
							display: flex;
							align-items: center;
							margin-right: 32px;
							color: $light;
							font-weight: 700;
							span{
								margin-right: 8px;
							}
						}
					}
				}
			}
			.course-details-video{
				max-width: 960px;
				margin: 0 auto 44px;
				position: relative;
				padding: 36px 0px 12px 100px;
				@include media-breakpoint-down(xl){
					padding-left: 0px;
				}
				.video-frame{
					border-radius: 14px;
					position: relative;
					padding-top: 56.25%;
					a.load-video-link{
						border: none;
						position: absolute;
						top: 0;
						left: 0px;
						height: 100%;
						width: 100%;
						border-radius: 14px;
						display: flex;
						justify-content: center;
						align-items: center;
						align-content: center;
						cursor: pointer;
						overflow: hidden;
						outline: none;
						&.video-link-loaded{
							display: none;
						}
						picture{
						    width: 100%;
						    height: 100%;
						    display: flex;
							img{
								object-fit: cover;
							    height: auto;
							    width:100%;
							}
						}
						span.dog-icon{
							width: 90px;
							height: 90px;
							position: absolute;
							top: 0px;
							left: 0px;
							bottom: 0px;
							right: 0px;
							margin: auto;
							text-align: center;
							cursor: pointer;
						}
					}
					iframe{
						border: none;
						position: absolute;
						top: 0;
						height: 100%;
						width: 100%;
						border-radius: 14px;
					}
				}
			}
			.course-details-content{
				padding-left: 48px;
				padding-top: 28px;
				position: relative;
				@include media-breakpoint-down(xl){
					padding-left: 0px;
				}
				.course-cta-hld{
					margin-bottom: 32px;
					.btn{
						min-width: 230px;
					}
				}
				.accordion{
					padding: 8px 0px 0px 4px;
					position: relative;
					.accordion-body{
						padding: 0px;
					}
					.accordion-item{
						border:none;
						position: relative;
						margin-bottom: 28px;
						h4{
							color: $white;
							font-size: $font-size-base;
							padding: 0px;
							margin: 0px;
							font-weight: 700;
							a{
								font-weight: 700;
								color: $white;
								font-size: $font-size-base;
								padding: 0px;
								margin: 0px;
								&:after{
									@include media-breakpoint-up(lg) {
										display:none;
									}
								}
							}
						}
						.accordion-collapse{
							@include media-breakpoint-up(lg) {
								display: block;
							}
						}
						ul{
							padding-left: 0px;
						}
						.chapter-video-link{
							position: relative;
							width: 100%;
							margin: 0px;
							padding: 0px;
							list-style: none;
							a{
								position: relative;
								transition: all 0.4s ease-in-out;
								color: $light;
								font-size: $font-size-sm;
								display: flex;
								padding: 8px 0px;
								border-radius: 14px;
								label{
									cursor: pointer;
									display: flex;
									span{
										display: inline-block;
										margin-right: 4px;
									}
								}
								&:hover,
								&:focus,
								&.active{
									color: $white;
								}
								&.active{
									background-color: $light-blue;
								}
								/*&:before{
									content: "•";
									margin-right: 4px;
								}*/
							}
						}
					}
				}

			}
		}

	}
}

#articles-view,
#course-view{
	background: $dark-blue;
	border-radius: 14px;
	width: 100%;
	position: relative;
	padding: 32px 24px;
	margin-bottom:80px;
	@include media-breakpoint-down(sm) {
		padding: 12px 8px;
	}
	.sidebar-col{
		width: 100%;
		@include media-breakpoint-down(lg) {
			flex: 1 1 100%;
		}
		@include media-breakpoint-up(lg) {
			max-width: 322px;
			min-width: 322px;
			position: sticky;
			top: 0px;
		}
		@include media-breakpoint-up(xl) {
			max-width: 392px;
			min-width: 392px;
			position: sticky;
			top: 0px;
		}
		@include media-breakpoint-up(xxl) {
			max-width: 442px;
			min-width: 442px;
			position: sticky;
			top: 0px;
		}
	}
	#videos-on-course{
		width: 100%;
		@include media-breakpoint-down(lg) {
			flex: 1 1 100%;
		}
		@include media-breakpoint-up(lg) {
			max-width: calc(100% - 328px);
			margin-left: auto;
			padding-left:16px;
		}
		@include media-breakpoint-up(xl) {
			max-width: calc(100% - 408px);
			margin-left: auto;
			padding-left:16px;
		}
		@include media-breakpoint-up(xxl) {
			max-width: calc(100% - 458px);
			margin-left: auto;
			padding-left:16px;
		}
		.pagination{
			justify-content: flex-end;
			margin: 20px auto 0px;
			.disabled{
				display: none;
			}
			.page-item{
				a[rel=prev],
				a[rel=next]{
					display: none;
				}
				&:last-child{
					display:none;
				}
			}
		}
	}
	.courses-title-hld{
		display: flex;
		width: 100%;
		position: relative;
		align-items: center;
		align-content: center;
		margin-bottom: 24px;
		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
			display: none;
		}
		.courses-index{
			padding: 0px 20px 6px;
			position: relative;
			h1{
				color: $yellow-headline;
				font-size: 32px;
				font-family: $font-family-monospace;
				margin: 0px;
			}
		}
		.courses-text{
			padding: 0px 20px 6px;
			position: relative;
			label{
				color: $yellow-headline;
				font-size: 32px;
				font-family: $font-family-monospace;
				margin: 0px;
			}
		}
		.courses-title{
			padding: 0px 10px;
			display: flex;
			align-items: center;
			align-content: center;
			a.remove-filter{
				width: 24px;
				height: 24px;
				position: relative;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				align-content: center;
				align-items: center;
				background-color: #2B5780;
				color: $light;
				margin-right: 6px;
				font-size: $small-font-size;
				text-align: center;
				transition: all 0.4s ease-in-out;
				&:before{
					content:"✖";
					color: $light;
					font-size: $small-font-size;
					font-family: $font-family-monospace;
					position: absolute;
					top: 0px;
					left: 0px;
					bottom: 0px;
					right: 0px;
					margin: auto;
					display: flex;
					justify-content: center;
					align-content: center;
					align-items: center;
					width: 24px;
					height: 24px;
					text-align: center;
				}
				&:hover,
				&:focus{
					background-color: $light-blue;
					color: $dark;
					&:before{
						color: $dark;
					}
				}
			}
			h1,label{
				color: $light;
				font-size: $font-size-sm;
				margin: 0px;
				padding: 0px;
			}
		}
	}
	.sidebar-menu-mobile{
		display: none;
		@include media-breakpoint-down(lg) {
			display: flex;
			align-items: center;
			align-content: center;
			width: 100%;
			margin-bottom: 20px;
			label{
				color: $light;
				font-size: $font-size-base;
				margin: 0px;
				padding: 0px;
			}
			button#articles-menu-sidebar,
			button#courses-menu-sidebar{
				display: flex;
				width: 40px;
				height: 40px;
				margin-left: auto;
				justify-content: center;
				align-content: center;
				align-items: center;
				transition: all 0.4s ease-in-out;
				position: relative;
				padding: 0px;
				outline: none;
				border: none;
				text-align: center;
				border-radius: 0px;
				background-color: transparent;
				&:hover,
				&:focus,
				&:active{
					box-shadow: none;
					outline: none;
					border: none;
					background-color: transparent;
				}
				.menu{
					display: block;
				}
				.close{
					display: none;
				}
				&.opened{
					.menu{
						display: none;
					}
					.close{
						display: block;
					}
				}

			}
		}
	}
	.sidebar-boxes{
		@include media-breakpoint-up(lg) {
			display: block !important;

		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	.sidebar-box{
		position: relative;
		padding: 0px;
		border:1px solid #2B5780;
		border-radius: 14px;
		color: $light;
		margin-bottom: 20px;
		@include media-breakpoint-down(lg) {
			width: 100%;
		}
		&.active{
			background-color: #2B5780;
			@include media-breakpoint-down(lg) {
				.accordion{
					display: block !important;
				}
			}
		}
		&.always-active{
			background-color: #2B5780;
			display: block !important;
		}
		.sidebar-box-title{
			position:relative;
			padding: 16px 32px;
			border-radius: 14px 14px 0 0;
			background-color:#466C90;
			color: $white;
			@include media-breakpoint-down(lg) {
				display: none;
			}
			h2{
				color: $white;
				font-size: $font-size-base;
				font-weight: 700;
				font-family: $font-family-monospace;
				margin: 0px;
				span,label{
					margin: 0 4px 0 0px;
				}
			}
		}
		.sidebar-box-content{
			position:relative;
			padding: 0 16px 16px;
			@include media-breakpoint-down(lg) {
				padding:16px;
			}
		}
		.course-details-top{
			display: flex;
			width: 100%;
			flex-direction: column;
			max-width: 740px;
			.course-header{
				display: flex;
				width: 100%;
				min-height: 100px;
				a.course-videos-link{
					display: flex;
					width: 100%;
					@include media-breakpoint-down(md) {
						flex-wrap: wrap;
					}
				}
				.course-number{
					width: 40px;
					min-width: 40px;
					text-align: center;
					display: flex;
					@include media-breakpoint-down(md) {
						margin: auto;
					}
					span.orderNo{
						color: $yellow-headline;
						font-weight: 700;
						font-size: 26px;
						line-height: 1;
					}
				}
				.course-name{
					display: flex;
					width: 100%;
					align-items: center;
					align-content: center;
					h2{
						color: $yellow-headline;
						font-weight: 700px;
						font-size: 18px;
						padding: 0;
						margin: 0px;
						@include media-breakpoint-down(md) {
							padding: 0;
							text-align: center;
							margin: 0px auto 12px;
							font-size: $font-size-lg;
						}
					}
				}
			}
			.course-content{
				padding: 12px 0px 12px 32px;
				display: flex;
				label{
					color: $light;
					padding-right: 32px;
					display: flex;
					align-content: center;
					align-items: center;
					line-height: 1;
					font-weight: 700;
					font-size: $font-size-sm;
					span{
						max-width: 18px;
						max-height: 18px;
						margin-right: 12px;
					}
				}
				@include media-breakpoint-down(md){
					padding-left: 0px;
				}
			}
		}
		.accordion{
			// padding: 8px 0px 0px 26px;
			padding: 0px;
			position: relative;
			@include media-breakpoint-down(lg) {
				display: none;
			}
			.accordion-body{
				padding: 0px;
			}
			.accordion-item{
				border-top: 1px solid #47729A;
				position: relative;
				padding-left: 4px;
				&:first-child{
					border:none;
				}
				h3{
					color: $light;
					font-weight:700;
					font-size: $font-size-sm;
					.accordion-button{
						padding-left: 12px;
						position: relative;
						transition: all 0s ease-in-out;
						font-weight:700;
						font-size: $font-size-sm;
						color: $white;
						&:after{
							position:absolute;
							left: 0px;
							top: 0px;
							bottom: 0px;
							margin: auto;
							display: none;
						}
						&.collapsed{
							color: $light;
							&:hover,
							&:focus,
							&.active{
								color: $white;
							}
						}
						&:hover,
						&:focus,
						&.active{
							color: $white;
						}
					}
				}
				ul{
					padding-left: 12px;
				}
				.chapter-video-link{
					position: relative;
					width: 100%;
					margin: 0px;
					padding: 0px;
					list-style: none;
					a{
						position: relative;
						transition: all 0.4s ease-in-out;
						color: $light;
						font-size: $font-size-sm;
						display: flex;
						padding: 8px;
						border-radius: 14px;
						label{
							cursor: pointer;
							display: flex;
							span{
								display: inline-block;
								margin-right: 4px;
							}
						}
						&:hover,
						&:focus,
						&.active{
							color: $white;
						}
						&.active{
							background-color: $dark-blue;
						}
						/*&:before{
							content: "•";
							margin-right: 4px;
						}*/
					}
				}
			}
		}
	}
	.video-list-box{
		position: relative;
		width: 100%;
		padding: 0px;
		margin-bottom:16px;
		background-color: $yellow-headline;
		display: flex;
		border-radius: 14px;
		height: 100%;
		.video-list-left{
			display: flex;
			flex-direction: column;
			width: 74px;
			min-width: 74px;
			position: relative;
			.video-list-play{
				width: 100%;
				height: 74px;
				min-height: 74px;
				background-color: $yellow-arrows;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				border-radius: 14px 0 14px 0;
			}
			.video-list-duration{
				width: 100%;
				height: 100%;
				justify-content: center;
				align-self: center;
				align-items: center;
				align-content: center;
				span.dog-icon{
					width: 100%;
					text-align: center;
					margin-bottom: 10px;
					display: flex;
					justify-content: center;
					margin-top: 50px;
				}
				label{
					width: 100%;
					text-align: center;
					margin: 0;
					font-weight: 700;
					font-size: $sub-sup-font-size;
					color: $yellow-arrows;
					display: flex;
					justify-content: center;
					white-space: nowrap;
				}
			}

		}
		.video-list-right{
			display: flex;
			flex-direction: column;
			position: relative;
			padding: 0px 20px;
			overflow: hidden;
			@include media-breakpoint-down(sm){
				padding: 0px 15px;
			}
			.video-list-title{
				position: relative;
				margin-bottom: 12px;
				width: 100%;
				margin-top: 20px;
				min-height: 50px;
				display: flex;
				align-items: center;
				cursor: pointer;
				a{
					display: block;
					width: 100%;
					position: relative;
					transition: all 0.2s ease-in-out;
					cursor: pointer;
					h4{
						transition: all 0.2s ease-in-out;
						color: #766F64;
						font-size: $font-size-lg;
						font-family: $font-family-monospace;
						font-weight: 600;
					}
					label{
						font-size: $small-font-size;
						color: $dark-blue
					}
					&:hover,
					&:focus{
						h4{
							color: $yellow-arrows;
						}
					}
				}

			}
			.video-list-image{
				width:260px;
				margin: 0 auto 20px;
				@include media-breakpoint-down(md){
					width:100%;
					margin: 0 0 15px;
				}
				img {
					max-width: 100%;
					border-radius: 14px;
					@include media-breakpoint-down(md){
						width:100%;
					}

				}
			}
			.video-list-description{
				position: relative;
				width: 100%;
				p{
					width: 97%;
					margin: 0;
					font-size: $small-font-size;
					color: #766F64;
					text-align: justify;
				}
			}

		}
		.video-list-icon{
			position: absolute;
			bottom: 8px;
			right: 8px;

		}
		&.can-play{
			background-color: $light-green;
			.video-list-left{
				.video-list-play{
					background-color: $light-blue;
				}
				.video-list-duration{
					label{
						color: $dark-blue;
					}
				}
			}
			.video-list-right{
				.video-list-title{
					cursor: pointer;
					a{
						h4{
							color: $dark-blue;
						}
						label{
							font-size: $small-font-size;
							color: $dark-blue
						}
						&:hover,
						&:focus{
							h4{
								color: $light-blue;
							}
						}
					}
				}
				.video-list-description{
					p{
						color: $dark-blue
					}
				}
			}
		}

	}
}


#pagination-holder{
	display: flex;
	position: relative;
	width: 100%;
}


.steps-holder{
	display: flex;
	width: 100%;
	max-width: 340px;
	position: relative;
	align-items: center;
	align-content: center;
	margin: 0 auto 60px;
	z-index: 2;
	@include media-breakpoint-down(sm) {
		justify-content: space-between;
	}
	&:before{
		content: "";
		height: 3px;
		width: 100%;
		background-color: $dark-blue;
		position: absolute;
		left: 0px;
		top: 4px;
	}
	.step{
		display: flex;
		white-space: nowrap;
		padding: 30px 32px 0;
		.details-holder{
			display: flex;
			label{
				font-weight: 600;
				font-size: $font-size-sm;
				line-height: 1;
				text-align: center;
				color: $gray-800;
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
			&:after{
				content: "";
				position: absolute;
				top: -5px;
				width: 20px;
				height: 20px;
				border: 2px solid $yellow-headline;			
				border-radius: 100%;
				display: block;
				background-color: $yellow-headline;
				color: $dark-blue;
				font-weight: 700;
				font-family: $font-family-monospace;
				font-size: 10px;
				line-height: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
			}
		}
		&.personal-details{
			margin-left: -80px;
			@include media-breakpoint-down(sm) {
				margin-left: 0px;
				padding-left: 0px;
				padding-right: 0px;
			}
			.details-holder{
				&:after{
					left: 0px;
				}
			}
		}
		&.password-create{
			margin-left: 0px;
			@include media-breakpoint-down(sm) {
				padding-left: 0px;
				padding-right: 0px;
			}
			.details-holder{
				&:after{
					left: calc(50% - 12px);
				}
			}
		}
		&.payment{
			margin-left: 12px;
			@include media-breakpoint-down(sm) {
				margin-left: 0px;
				padding-left: 0px;
				padding-right: 0px;
			}
			.details-holder{
				&:after{
					right: 0%;
				}
			}
		}
		&.active{
			.details-holder{
				label{
					color: $dark;
					@include media-breakpoint-down(sm) {
						display: block;
					}
				}
				&:after{
					content: "⚉";
					background-color: $dark-blue;
					border-color: $dark;
					border-width: 4px;
					color: $yellow-btn;
				}
			}
		}
		&.passed{
			.details-holder{
				label{
					color: $dark;
				}
				&:after{
					content: "✓";
					background-color: $dark;
					border-color: $dark;
					color: $white;
				}
			}
		}

	}
}

.acceptable-cards{
	display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 35px 0 15px;
    @include media-breakpoint-down(sm){
      width: 100%;
      margin: 0 auto;

      .img1{
        width: 23px;
        height: 9px;
      }
      .img2{
        width: 18px;
        height: 11px;
      }
      .img3{
        width: 27px;
        height: 7px;
      }
      .img4{
        width: 38px;
        height: 7px;
      }
      .img5{
        width: 12px;
        height: 11px;
      }
      .img6{
        width: 29px;
        height: 9px;
      }
      .img7{
        width: 10px;
        height: 12px;
      }
    }
}

.reassurance{
	display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0 15px;
    list-style: none;
	li{
		color: $gray-800 !important;
		font-family: $font-family-sans-serif;
		font-weight: 700;
		font-size: $font-size-base !important;
		letter-spacing: 0.25px;
		margin-bottom: 10px;
	}
}


.dropin-container{
	margin-top:20px;
	margin-bottom: 20px;
	.braintree-upper-container{
		background-color:transparent !important;
		border-radius:25px;
		&:before{
			background-color:transparent !important;
		}
	}
	.braintree-option{
		background-color: transparent !important;
	    border-color: $input-border-color;
	    border-style: solid;
	    border-width: 0 2px 2px;
		border-bottom-width: 1px;
	}

	.braintree-option:last-child {
	    border-radius: 0 0 25px 25px;
		border-bottom-width: 2px;
	}

	.braintree-option:first-child {
	    border-radius: 25px 25px 0 0;
	    border-top-width: 2px;
	    border-bottom-width: 1px;
	}

	.braintree-option__label, .braintree-sheet__text, .braintree-sheet__label, .braintree-heading{
		font-family: $font-family-sans-serif;
	    font-size: $font-size-base !important;
	    color: $dark-blue !important;
	    letter-spacing: 0.25px;
	    font-weight: 500;
	    @media screen and (min-width: 960px){
		    font-size: $font-size-lg !important;
			line-height: 25px;
	    }
	}
	.braintree-heading{
		color: $gray-800 !important;
	    font-family: $font-family-sans-serif;
	    font-weight: 700;
	}

	.braintree-sheet {
	    background-color: transparent !important;
	    border: 2px solid $primary;
	    border-radius: 25px;
	}
	.braintree-toggle{
	    color: $gray-800;
	    font-family: $font-family-monospace;
	    padding: 6px 10px;
	    border-radius: 25px;
		background-color: transparent;
		min-width: 50%;
		max-width: 65%;
		font-weight: 700;
		span{
			border-bottom: none;
		}
	}	
	.braintree-sheet{
		border-color: transparent !important;
		.braintree-sheet__content--button{
			background-color: transparent;
			color: $gray-800;
		}
		input{
			background-color: $input-bg;
			border: none;
			display: block;
			width: 100%;
			font-family: $font-family-sans-serif;
			font-size: 14px;
			color: $input-color;
			outline: none;
			padding: 0px;
			margin: 0;
			height: auto;
			text-transform: uppercase;
			border-radius:0.25rem;			
			@media screen and (max-width: 359px){
				font-size: 12px !important;
			}
			&::-webkit-input-placeholder {
			    color: $yellow-arrows;
			}
			&::-moz-placeholder {
			    color: $yellow-arrows;
			}
			&:-ms-input-placeholder {
			    color: $yellow-arrows;
			}
			&:-moz-placeholder {
			    color: $yellow-arrows;
			}
			&:hover,
			&:focus{
				outline:none;
				box-shadow: none;
				border:none;
				background-color: transparent;
			}
		}
		.gpay-button.black.short{
			display: block;
			width: 100%;
			&.hover,
			&:hover,
			&:focus{
				background-color: #01508C;
				background-image: url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg);
			}
		}
		.braintree-sheet__content--form{
			padding: 11px 0px;
		}
		.braintree-sheet__header{
			flex-wrap: nowrap;
			border: none;
			padding: 0px;				
			.braintree-sheet__logo--header{
				display: none;
			}				
			.braintree-sheet__label,
			.braintree-sheet__text{
			    font-size: $font-size-base !important;
			    letter-spacing: 0.25px;
			    font-weight: 500;
				margin: 0;
				white-space: nowrap;
				margin-right: 5px;
				color: $gray-800 !important;
	    		font-family: $font-family-monospace !important;
	    		font-weight: 700;
				@media screen and (max-width: 359px){
					font-size: 12px !important;
				}
			}
			.braintree-sheet__icons{
				display: flex;
				margin-left: auto;
				width: 100%;
				justify-content: flex-end;
				.braintree-sheet__card-icon{
					height:  23px;
					width: auto;
					max-height: 23px;
					position: relative;
					display: block;
				}
				.braintree-hidden{
					display: none !important;
				}
			}
		}			
		.braintree-sheet__content--form{
			width: 100%;
			position: relative;				
			.braintree-form__field-group{				
				-webkit-transition: all .4s ease;
				-moz-transition: all .4s ease;
				-ms-transition: all .4s ease;
				-o-transition: all .4s ease;
				transition: all .4s ease;
				position:relative;
				padding:0px !important;
				margin: 0px !important;
				&.braintree-form__field-group--has-error{
					border-color: #dc3545;
					.braintree-form__label{
						color: #dc3545 !important
					}
				}
				.braintree-form__field-error{
					position: absolute;
					border-color: #DBF0FF;
					bottom:8px;
					padding-bottom: 2px;
					padding-left: 16px;
					font-weight:700;
					span{
						color: #DBF0FF;
					}
				}
				> label{
					display: block;
					width: 100%;
					padding: 0 8px 36px 0;
					position: relative;
					align-items: center;
					align-content: center;
					cursor: pointer;
					margin: 0px;
					.braintree-form__icon-container{
						display: none;
					}
					.braintree-form__label{
						display: block;
						font-family: $font-family-sans-serif;
						font-size: $font-size-sm;
						line-height: 1.4;
						color: $gray-800;
						white-space: nowrap;	
						margin: 0;
						padding-top: 0px;
					    padding-bottom: 12px;
					    padding-left: 16px;
						.braintree-form__descriptor{
							display: none;
						}		
						@media screen and (max-width: 359px){
							font-size: 12px !important;
							width: 140px;
						}				
					}
					.braintree-form__field{
						display: flex;
						width: 100%;
						position: relative;
						align-items: center;
						align-content: center;
						cursor: pointer;
						margin: 0px;
						margin-left: auto;
						background-color:$input-bg;
						color: $dark-blue;
						border: 2px solid $input-border-color;
						border-radius: 25px;
						padding: 0 1rem;
						.braintree-form__hosted-field{
							border: none !important;
							margin: 0;
							&:hover,							
							&:focus{
								border: none;
							}							
						}							
					}
				}
			}

		}
	}

}