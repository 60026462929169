#video-links-resources{
	position: relative;
	padding: 30px;
	background-color: $light-green;
	border-radius: 14px;
	color: $dark;
	@include media-breakpoint-down(xl) {
		padding: 24px 16px;
	}
	@include media-breakpoint-down(sm) {
		padding: 12px 8px;
	}
	.video-links-resources-filter{
		display: flex;
		align-items: center;
		align-content: center;
		width: 100%;
		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
		}
		.filter-title{
			margin-right: 28px;
			position: relative;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 16px;
			}
			h4{
				font-weight: 700;
				font-size: $font-size-base;
				color: $dark;
				margin: 0px;
				padding: 0px;
			}
		}
		.resources-filter{
			display: flex;
			align-items: center;
			align-content: center;
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				align-items: flex-start;
				align-content: flex-start;
			}
			.btn{
				min-width: 40px;
				font-weight: 400;
				font-size: $small-font-size;
				margin-left: 12px;
				border-color:$light-blue;
				color: $dark-blue;
				@include media-breakpoint-down(sm) {
					margin: 0px auto 12px 0;
				}
				&.active{
					background-color: $dark;
					color: $light-green;
					border-color: $dark
				}
			}
		}
	}
	.video-links-resources-list{
		position: relative;
		.card{
			background-color: transparent;
			border: none;
			margin-top: 40px;
			@include media-breakpoint-up(sm) {
				flex-direction: row;
			}
			.card-image-hld{
				position: relative;
				max-width: 300px;
				min-width: 300px;
				border-radius: 14px;				
				max-height: 100%;
				display: flex;
				align-items: flex-start;
				align-content: center;
				margin-right: 24px;
				overflow: hidden;
				@include media-breakpoint-down(xl) {
					max-width: 180px;
					min-width: 180px;
					picture{
					  height: auto !important;
					}
				}
				@include media-breakpoint-down(sm) {
					max-width: 100%;
					max-height: 100%;
				}
				picture{
				  width: 100%;
				  display: flex;
					img{
					    height: auto;
					    width:100%;
					    border-radius: 14px;
					}
				}
			}
			.card-body{
				position: relative;
				display: flex;
				flex-direction: column;
				width: 100%;
				position: relative;
				padding: 12px 0;
				@include media-breakpoint-up(sm) {
					padding: 0px;
				}
				h5{
					font-weight: 700;
					font-size: $font-size-base;
					font-family: $font-family-monospace;
					color: $dark;
					margin: 0px 0 16px 0;
					padding: 0px;
				}
				p{
					font-size: $font-size-sm;
					color: $dark;
					margin: 0px 0 8px 0;
					padding: 0px;
				}
				.btn-hld{
					display: flex;
					width: auto;
					position: relative;
					margin-top: 16px;
					a.btn{
						padding-left:0px;
						text-decoration: none;
						text-align: left;
						&:hover,
						&:focus,
						&:active{
							svg{
								path{
									stroke: $light-blue;
								}
							}
						}
					}
				}
			}
		}
	}
}

#video-details{
	position: relative;
	padding-left: 0px;
	@include media-breakpoint-up(xxl) {
		padding-left: 16px;
	}
	@include media-breakpoint-down(lg) {
		padding-left: 0px;
	}
	.video-title{
		width: 100%;
		position: relative;
		text-align: left;
		margin-bottom: 16px;
		@include media-breakpoint-up(xl) {
			padding-left: 8px;
		}
		h1{
			color: $white;
			font-size: 40px;
			font-family: $font-family-monospace;
			max-width: 95%;
			@include media-breakpoint-down(md) {
				font-size: 24px;
			}
			span,label{
				margin: 0 4px 0 0px;
			}
		}
	}
	.video-description{
		color: $gray-300;
		max-width: 720px;
		@include media-breakpoint-up(xl) {
			padding-left: 8px;
		}
		h2,h3,h4,h5,h6{
			color: $white;			
		}
		h2,h3{
			margin-top: 40px;
			margin-bottom: 12px;
			@include media-breakpoint-down(md) {
				margin-top:  24px;
			}
		}
		h4,h5,h6{
			margin-top: 24px;
			margin-bottom: 12px;
			@include media-breakpoint-down(md) {
				margin-top:  16px;
			}
		}
		p, label,mark,span, li{
			color: $gray-300;
		}
		a, p a{
			color: $white;
		}
		blockquote{
		    font-family: $font-family-monospace;
		    position: relative;
		    color: $yellow-headline;	    
		    padding: 2px 0;
		    border-style: solid;
		    border-color: $yellow-headline;
		    border-width: 0;
		    border-left-width: 5px;
		    margin: 0 auto 0 24px;
		    padding-left: 16px;
		    padding-right: 8px;
		    p,li{
		    	color: $yellow-headline;
		    }
		    &::before{
				content: "";
				position: absolute;
				left: -40px;
				top: 2px;
			}
		}
		ul{
			padding: 0px 0 12px 0;
			margin: 0px;
			list-style: none;
			li{
				padding-left: 22px;
				position: relative;
				margin-bottom: 16px;
				&:before{
					content: "⚹";
					color: $yellow-arrows;
					font-size: 20px;
					position: absolute;
					left: 0px;
					top: 1px;
					line-height: 1;
				}
			}
		}
	}
	.video-holder{
		max-width: 1040px;
		margin: 0 auto 48px;
		position: relative;
		.video-frame{
			border-radius: 14px;
			position: relative;
			padding-top: 56.25%;
			a.load-video-link{
				border: none;
				position: absolute;
				top: 0;
				left: 0px;
				height: 100%;
				width: 100%;
				border-radius: 14px;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				cursor: pointer;
				overflow: hidden;
				outline: none;
				&.video-link-loaded{
					display: none;
				}
				picture{
				    width: 100%;
				    height: 100%;
				    display: flex;
					img{
						object-fit: cover;
					    height: auto;
					    width:100%;
					}
				}
				span.dog-icon{
					width: 90px;
					height: 90px;
					position: absolute;
					top: 0px;
					left: 0px;
					bottom: 0px;
					right: 0px;
					margin: auto;
					text-align: center;
					cursor: pointer;
				}
			}
			iframe{
				border: none;
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				border-radius: 14px;
			}
		}
		.video-frame-holder{
			position: relative;
			background: linear-gradient(180deg, rgba(19, 68, 114, 0) 0%, rgba(21, 53, 82, 0.5) 100%);
			border-radius: 14px;
			iframe{
				border-radius: 14px;
			}
			picture{
			  width: 100%;
			  height: 100%;
			  display: flex;
			  border-radius: 14px;
				img{
					object-fit: cover;
				    height: auto;
				    width:100%;
				    border-radius: 14px;
				}
			}
			.dog-icon{
				position: absolute;
				top: 0px;
				left: 0px;
				bottom: 0px;
				right: 0px;
				margin: auto;
				width: 48px;
				height: 48px;
				display: flex;
				align-items: center;
				svg{
					width: 100%;
					height: 100%;
				}

			}
		}
	}
	.video-pagination-links{
		max-width: 960px;
		margin: 0 auto 30px;
		position: relative;

	}
}

#video-chapter{
	position:relative;
	padding-left: 0px;
	margin-top: 30px;
	@include media-breakpoint-down(xxl) {
		padding-left: 16px;
	}
	@include media-breakpoint-down(lg) {
		padding-left: 0px;
	}	
	.video-operating-title{
		display: flex;
		margin: 16px 0 40px;
		@include media-breakpoint-up(xl) {
			padding-left: 8px;
		}
		h2{
			color: $yellow-headline;
			font-size: $font-size-lg;
			margin: 0px;
			padding: 0px;
		}
	}
	.video-shownotes{
		position: relative;
		padding-right: 32px;
		width: 100%;
		font-size: $font-size-base;
		color: $gray-300;
		max-width: 720px;
		@include media-breakpoint-up(xl) {
			padding-left: 8px;
		}
		@include media-breakpoint-down(md) {
			padding-right: 0px;
		}
		h2,h3,h4,h5,h6{
			color: $white;			
		}
		h2,h3{
			margin-top: 40px;
			margin-bottom: 16px;
			@include media-breakpoint-down(md) {
				margin-top:  24px;
				margin-bottom: 12px;
			}
		}
		h4,h5,h6{
			margin-top: 24px;
			margin-bottom: 12px;
			@include media-breakpoint-down(md) {
				margin-top:  16px;
			}
		}
		p, label,mark,span, li{
			color: $gray-300;
			margin-bottom: 12px;
		}
		a, p a{
			color: $white;
		}
		blockquote{
		    font-family: $font-family-monospace;
		    position: relative;
		    color: $yellow-headline;	    
		    padding: 2px 0;
		    border-style: solid;
		    border-color: $yellow-headline;
		    border-width: 0;
		    border-left-width: 5px;
		    margin: 0 auto 0 24px;
		    padding-left: 16px;
		    padding-right: 8px;
		    p,li{
		    	color: $yellow-headline;
		    }
		    &::before{
				content: "";
				position: absolute;
				left: -40px;
				top: 2px;
			}
		}
		ul{
			padding: 0px 0 12px 0;
			margin: 0px;
			list-style: none;
			li{
				padding-left: 22px;
				position: relative;
				margin-bottom: 16px;
				&:before{
					content: "⚹";
					color: $yellow-arrows;
					font-size: 20px;
					position: absolute;
					left: 0px;
					top: 1px;
					line-height: 1;
				}
			}
		}
	}
	.chapter-video-box{
		display: block;
		width: 100%;
		.video-box{
			display: flex;
			width: 100%;
			align-content: center;
			align-items: center;
			min-height: 90px;
			padding: 0px 24px;
			border: 1px solid #2B5780;
			border-radius: 4px;
			cursor: pointer;
			margin-bottom: 20px;
			transition: all 0.4s ease-in-out;
			.play-icon{
				min-width: 32px;
				margin-right: 16px;
				cursor: pointer;
			}
			label{
				color: $light;
				font-size: $font-size-sm;
				margin: 0px;
				width: 100%;
				cursor: pointer;
				transition: all 0.4s ease-in-out;
			}
			mark{
				margin-left: auto;
				display: flex;
				align-items: center;
				align-content: center;
				color: $light;
				font-size: $font-size-sm;
				font-weight: 700;
				padding: 0;
				margin: 0px;
				white-space: nowrap;
				cursor: pointer;
				span{
					width: 18px;
					height: 18px;
					margin-right: 8px;
					svg{
						vertical-align: baseline;
						width: 100%;
						height: 100%;
					}
				}
			}
			&:hover,
			&:focus{
				border-color: $light-blue;
				label{
					color: $yellow-headline;
				}
			}

		}
	}
}
