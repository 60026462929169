@charset "UTF-8";
/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #134472;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #8492A5;
  --bs-gray-dark: #273443;
  --bs-primary: #84D5F7;
  --bs-secondary: #FFD58E;
  --bs-success: #DEF0E7;
  --bs-info: #DBB378;
  --bs-warning: #DFDCC3;
  --bs-danger: #dc3545;
  --bs-light: #DEF0E7;
  --bs-dark: #134472;
  --bs-font-sans-serif: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #273443;
  background-color: #F8E2AF;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.8125rem;
}

mark, .mark {
  padding: 0.2em;
  background-color: transparent;
}

sub,
sup {
  position: relative;
  font-size: 0.625rem;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #134472;
  text-decoration: underline;
}
a:hover {
  color: #84D5F7;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.8125rem;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.8125rem;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: #273443;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8492A5;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  color: #8492A5;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F8E2AF;
  border: 1px solid #E0E6ED;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.8125rem;
  color: #8492A5;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 1340px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 1350px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 1360px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1380px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1480px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #273443;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #273443;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #273443;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #273443;
  vertical-align: middle;
  border-color: #134472;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #e6f7fd;
  --bs-table-striped-bg: #dbebf0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cfdee4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d5e4ea;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cfdee4;
}

.table-secondary {
  --bs-table-bg: #fff7e8;
  --bs-table-striped-bg: #f2ebdc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6ded1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece4d7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6ded1;
}

.table-success {
  --bs-table-bg: #f8fcfa;
  --bs-table-striped-bg: #ecefee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe3e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e9e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe3e1;
}

.table-info {
  --bs-table-bg: #f8f0e4;
  --bs-table-striped-bg: #ece4d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfd8cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5ded3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfd8cd;
}

.table-warning {
  --bs-table-bg: #f9f8f3;
  --bs-table-striped-bg: #edece7;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e0dfdb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e6e5e1;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e0dfdb;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #DEF0E7;
  --bs-table-striped-bg: #d3e4db;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8d8d0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cdded6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c8d8d0;
}

.table-dark {
  --bs-table-bg: #134472;
  --bs-table-striped-bg: #1f4d79;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2b5780;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #25527d;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #2b5780;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(1rem + 2px);
  padding-bottom: calc(1rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: calc(1rem + 2px);
  padding-bottom: calc(1rem + 2px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.625rem + 2px);
  padding-bottom: calc(0.625rem + 2px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.8125rem;
  color: #8492A5;
}

.form-control {
  display: block;
  width: 100%;
  padding: 1rem 1rem;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #134472;
  background-color: transparent;
  background-clip: padding-box;
  border: 2px solid #DBB378;
  appearance: none;
  border-radius: 25px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #134472;
  background-color: transparent;
  border-color: tint-color(#DBB378, 50%);
  outline: 0;
  box-shadow: 0;
}
.form-control::-webkit-date-and-time-value {
  height: 1em;
}
.form-control::placeholder {
  color: #DBB378;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #FFD58E;
  border-color: #DBB378;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 1rem 1rem;
  margin: -1rem -1rem;
  margin-inline-end: 1rem;
  color: #134472;
  background-color: #EBEEF6;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 2px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dfe2ea;
}
.form-control::-webkit-file-upload-button {
  padding: 1rem 1rem;
  margin: -1rem -1rem;
  margin-inline-end: 1rem;
  color: #134472;
  background-color: #EBEEF6;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 2px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dfe2ea;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  line-height: 1;
  color: #DBB378;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: add(1em, add(1.25rem, 4px, false));
  padding: 0.625rem 0.625rem;
  font-size: 0.875rem;
  border-radius: 25px;
}
.form-control-sm::file-selector-button {
  padding: 0.625rem 0.625rem;
  margin: -0.625rem -0.625rem;
  margin-inline-end: 0.625rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.625rem 0.625rem;
  margin: -0.625rem -0.625rem;
  margin-inline-end: 0.625rem;
}

.form-control-lg {
  min-height: add(1em, add(2rem, 4px, false));
  padding: 1rem 1rem;
  font-size: 1rem;
  border-radius: 25px;
}
.form-control-lg::file-selector-button {
  padding: 1rem 1rem;
  margin: -1rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 1rem 1rem;
  margin: -1rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: add(1em, add(2rem, 4px, false));
}
textarea.form-control-sm {
  min-height: add(1em, add(1.25rem, 4px, false));
}
textarea.form-control-lg {
  min-height: add(1em, add(2rem, 4px, false));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 1rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1em;
  border-radius: 25px;
}
.form-control-color::-webkit-color-swatch {
  height: 1em;
  border-radius: 25px;
}

.form-select {
  display: block;
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
  color: #134472;
  background-color: #F8E2AF;
  background-image: url("data:image/svg+xml,%3csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url%28%23clip0%29'%3e%3cpath d='M4.76692 4.88664C4.6195 5.03779 4.3805 5.03779 4.23308 4.88664L0.111319 0.66069C-0.126482 0.416879 0.0419378 0 0.378238 0L8.62176 0C8.95806 0 9.12648 0.416879 8.88868 0.66069L4.76692 4.88664Z' fill='%23DBB378'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0'%3e%3crect width='9' height='5' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 9px 5px;
  border: 2px solid #DBB378;
  border-radius: 25px;
  appearance: none;
}
.form-select:focus {
  border-color: tint-color(#DBB378, 50%);
  outline: 0;
  box-shadow: 0;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #EBEEF6;
  border-color: #DBB378;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #134472;
}

.form-select-sm {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 0.625rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: tint-color(#DBB378, 50%);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(132, 213, 247, 0.25);
}
.form-check-input:checked {
  background-color: #84D5F7;
  border-color: #84D5F7;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #84D5F7;
  border-color: #84D5F7;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='tint-color%28%23DBB378, 50%%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F8E2AF, 0;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F8E2AF, 0;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #84D5F7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #daf2fd;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E0E6ED;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #84D5F7;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #daf2fd;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #E0E6ED;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #B0BBCB;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #B0BBCB;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 4px);
  padding: 1rem 1rem;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1rem;
  pointer-events: none;
  border: 2px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #134472;
  text-align: center;
  white-space: nowrap;
  background-color: #EBEEF6;
  border: 2px solid #DBB378;
  border-radius: 25px;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 1rem;
  font-size: 1rem;
  border-radius: 25px;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.625rem 0.625rem;
  font-size: 0.875rem;
  border-radius: 25px;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -2px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8125rem;
  color: #DEF0E7;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #000;
  background-color: rgba(222, 240, 231, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #DEF0E7;
  padding-right: add(1em, 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23DEF0E7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right add(0.25em, 0.5rem) center;
  background-size: add(0.5em, 1rem) add(0.5em, 1rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #DEF0E7;
  box-shadow: 0 0 0 0rem rgba(222, 240, 231, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: add(1em, 2rem);
  background-position: top add(0.25em, 0.5rem) right add(0.25em, 0.5rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #DEF0E7;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url%28%23clip0%29'%3e%3cpath d='M4.76692 4.88664C4.6195 5.03779 4.3805 5.03779 4.23308 4.88664L0.111319 0.66069C-0.126482 0.416879 0.0419378 0 0.378238 0L8.62176 0C8.95806 0 9.12648 0.416879 8.88868 0.66069L4.76692 4.88664Z' fill='%23DBB378'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0'%3e%3crect width='9' height='5' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23DEF0E7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 9px 5px, add(0.5em, 1rem) add(0.5em, 1rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #DEF0E7;
  box-shadow: 0 0 0 0rem rgba(222, 240, 231, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #DEF0E7;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #DEF0E7;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(222, 240, 231, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #DEF0E7;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8125rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: add(1em, 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right add(0.25em, 0.5rem) center;
  background-size: add(0.5em, 1rem) add(0.5em, 1rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: add(1em, 2rem);
  background-position: top add(0.25em, 0.5rem) right add(0.25em, 0.5rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 5.5rem;
  background-image: url("data:image/svg+xml,%3csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url%28%23clip0%29'%3e%3cpath d='M4.76692 4.88664C4.6195 5.03779 4.3805 5.03779 4.23308 4.88664L0.111319 0.66069C-0.126482 0.416879 0.0419378 0 0.378238 0L8.62176 0C8.95806 0 9.12648 0.416879 8.88868 0.66069L4.76692 4.88664Z' fill='%23DBB378'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0'%3e%3crect width='9' height='5' fill='white'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, center right 3rem;
  background-size: 9px 5px, add(0.5em, 1rem) add(0.5em, 1rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #273443;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  padding: 0.625rem 1.625rem;
  font-size: 1rem;
  border-radius: 30px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #273443;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(132, 213, 247, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #000;
  background-color: #84D5F7;
  border-color: #84D5F7;
}
.btn-primary:hover {
  color: #000;
  background-color: #96dbf8;
  border-color: #90d9f8;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #96dbf8;
  border-color: #90d9f8;
  box-shadow: 0 0 0 0.25rem rgba(112, 181, 210, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #000;
  background-color: #9dddf9;
  border-color: #90d9f8;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(112, 181, 210, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #000;
  background-color: #84D5F7;
  border-color: #84D5F7;
}

.btn-secondary {
  color: #000;
  background-color: #FFD58E;
  border-color: #FFD58E;
}
.btn-secondary:hover {
  color: #000;
  background-color: #ffdb9f;
  border-color: #ffd999;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #ffdb9f;
  border-color: #ffd999;
  box-shadow: 0 0 0 0.25rem rgba(217, 181, 121, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #ffdda5;
  border-color: #ffd999;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 181, 121, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #FFD58E;
  border-color: #FFD58E;
}

.btn-success {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-success:hover {
  color: #000;
  background-color: #e3f2eb;
  border-color: #e1f2e9;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #e3f2eb;
  border-color: #e1f2e9;
  box-shadow: 0 0 0 0.25rem rgba(189, 204, 196, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #e5f3ec;
  border-color: #e1f2e9;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 204, 196, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}

.btn-info {
  color: #000;
  background-color: #DBB378;
  border-color: #DBB378;
}
.btn-info:hover {
  color: #000;
  background-color: #e0be8c;
  border-color: #dfbb86;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #e0be8c;
  border-color: #dfbb86;
  box-shadow: 0 0 0 0.25rem rgba(186, 152, 102, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #e2c293;
  border-color: #dfbb86;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(186, 152, 102, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #DBB378;
  border-color: #DBB378;
}

.btn-warning {
  color: #000;
  background-color: #DFDCC3;
  border-color: #DFDCC3;
}
.btn-warning:hover {
  color: #000;
  background-color: #e4e1cc;
  border-color: #e2e0c9;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #e4e1cc;
  border-color: #e2e0c9;
  box-shadow: 0 0 0 0.25rem rgba(190, 187, 166, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #e5e3cf;
  border-color: #e2e0c9;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(190, 187, 166, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #DFDCC3;
  border-color: #DFDCC3;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-light:hover {
  color: #000;
  background-color: #e3f2eb;
  border-color: #e1f2e9;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #e3f2eb;
  border-color: #e1f2e9;
  box-shadow: 0 0 0 0.25rem rgba(189, 204, 196, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #e5f3ec;
  border-color: #e1f2e9;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(189, 204, 196, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}

.btn-dark {
  color: #fff;
  background-color: #134472;
  border-color: #134472;
}
.btn-dark:hover {
  color: #fff;
  background-color: #103a61;
  border-color: #0f365b;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #103a61;
  border-color: #0f365b;
  box-shadow: 0 0 0 0.25rem rgba(54, 96, 135, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0f365b;
  border-color: #0e3356;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(54, 96, 135, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #134472;
  border-color: #134472;
}

.btn-outline-primary {
  color: #84D5F7;
  border-color: #84D5F7;
}
.btn-outline-primary:hover {
  color: #000;
  background-color: #84D5F7;
  border-color: #84D5F7;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(132, 213, 247, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #000;
  background-color: #84D5F7;
  border-color: #84D5F7;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(132, 213, 247, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #84D5F7;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #FFD58E;
  border-color: #FFD58E;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #FFD58E;
  border-color: #FFD58E;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 213, 142, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #FFD58E;
  border-color: #FFD58E;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 213, 142, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #FFD58E;
  background-color: transparent;
}

.btn-outline-success {
  color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 240, 231, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 240, 231, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #DEF0E7;
  background-color: transparent;
}

.btn-outline-info {
  color: #DBB378;
  border-color: #DBB378;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #DBB378;
  border-color: #DBB378;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(219, 179, 120, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #DBB378;
  border-color: #DBB378;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(219, 179, 120, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #DBB378;
  background-color: transparent;
}

.btn-outline-warning {
  color: #DFDCC3;
  border-color: #DFDCC3;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #DFDCC3;
  border-color: #DFDCC3;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(223, 220, 195, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #DFDCC3;
  border-color: #DFDCC3;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(223, 220, 195, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #DFDCC3;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 240, 231, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #DEF0E7;
  border-color: #DEF0E7;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 240, 231, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #DEF0E7;
  background-color: transparent;
}

.btn-outline-dark {
  color: #134472;
  border-color: #134472;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #134472;
  border-color: #134472;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 68, 114, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #134472;
  border-color: #134472;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 68, 114, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #134472;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #134472;
  text-decoration: underline;
}
.btn-link:hover {
  color: #84D5F7;
}
.btn-link:disabled, .btn-link.disabled {
  color: #8492A5;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  border-radius: 30px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 30px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #273443;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #273443;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #232f3c;
  background-color: #EBEEF6;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #84D5F7;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #B0BBCB;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #8492A5;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #273443;
}

.dropdown-menu-dark {
  color: #E0E6ED;
  background-color: #273443;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #E0E6ED;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #84D5F7;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #B0BBCB;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #E0E6ED;
}
.dropdown-menu-dark .dropdown-header {
  color: #B0BBCB;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -2px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.21875rem;
  padding-left: 1.21875rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -2px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #134472;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #84D5F7;
}
.nav-link.disabled {
  color: #8492A5;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E0E6ED;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #EBEEF6 #EBEEF6 #E0E6ED;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #8492A5;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #3B4857;
  background-color: #F8E2AF;
  border-color: #E0E6ED #E0E6ED #F8E2AF;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #84D5F7;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1.125rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  font-size: 1rem;
  color: transparent;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #DEF0E7;
  background-color: transparent;
  box-shadow: inset 0 0 0 transparent;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' %3e%3cg%3e%3cpath fill='%23DEF0E7' d='M 13.121094 6.515625 L 4.234375 0.429688 C 3.824219 0.148438 3.410156 0 3.066406 0 C 2.402344 0 1.992188 0.535156 1.992188 1.425781 L 1.992188 14.574219 C 1.992188 15.46875 2.402344 16 3.066406 16 C 3.410156 16 3.816406 15.851562 4.226562 15.570312 L 13.121094 9.484375 C 13.691406 9.09375 14.007812 8.566406 14.007812 8 C 14.007812 7.433594 13.695312 6.90625 13.121094 6.515625 Z M 13.121094 6.515625 '/%3e%3c/g%3e%3c/svg%3e");
  transform: rotate(90deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 0.625rem;
  height: 0.625rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' %3e%3cg%3e%3cpath fill='%23DEF0E7' d='M 13.121094 6.515625 L 4.234375 0.429688 C 3.824219 0.148438 3.410156 0 3.066406 0 C 2.402344 0 1.992188 0.535156 1.992188 1.425781 L 1.992188 14.574219 C 1.992188 15.46875 2.402344 16 3.066406 16 C 3.410156 16 3.816406 15.851562 4.226562 15.570312 L 13.121094 9.484375 C 13.691406 9.09375 14.007812 8.566406 14.007812 8 C 14.007812 7.433594 13.695312 6.90625 13.121094 6.515625 Z M 13.121094 6.515625 '/%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 0.625rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: tint-color(#DBB378, 50%);
  outline: 0;
  box-shadow: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: transparent;
  border: 0 solid transparent;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion-body {
  padding: 0.625rem 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 2rem;
  font-size: 0.875rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #B3B39D;
  content: var(--bs-breadcrumb-divider, "›") /* rtl: var(--bs-breadcrumb-divider, "›") */;
}
.breadcrumb-item.active {
  color: #869390;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #134472;
  text-decoration: none;
  background-color: #DFDCC3;
  border: 1px solid #DBB378;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #134472;
  background-color: #DBB378;
  border-color: #DBB378;
}
.page-link:focus {
  z-index: 3;
  color: #134472;
  background-color: #DBB378;
  outline: 0;
  box-shadow: 0;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #134472;
  background-color: #DBB378;
  border-color: #DBB378;
}
.page-item.disabled .page-link {
  color: #8492A5;
  pointer-events: none;
  background-color: #fff;
  border-color: #E0E6ED;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #355563;
  background-color: #e6f7fd;
  border-color: #daf2fd;
}
.alert-primary .alert-link {
  color: #2a444f;
}

.alert-secondary {
  color: #665539;
  background-color: #fff7e8;
  border-color: #fff2dd;
}
.alert-secondary .alert-link {
  color: #52442e;
}

.alert-success {
  color: #59605c;
  background-color: #f8fcfa;
  border-color: #f5fbf8;
}
.alert-success .alert-link {
  color: #474d4a;
}

.alert-info {
  color: #584830;
  background-color: #f8f0e4;
  border-color: #f4e8d7;
}
.alert-info .alert-link {
  color: #463a26;
}

.alert-warning {
  color: #59584e;
  background-color: #f9f8f3;
  border-color: #f5f5ed;
}
.alert-warning .alert-link {
  color: #47463e;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #59605c;
  background-color: #f8fcfa;
  border-color: #f5fbf8;
}
.alert-light .alert-link {
  color: #474d4a;
}

.alert-dark {
  color: #0b2944;
  background-color: #d0dae3;
  border-color: #b8c7d5;
}
.alert-dark .alert-link {
  color: #092136;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #EBEEF6;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #84D5F7;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #3B4857;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #3B4857;
  text-decoration: none;
  background-color: #F1F3F8;
}
.list-group-item-action:active {
  color: #273443;
  background-color: #EBEEF6;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #273443;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #8492A5;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #84D5F7;
  border-color: #84D5F7;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #355563;
  background-color: #e6f7fd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #355563;
  background-color: #cfdee4;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #355563;
  border-color: #355563;
}

.list-group-item-secondary {
  color: #665539;
  background-color: #fff7e8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #665539;
  background-color: #e6ded1;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #665539;
  border-color: #665539;
}

.list-group-item-success {
  color: #59605c;
  background-color: #f8fcfa;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #59605c;
  background-color: #dfe3e1;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #59605c;
  border-color: #59605c;
}

.list-group-item-info {
  color: #584830;
  background-color: #f8f0e4;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #584830;
  background-color: #dfd8cd;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #584830;
  border-color: #584830;
}

.list-group-item-warning {
  color: #59584e;
  background-color: #f9f8f3;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #59584e;
  background-color: #e0dfdb;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #59584e;
  border-color: #59584e;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #59605c;
  background-color: #f8fcfa;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #59605c;
  background-color: #dfe3e1;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #59605c;
  border-color: #59605c;
}

.list-group-item-dark {
  color: #0b2944;
  background-color: #d0dae3;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0b2944;
  background-color: #bbc4cc;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0b2944;
  border-color: #0b2944;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(132, 213, 247, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #8492A5;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E0E6ED;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E0E6ED;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #273443;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #84D5F7;
}
.link-primary:hover, .link-primary:focus {
  color: #84d5f7;
}

.link-secondary {
  color: #FFD58E;
}
.link-secondary:hover, .link-secondary:focus {
  color: #ffd58e;
}

.link-success {
  color: #DEF0E7;
}
.link-success:hover, .link-success:focus {
  color: #def0e7;
}

.link-info {
  color: #DBB378;
}
.link-info:hover, .link-info:focus {
  color: #dbb378;
}

.link-warning {
  color: #DFDCC3;
}
.link-warning:hover, .link-warning:focus {
  color: #dfdcc3;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #dc3545;
}

.link-light {
  color: #DEF0E7;
}
.link-light:hover, .link-light:focus {
  color: #def0e7;
}

.link-dark {
  color: #134472;
}
.link-dark:hover, .link-dark:focus {
  color: #134472;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #E0E6ED !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #E0E6ED !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #E0E6ED !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #E0E6ED !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #E0E6ED !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #84D5F7 !important;
}

.border-secondary {
  border-color: #FFD58E !important;
}

.border-success {
  border-color: #DEF0E7 !important;
}

.border-info {
  border-color: #DBB378 !important;
}

.border-warning {
  border-color: #DFDCC3 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #DEF0E7 !important;
}

.border-dark {
  border-color: #134472 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.5 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.5 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #84D5F7 !important;
}

.text-secondary {
  color: #FFD58E !important;
}

.text-success {
  color: #DEF0E7 !important;
}

.text-info {
  color: #DBB378 !important;
}

.text-warning {
  color: #DFDCC3 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #DEF0E7 !important;
}

.text-dark {
  color: #134472 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #273443 !important;
}

.text-muted {
  color: #8492A5 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #84D5F7 !important;
}

.bg-secondary {
  background-color: #FFD58E !important;
}

.bg-success {
  background-color: #DEF0E7 !important;
}

.bg-info {
  background-color: #DBB378 !important;
}

.bg-warning {
  background-color: #DFDCC3 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #DEF0E7 !important;
}

.bg-dark {
  background-color: #134472 !important;
}

.bg-body {
  background-color: #F8E2AF !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

#page-body {
  opacity: 1 !important;
  visibility: visible !important;
}

#top-header {
  position: relative;
  z-index: 2;
  width: 100%;
}
#top-header .navbar-brand {
  margin: 0px;
  padding: 1.75rem 2.75rem 1.75rem 0;
  min-width: 225px;
}
#top-header .navbar-brand img {
  max-width: 100%;
  max-height: 100%;
}
@media (max-width: 991.98px) {
  #top-header .navbar-brand {
    padding: 1rem 0.75rem 1rem 0;
  }
}
@media (max-width: 575.98px) {
  #top-header .navbar-brand {
    padding: 0;
    min-width: 100px;
  }
}
@media (min-width: 992px) {
  #top-header aside {
    min-width: 350px;
  }
}
@media (max-width: 575.98px) {
  #top-header aside {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #134472;
    color: #DEF0E7;
    left: 0px;
    top: 0px;
    z-index: 2;
    transition: all 0.6s ease-in-out;
  }
}
#top-header .navbar-toggler {
  padding: 0px;
  outline: none;
  border: none;
  width: 100%;
  text-align: center;
  border-radius: 0px;
}
#top-header .navbar-toggler:hover, #top-header .navbar-toggler:focus, #top-header .navbar-toggler:active {
  box-shadow: none;
  outline: none;
  border: none;
  background-color: transparent;
}
#top-header .login-button-box {
  font-weight: 600;
  font-size: 0.8125rem;
  outline: none;
}
#top-header .login-button-box.mobile-color {
  padding-right: 0px;
  min-width: 40px;
}
@media (min-width: 768px) {
  #top-header .login-button-box span {
    display: none;
  }
}
@media (max-width: 767.98px) {
  #top-header .login-button-box {
    border: none;
  }
  #top-header .login-button-box:hover, #top-header .login-button-box:focus, #top-header .login-button-box:active {
    background-color: transparent;
  }
  #top-header .login-button-box label {
    display: none;
  }
}
@media (min-width: 576px) {
  #top-header .navbar {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
  #top-header .navbar .navbar-nav {
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
  }
  #top-header .navbar .nav-item {
    padding: 0 0.75rem;
  }
  #top-header .navbar .nav-item .nav-link {
    font-size: 1rem;
    font-weight: 700;
    color: #134472;
    padding: 0px;
    position: relative;
    transition: all 0.6s ease-in-out;
  }
  #top-header .navbar .nav-item .nav-link:after {
    content: "";
    height: 2px;
    bottom: -3px;
    position: absolute;
    background-color: transparent;
    width: 110%;
    left: -2%;
  }
  #top-header .navbar .nav-item .nav-link.active, #top-header .navbar .nav-item .nav-link:hover, #top-header .navbar .nav-item .nav-link:focus {
    color: #040e17;
  }
  #top-header .navbar .nav-item .nav-link.active:after, #top-header .navbar .nav-item .nav-link:hover:after, #top-header .navbar .nav-item .nav-link:focus:after {
    background-color: #040e17;
  }
}
@media (max-width: 575.98px) {
  #top-header .navbar {
    flex-direction: column;
    height: 100%;
    padding: 0px;
  }
  #top-header .navbar .helper-menu {
    width: 100%;
    padding: 0 16px;
  }
  #top-header .navbar .navbar-nav {
    margin: auto;
    height: calc(100% - 160px);
    padding: 0 0 1rem 0;
  }
  #top-header .navbar .navbar-nav .nav-item {
    padding: 0.75rem;
    margin-bottom: 40px;
  }
  #top-header .navbar .navbar-nav .nav-item .nav-link {
    font-size: 1rem;
    font-weight: 700;
    color: #DEF0E7;
    position: relative;
    text-align: center;
    transition: all 0.6s ease-in-out;
  }
  #top-header .navbar .navbar-nav .nav-item .nav-link:after {
    content: "";
    height: 2px;
    bottom: -3px;
    position: absolute;
    background-color: transparent;
    width: 110%;
    left: -2%;
  }
  #top-header .navbar .navbar-nav .nav-item .nav-link.active, #top-header .navbar .navbar-nav .nav-item .nav-link:hover, #top-header .navbar .navbar-nav .nav-item .nav-link:focus {
    color: #2c302e;
  }
  #top-header .navbar .navbar-nav .nav-item .nav-link.active:after, #top-header .navbar .navbar-nav .nav-item .nav-link:hover:after, #top-header .navbar .navbar-nav .nav-item .nav-link:focus:after {
    background-color: #2c302e;
  }
}

.footer-content {
  width: 100%;
  background-color: #134472;
  padding: 44px 0px;
}
.footer-content .footer-row {
  width: 100%;
}
.footer-content .footer-row > .col-12 {
  align-items: center;
  align-content: center;
}
.footer-content .footer-row .footer-navbar {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  color: #DEF0E7;
  height: 100%;
  align-items: center;
  align-content: center;
}
.footer-content .footer-row .footer-navbar .form-control {
  min-height: 24px;
  font-size: 0.875rem !important;
  background-color: #F8E2AF !important;
  color: #134472 !important;
  outline: none !important;
  box-shadow: none !important;
}
.footer-content .footer-row .footer-navbar .form-control:hover, .footer-content .footer-row .footer-navbar .form-control:focus {
  font-size: 0.875rem !important;
  border-color: #DBB378 !important;
  background-color: #DBB378 !important;
  color: #134472 !important;
}
.footer-content .footer-row .footer-navbar .form-control::-webkit-input-placeholder {
  color: #134472 !important;
}
.footer-content .footer-row .footer-navbar .form-control::-moz-placeholder {
  color: #134472 !important;
}
.footer-content .footer-row .footer-navbar .form-control:-ms-input-placeholder {
  color: #134472 !important;
}
.footer-content .footer-row .footer-navbar .form-control:-moz-placeholder {
  color: #134472 !important;
}
.footer-content .footer-row .footer-navbar .nav-item {
  margin-right: 42px;
}
.footer-content .footer-row .footer-navbar .nav-item .nav-link {
  padding: 0px;
  color: #DEF0E7;
  font-weight: 700;
}
.footer-content .footer-row .footer-navbar .nav-item .nav-link:hover, .footer-content .footer-row .footer-navbar .nav-item .nav-link:focus {
  color: #fff;
}
.footer-content .footer-row .footer-navbar.justify-content-end .nav-item {
  margin-right: 0px;
  margin-left: 42px;
}
.footer-content .footer-row .footer-navbar.justify-content-end .nav-item .nav-link {
  font-weight: 500;
}
@media (max-width: 767.98px) {
  .footer-content .footer-row .footer-navbar {
    flex-direction: column;
  }
  .footer-content .footer-row .footer-navbar .nav-item {
    margin: 12px auto !important;
    width: 100%;
    text-align: center;
  }
}
.footer-content .footer-row .copyright {
  margin-top: 36px;
  padding: 0px;
  color: #DEF0E7;
}
@media (max-width: 767.98px) {
  .footer-content .footer-row .copyright {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .breadcrumb-list {
    overflow: hidden;
    width: 100%;
  }
  .breadcrumb-list nav {
    overflow: auto;
    max-width: 100%;
    width: auto;
  }
  .breadcrumb-list nav ol.breadcrumb {
    flex-wrap: nowrap;
  }
  .breadcrumb-list nav ol.breadcrumb .breadcrumb-item {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .breadcrumb-list nav ol.breadcrumb .breadcrumb-item a {
    white-space: nowrap;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.search-title {
  width: 100%;
  position: relative;
  text-align: left;
  margin-bottom: 24px;
}
.search-title h3, .search-title .h3 {
  color: #DFDCC3;
  font-size: 24px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin: 0px auto 24px;
  max-width: 100%;
}
@media (max-width: 767.98px) {
  .search-title h3, .search-title .h3 {
    font-size: 24px;
  }
}
.search-title h3 span, .search-title .h3 span, .search-title h3 label, .search-title .h3 label {
  margin: 0 4px 0 0px;
}
.search-title p {
  color: #DFDCC3;
}

#loading-overlay, #loader-bg {
  position: fixed;
  height: 100%;
  display: none;
  z-index: -1;
  bottom: 0;
  left: 0;
  top: 0px;
  right: 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.92);
  backdrop-filter: blur(6px);
}

#loading-overlay #loading-overlay-loader, #loader-bg #loader {
  text-align: center;
  width: 100%;
  max-width: 380px;
  height: 160px;
  min-height: 100px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  margin: auto;
  display: block;
}

#loading-overlay.showed, #loader-bg.showed {
  display: block;
  z-index: 2000;
}

.braintree-loader__container > img {
  padding-left: 10px;
  margin-top: -16px;
}

.invalid-feedback {
  padding-bottom: 2px;
  padding-left: 16px;
}

.only-btn-to-cart {
  margin: 20px auto 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}
.only-btn-to-cart.with-description {
  margin: 32px auto 24px;
  background-color: #2B5780;
  padding: 40px 44px;
  border-radius: 8px;
  justify-content: space-between;
}
@media (max-width: 1399.98px) {
  .only-btn-to-cart.with-description {
    padding: 32px 24px;
  }
}
@media (max-width: 1199.98px) {
  .only-btn-to-cart.with-description {
    padding: 16px;
    flex-direction: column;
  }
}
.only-btn-to-cart.only-btn {
  padding: 0px !important;
  background: none !important;
  flex-direction: column;
  text-align: center;
  color: #fff;
  position: relative;
}
.only-btn-to-cart.only-btn mark, .only-btn-to-cart.only-btn .mark {
  color: #fff;
  font-size: 1rem;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: center;
  display: block;
  font-weight: 700;
}
.only-btn-to-cart .centered-btn {
  margin: 20px auto 10px;
  text-align: center;
  font-size: 1.125rem;
  min-width: 280px;
  max-width: 280px;
}
.only-btn-to-cart .lets-start-content {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
}
.only-btn-to-cart .lets-start-content h4, .only-btn-to-cart .lets-start-content .h4 {
  color: #fff;
  font-size: 24px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: left;
  display: block;
  font-weight: 700;
  margin: 0 0 8px 0;
  padding: 0px 16px 0 0;
}
.only-btn-to-cart .lets-start-content p {
  font-size: 0.875rem;
  color: #E0E6ED;
  text-align: left;
  margin: 0px;
  padding: 0px 16px 0 0;
}

.form-select,
.form-control {
  min-height: 54px;
}
.form-select.is-valid,
.form-control.is-valid {
  border-color: #DBB378;
  background: none !important;
}
.form-select:hover, .form-select:focus,
.form-control:hover,
.form-control:focus {
  border-color: #DBB378;
  background: none !important;
}

.form-select {
  line-height: 1.3;
}
.form-select optgroup {
  font-size: 0px;
  border: none;
  padding: 0px 25px;
  margin: 0px;
  line-height: 1.5;
}
.form-select optgroup option {
  font-size: 18px;
  line-height: 1.5;
}

a {
  text-decoration: none;
}

.no-js .lazyload {
  display: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-position: 98% center;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 400ms;
}

img.lazyload:not([src]) {
  visibility: hidden;
}

.container-scroller {
  width: 100%;
  /*overflow-x: hidden;*/
}

.container {
  position: relative;
}
@media (max-width: 575.98px) {
  .container {
    min-width: 40px;
    padding-right: var(--bs-gutter-x, 0.4rem);
    padding-left: var(--bs-gutter-x, 0.4rem);
  }
}

.row {
  --bs-gutter-x: 0rem;
}

#notification_content,
#preview-mode-notification {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9;
  width: 280px;
  height: auto;
}
#notification_content .alert,
#notification_content .preview-mode-text,
#preview-mode-notification .alert,
#preview-mode-notification .preview-mode-text {
  position: relative;
  width: 100%;
}
#notification_content .alert p label,
#notification_content .preview-mode-text p label,
#preview-mode-notification .alert p label,
#preview-mode-notification .preview-mode-text p label {
  display: block;
}
#notification_content .alert .btn,
#notification_content .preview-mode-text .btn,
#preview-mode-notification .alert .btn,
#preview-mode-notification .preview-mode-text .btn {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: 100%;
  padding: 0px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
#notification_content .alert p,
#preview-mode-notification .alert p {
  padding-right: 30px;
}

.btn {
  outline: none;
  box-shadow: none;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  min-width: 180px;
}
.btn span, .btn label {
  cursor: pointer;
}

.btn-sm, .btn-group-sm > .btn {
  min-width: 90px;
}

@media (max-width: 575.98px) {
  .btn-sm,
.btn-group-sm > .btn {
    min-width: 40px;
  }
}

.btn-dark {
  position: relative;
}
.btn-dark:hover, .btn-dark:focus {
  background-color: #407DB6;
  border-color: #407DB6;
  color: #fff;
}

.btn-outline-dark {
  position: relative;
  color: #134472;
}
.btn-outline-dark:hover, .btn-outline-dark:focus {
  background-color: transparent;
  border-color: #407DB6;
  color: #407DB6;
}

.btn-primary {
  position: relative;
  color: #134472;
}
.btn-primary:hover, .btn-primary:focus {
  background-color: #3AA8D6;
  border-color: #3AA8D6;
  color: #134472;
}

.btn-secondary {
  position: relative;
  color: #134472;
}
.btn-secondary:hover, .btn-secondary:focus {
  background-color: #DCB26A;
  border-color: #DCB26A;
  color: #134472;
}

.buy-now-section {
  padding: 30px 0px 50px;
  position: relative;
  width: 100%;
  background-color: #134472;
}

#posts-list {
  padding: 60px 0px 40px;
  position: relative;
  width: 100%;
  background-color: #134472;
}
#posts-list .slick-track {
  display: flex !important;
}
#posts-list .slick-slide {
  height: inherit !important;
}
#posts-list .slick-slide > div {
  height: 100% !important;
}
#posts-list .slick-dots {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 50px auto 30px;
  position: relative;
  list-style: none;
}
#posts-list .slick-dots li {
  display: flex;
  margin: 0 4px;
}
#posts-list .slick-dots li button {
  display: flex;
  width: 16px;
  height: 16px;
  font-size: 0px;
  background-color: #DFDCC3;
  border-radius: 100%;
  border: 0px solid transparent;
  outline: none;
}
#posts-list .slick-dots li.slick-active button {
  background-color: #DBB378;
}
#posts-list .posts-list-btn {
  position: absolute;
  z-index: 2;
  top: 80px;
  width: 54px;
  height: 54px;
  outline: none;
  border: none;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  text-align: center;
  padding: 0px;
  display: none !important;
}
@media (max-width: 1399.98px) {
  #posts-list .posts-list-btn {
    display: inline-block !important;
  }
}
@media (max-width: 767.98px) {
  #posts-list .posts-list-btn {
    top: 75px;
  }
}
#posts-list .posts-list-btn span {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  border-radius: 100%;
  background-color: #DBB378;
  transition: all 0.4s ease-in-out;
}
#posts-list .posts-list-btn.prev {
  left: 0px;
}
@media (min-width: 1400px) {
  #posts-list .posts-list-btn.prev {
    left: -10px;
  }
}
#posts-list .posts-list-btn.next {
  right: 0px;
}
@media (min-width: 1400px) {
  #posts-list .posts-list-btn.next {
    right: -14px;
  }
}
#posts-list .posts-list-btn:hover, #posts-list .posts-list-btn:focus #posts-list .posts-list-btn:active {
  outline: none;
  border: none;
  box-shadow: none;
}
#posts-list .posts-list-btn:hover span, #posts-list .posts-list-btn:focus #posts-list .posts-list-btn:active span {
  background-color: #FFD58E;
}
#posts-list .posts-list-holder.masonry {
  column-count: 3;
  column-gap: 16px;
}
#posts-list .posts-list-holder.masonry .post-box {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
  height: 100%;
  min-width: 350px;
}
#posts-list .posts-list-holder.masonry .post-box.landscape {
  grid-template-rows: 2fr auto;
}
#posts-list .posts-list-holder.masonry .post-box .post-container {
  position: relative;
  padding-bottom: 84.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
  background-color: #fff;
}
#posts-list .posts-list-holder.masonry .post-box .post-container iframe {
  border: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#posts-list .posts-list-holder.masonry .post-box .normal-container {
  background-color: #fff;
}
#posts-list .posts-list-holder.masonry .post-box .normal-container .twitter-tweet {
  margin: 0 !important;
}
#posts-list .posts-list-holder.masonry .post-box .normal-container .twitter-tweet iframe {
  border: none !important;
  overflow: hidden !important;
}
#posts-list .posts-list-holder .post-box {
  position: relative;
  padding: 8px;
  height: 100%;
}
@media (max-width: 767.98px) {
  #posts-list .posts-list-holder .post-box {
    padding: 0px;
  }
}
#posts-list .posts-list-holder .post-box .instagram-media {
  background: #FFF;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 1px;
  max-width: 540px;
  min-width: 326px;
  padding: 0;
  width: 99.375%;
  width: -webkit-calc(100% - 2px);
  width: calc(100% - 2px);
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 {
  padding: 16px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 {
  background: #FFFFFF;
  line-height: 0;
  padding: 0 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-2 .instagram-media-nested-1 {
  background-color: #F4F4F4;
  border-radius: 50%;
  flex-grow: 0;
  height: 40px;
  margin-right: 14px;
  width: 40px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-2 .instagram-media-nested-2 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-2 .instagram-media-nested-2 .instagram-media-nested-first {
  background-color: #F4F4F4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  margin-bottom: 6px;
  width: 100px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-2 .instagram-media-nested-2 .instagram-media-nested-second {
  background-color: #F4F4F4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  width: 60px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-3 {
  padding: 19% 0;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-4 {
  display: block;
  height: 50px;
  margin: 0 auto 12px;
  width: 50px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-5 {
  padding-top: 8px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-5 > div {
  color: #3897f0;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 18px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-6 {
  padding: 12.5% 0;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  align-items: center;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-1 .instagram-media-nested-1 {
  background-color: #F4F4F4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(0px) translateY(7px);
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-1 .instagram-media-nested-2 {
  background-color: #F4F4F4;
  height: 12.5px;
  transform: rotate(-45deg) translateX(3px) translateY(1px);
  width: 12.5px;
  flex-grow: 0;
  margin-right: 14px;
  margin-left: 2px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-1 .instagram-media-nested-3 {
  background-color: #F4F4F4;
  border-radius: 50%;
  height: 12.5px;
  width: 12.5px;
  transform: translateX(9px) translateY(-18px);
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-2 {
  margin-left: 8px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-2 .instagram-media-nested-1 {
  background-color: #F4F4F4;
  border-radius: 50%;
  flex-grow: 0;
  height: 20px;
  width: 20px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-2 .instagram-media-nested-2 {
  width: 0;
  height: 0;
  border-top: 2px solid transparent;
  border-left: 6px solid #f4f4f4;
  border-bottom: 2px solid transparent;
  transform: translateX(16px) translateY(-4px) rotate(30deg);
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-3 {
  margin-left: auto;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-3 .instagram-media-nested-1 {
  width: 0px;
  border-top: 8px solid #F4F4F4;
  border-right: 8px solid transparent;
  transform: translateY(16px);
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-3 .instagram-media-nested-2 {
  background-color: #F4F4F4;
  flex-grow: 0;
  height: 12px;
  width: 16px;
  transform: translateY(-4px);
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-7 .nested-3 .instagram-media-nested-3 {
  width: 0;
  height: 0;
  border-top: 8px solid #F4F4F4;
  border-left: 8px solid transparent;
  transform: translateY(-4px) translateX(8px);
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-8 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 24px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-8 .instagram-media-nested-1 {
  background-color: #F4F4F4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  margin-bottom: 6px;
  width: 224px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 .instagram-media-link-1 .instagram-media-div-8 .instagram-media-nested-2 {
  background-color: #F4F4F4;
  border-radius: 4px;
  flex-grow: 0;
  height: 14px;
  width: 144px;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 p.instagram-media-p-1 {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 0 7px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#posts-list .posts-list-holder .post-box .instagram-media .instagram-media-div-1 p.instagram-media-p-1 > a {
  color: #c9c8cd;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 17px;
  text-decoration: none;
}

#courses-list {
  padding: 80px 0px 30px;
  position: relative;
  width: 100%;
}
#courses-list .slick-track {
  display: flex !important;
}
#courses-list .slick-slide {
  height: inherit !important;
}
#courses-list .slick-slide > div {
  height: 100% !important;
}
#courses-list .slick-dots {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 50px auto 30px;
  position: relative;
  list-style: none;
}
#courses-list .slick-dots li {
  display: flex;
  margin: 0 4px;
}
#courses-list .slick-dots li button {
  display: flex;
  width: 16px;
  height: 16px;
  font-size: 0px;
  background-color: rgba(19, 68, 114, 0.7);
  border-radius: 100%;
  border: 0px solid transparent;
  outline: none;
}
#courses-list .slick-dots li.slick-active button {
  background-color: #134472;
}
@media (min-width: 992px) {
  #courses-list .courses-list-holder .course-box span.orderNo {
    font-size: 48px !important;
  }
}
#courses-list .courses-list-btn {
  position: absolute;
  z-index: 2;
  top: 255px;
  width: 54px;
  height: 54px;
  outline: none;
  border: none;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  text-align: center;
  padding: 0px;
  display: inline-block !important;
}
@media (max-width: 767.98px) {
  #courses-list .courses-list-btn {
    top: 75px;
  }
}
#courses-list .courses-list-btn span {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  border-radius: 100%;
  background-color: #DBB378;
  transition: all 0.4s ease-in-out;
}
#courses-list .courses-list-btn.prev {
  left: 0px;
}
@media (min-width: 1400px) {
  #courses-list .courses-list-btn.prev {
    left: -10px;
  }
}
#courses-list .courses-list-btn.next {
  right: 0px;
}
@media (min-width: 1400px) {
  #courses-list .courses-list-btn.next {
    right: -14px;
  }
}
#courses-list .courses-list-btn:hover, #courses-list .courses-list-btn:focus #courses-list .courses-list-btn:active {
  outline: none;
  border: none;
  box-shadow: none;
}
#courses-list .courses-list-btn:hover span, #courses-list .courses-list-btn:focus #courses-list .courses-list-btn:active span {
  background-color: #FFD58E;
}
#courses-list .course-box {
  position: relative;
  padding: 8px;
  height: 100%;
}
@media (max-width: 767.98px) {
  #courses-list .course-box {
    padding: 0px;
  }
}
#courses-list .course-box .course-box-explain {
  width: 100%;
  border-radius: 14px;
  background-color: #134472;
  display: flex;
  flex-direction: column;
  padding: 22px 32px;
  height: 100%;
  box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
}
@media (max-width: 1199.98px) {
  #courses-list .course-box .course-box-explain {
    padding: 22px 22px;
  }
}
@media (max-width: 575.98px) {
  #courses-list .course-box .course-box-explain {
    padding: 8px;
  }
}
#courses-list .course-box .course-box-explain .course-box-top {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 500px;
}
#courses-list .course-box .course-box-explain .course-box-top .course-header {
  display: flex;
  width: 100%;
  min-height: 90px;
}
@media (max-width: 1199.98px) {
  #courses-list .course-box .course-box-explain .course-box-top .course-header {
    flex-wrap: wrap;
  }
}
#courses-list .course-box .course-box-explain .course-box-top .course-header .course-number {
  width: 70px;
  min-width: 70px;
  text-align: center;
  display: flex;
  align-self: flex-start;
}
@media (max-width: 1199.98px) {
  #courses-list .course-box .course-box-explain .course-box-top .course-header .course-number {
    align-self: center;
    margin: auto;
  }
}
#courses-list .course-box .course-box-explain .course-box-top .course-header .course-number span.orderNo {
  color: #DEF0E7;
  font-weight: 700;
  font-size: 84px;
  line-height: 1;
}
@media (max-width: 575.98px) {
  #courses-list .course-box .course-box-explain .course-box-top .course-header .course-number span.orderNo {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
#courses-list .course-box .course-box-explain .course-box-top .course-header .course-name {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  align-self: flex-start;
}
#courses-list .course-box .course-box-explain .course-box-top .course-header .course-name h2, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name .h2 {
  color: #DEF0E7;
  font-size: 29px;
  text-align: center;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  padding: 28px 0px 0px 15px;
  margin: 0px;
}
#courses-list .course-box .course-box-explain .course-box-top .course-header .course-name h2 a, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name .h2 a {
  color: #DEF0E7;
  font-size: 29px;
  text-align: center;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
#courses-list .course-box .course-box-explain .course-box-top .course-header .course-name h2 a.hover, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name .h2 a.hover, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name h2 a.focus, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name .h2 a.focus {
  color: #DEF0E7;
}
@media (min-width: 992px) {
  #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name h2, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name .h2 {
    padding: 12px 0px 8px;
    margin: 0 auto 0 0;
    font-size: 24px;
    text-align: left;
  }
  #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name h2 a, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name .h2 a {
    font-size: 24px;
    text-align: left;
  }
}
@media (min-width: 576px) and (max-width: 991.98px) {
  #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name h2, #courses-list .course-box .course-box-explain .course-box-top .course-header .course-name .h2 {
    padding: 28px 0px 0px;
  }
}
#courses-list .course-box .course-box-explain .course-box-top .course-description {
  padding: 8px 0px 12px 0px;
  min-height: 180px;
}
#courses-list .course-box .course-box-explain .course-box-top .course-description p {
  color: #DEF0E7;
}
@media (max-width: 1199.98px) {
  #courses-list .course-box .course-box-explain .course-box-top .course-description {
    padding-left: 0px;
  }
}
#courses-list .course-box .course-box-explain .course-box-content {
  padding: 0px;
  position: relative;
  max-width: 500px;
}
@media (max-width: 1199.98px) {
  #courses-list .course-box .course-box-explain .course-box-content {
    padding-left: 0px;
  }
}
#courses-list .course-box .course-box-explain .course-box-content .course-free-chapter {
  position: relative;
  width: 100%;
  margin-bottom: 48px;
}
@media (max-width: 767.98px) {
  #courses-list .course-box .course-box-explain .course-box-content .course-free-chapter {
    margin-bottom: 20px;
  }
}
#courses-list .course-box .course-box-explain .course-box-content .course-free-chapter .chapter-image {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
}
#courses-list .course-box .course-box-explain .course-box-content .course-free-chapter .chapter-image picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#courses-list .course-box .course-box-explain .course-box-content .course-free-chapter .chapter-image picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#courses-list .course-box .course-box-explain .course-box-content .course-free-chapter .chapter-cta {
  position: absolute;
  bottom: -24px;
  text-align: center;
  width: 100%;
}
#courses-list .course-box .course-box-explain .course-box-content .course-free-chapter .chapter-cta a.btn {
  padding: 8px 28px;
}
#courses-list .course-box .course-box-explain .course-box-content .course-free-chapter .chapter-cta a.btn label {
  cursor: pointer;
  color: #134472;
  font-weight: 700;
  margin-right: 12px;
}
#courses-list .course-box .course-box-explain .course-box-content .chapters-list {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
}
#courses-list .course-box .course-box-explain .course-box-content .chapters-list .chapter-row {
  position: relative;
  margin-bottom: 16px;
  width: 100%;
}
#courses-list .course-box .course-box-explain .course-box-content .chapters-list .chapter-row .chapter-title {
  width: 100%;
}
#courses-list .course-box .course-box-explain .course-box-content .chapters-list .chapter-row .chapter-title h3, #courses-list .course-box .course-box-explain .course-box-content .chapters-list .chapter-row .chapter-title .h3 {
  color: #DEF0E7;
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
}
#courses-list .course-box .course-box-explain .course-box-content .chapters-list .chapter-row .chapter-description {
  position: relative;
}
#courses-list .course-box .course-box-explain .course-box-content .chapters-list .chapter-row .chapter-description p {
  color: #DEF0E7;
  margin-top: 5px;
}
#courses-list .course-box .course-box-explain .course-box-cta {
  margin-top: auto;
}
#courses-list .course-box .course-box-explain .course-box-cta .course-buy-btn {
  text-align: center;
}
#courses-list .course-box .course-box-explain .course-box-cta .course-buy-btn .btn {
  cursor: pointer;
  color: #134472;
  font-weight: 700;
  margin-bottom: 12px;
}
#courses-list #course-details {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.6s ease-in-out;
  min-height: 100%;
}
#courses-list #course-details.active {
  opacity: 1;
  visibility: visible;
  z-index: 3;
}
@media (max-width: 575.98px) {
  #courses-list #course-details > .container {
    padding: 0 6px;
  }
}
#courses-list #course-details .courses-list-btn {
  top: 40%;
  z-index: 4;
}
@media (max-width: 767.98px) {
  #courses-list #course-details .courses-list-btn {
    top: 75px;
  }
}
#courses-list #course-details .close-details-view {
  position: absolute;
  width: 42px;
  height: 42px;
  right: 25px;
  top: 25px;
  z-index: 2;
}
@media (max-width: 575.98px) {
  #courses-list #course-details .close-details-view {
    right: 30px;
    top: 12px;
  }
}
#courses-list .course-details-section {
  position: relative;
  padding: 6px;
  width: 100%;
  min-height: 100%;
  height: 100%;
}
#courses-list .course-details-section .course-details-explain {
  width: 100%;
  border-radius: 14px;
  background-color: #134472;
  display: flex;
  flex-direction: column;
  padding: 22px 32px;
  background: #134472;
  border-radius: 14px;
  height: 100%;
  box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
}
@media (max-width: 1199.98px) {
  #courses-list .course-details-section .course-details-explain {
    padding: 22px 22px;
  }
}
@media (max-width: 991.98px) {
  #courses-list .course-details-section .course-details-explain > .row {
    max-width: 640px;
    margin: 0 auto;
  }
}
@media (max-width: 575.98px) {
  #courses-list .course-details-section .course-details-explain {
    padding: 0px;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-top {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 100%;
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-header {
  display: flex;
  width: 100%;
  min-height: 100px;
}
@media (max-width: 1199.98px) {
  #courses-list .course-details-section .course-details-explain .course-details-top .course-header {
    flex-wrap: wrap;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-header .course-number {
  width: 70px;
  min-width: 70px;
  text-align: center;
  display: flex;
  align-self: flex-start;
}
@media (max-width: 991.98px) {
  #courses-list .course-details-section .course-details-explain .course-details-top .course-header .course-number {
    align-self: center;
    margin: auto;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-header .course-number span.orderNo {
  color: #DEF0E7;
  font-weight: 700;
  font-size: 84px;
  line-height: 1;
}
@media (max-width: 575.98px) {
  #courses-list .course-details-section .course-details-explain .course-details-top .course-header .course-number span.orderNo {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-header .course-name {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-header .course-name h2, #courses-list .course-details-section .course-details-explain .course-details-top .course-header .course-name .h2 {
  color: #DEF0E7;
  font-size: 32px;
  text-align: center;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-description {
  padding: 36px 0px 12px 0px;
  min-height: 100px;
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-description p {
  color: #DEF0E7;
}
@media (max-width: 1199.98px) {
  #courses-list .course-details-section .course-details-explain .course-details-top .course-description {
    padding-left: 0px;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-description .course-content {
  margin: 16px 0 40px;
  display: flex;
  align-items: center;
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-description .course-content label {
  display: flex;
  align-items: center;
  margin-right: 32px;
  color: #DEF0E7;
  font-weight: 700;
}
#courses-list .course-details-section .course-details-explain .course-details-top .course-description .course-content label span {
  margin-right: 8px;
}
#courses-list .course-details-section .course-details-explain .course-details-video {
  max-width: 960px;
  margin: 0 auto 44px;
  position: relative;
  padding: 36px 0px 12px 100px;
}
@media (max-width: 1199.98px) {
  #courses-list .course-details-section .course-details-explain .course-details-video {
    padding-left: 0px;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-video .video-frame {
  border-radius: 14px;
  position: relative;
  padding-top: 56.25%;
}
#courses-list .course-details-section .course-details-explain .course-details-video .video-frame a.load-video-link {
  border: none;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
#courses-list .course-details-section .course-details-explain .course-details-video .video-frame a.load-video-link.video-link-loaded {
  display: none;
}
#courses-list .course-details-section .course-details-explain .course-details-video .video-frame a.load-video-link picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#courses-list .course-details-section .course-details-explain .course-details-video .video-frame a.load-video-link picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#courses-list .course-details-section .course-details-explain .course-details-video .video-frame a.load-video-link span.dog-icon {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
#courses-list .course-details-section .course-details-explain .course-details-video .video-frame iframe {
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 14px;
}
#courses-list .course-details-section .course-details-explain .course-details-content {
  padding-left: 48px;
  padding-top: 28px;
  position: relative;
}
@media (max-width: 1199.98px) {
  #courses-list .course-details-section .course-details-explain .course-details-content {
    padding-left: 0px;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-content .course-cta-hld {
  margin-bottom: 32px;
}
#courses-list .course-details-section .course-details-explain .course-details-content .course-cta-hld .btn {
  min-width: 230px;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion {
  padding: 8px 0px 0px 4px;
  position: relative;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-body {
  padding: 0px;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item {
  border: none;
  position: relative;
  margin-bottom: 28px;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item h4, #courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .h4 {
  color: #fff;
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item h4 a, #courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .h4 a {
  font-weight: 700;
  color: #fff;
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
}
@media (min-width: 992px) {
  #courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item h4 a:after, #courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .h4 a:after {
    display: none;
  }
}
@media (min-width: 992px) {
  #courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .accordion-collapse {
    display: block;
  }
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item ul {
  padding-left: 0px;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link {
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link a {
  position: relative;
  transition: all 0.4s ease-in-out;
  color: #DEF0E7;
  font-size: 0.875rem;
  display: flex;
  padding: 8px 0px;
  border-radius: 14px;
  /*&:before{
  	content: "•";
  	margin-right: 4px;
  }*/
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link a label {
  cursor: pointer;
  display: flex;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link a label span {
  display: inline-block;
  margin-right: 4px;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link a:hover, #courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link a:focus, #courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link a.active {
  color: #fff;
}
#courses-list .course-details-section .course-details-explain .course-details-content .accordion .accordion-item .chapter-video-link a.active {
  background-color: #84D5F7;
}

#articles-view,
#course-view {
  background: #134472;
  border-radius: 14px;
  width: 100%;
  position: relative;
  padding: 32px 24px;
  margin-bottom: 80px;
}
@media (max-width: 575.98px) {
  #articles-view,
#course-view {
    padding: 12px 8px;
  }
}
#articles-view .sidebar-col,
#course-view .sidebar-col {
  width: 100%;
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-col,
#course-view .sidebar-col {
    flex: 1 1 100%;
  }
}
@media (min-width: 992px) {
  #articles-view .sidebar-col,
#course-view .sidebar-col {
    max-width: 322px;
    min-width: 322px;
    position: sticky;
    top: 0px;
  }
}
@media (min-width: 1200px) {
  #articles-view .sidebar-col,
#course-view .sidebar-col {
    max-width: 392px;
    min-width: 392px;
    position: sticky;
    top: 0px;
  }
}
@media (min-width: 1400px) {
  #articles-view .sidebar-col,
#course-view .sidebar-col {
    max-width: 442px;
    min-width: 442px;
    position: sticky;
    top: 0px;
  }
}
#articles-view #videos-on-course,
#course-view #videos-on-course {
  width: 100%;
}
@media (max-width: 991.98px) {
  #articles-view #videos-on-course,
#course-view #videos-on-course {
    flex: 1 1 100%;
  }
}
@media (min-width: 992px) {
  #articles-view #videos-on-course,
#course-view #videos-on-course {
    max-width: calc(100% - 328px);
    margin-left: auto;
    padding-left: 16px;
  }
}
@media (min-width: 1200px) {
  #articles-view #videos-on-course,
#course-view #videos-on-course {
    max-width: calc(100% - 408px);
    margin-left: auto;
    padding-left: 16px;
  }
}
@media (min-width: 1400px) {
  #articles-view #videos-on-course,
#course-view #videos-on-course {
    max-width: calc(100% - 458px);
    margin-left: auto;
    padding-left: 16px;
  }
}
#articles-view #videos-on-course .pagination,
#course-view #videos-on-course .pagination {
  justify-content: flex-end;
  margin: 20px auto 0px;
}
#articles-view #videos-on-course .pagination .disabled,
#course-view #videos-on-course .pagination .disabled {
  display: none;
}
#articles-view #videos-on-course .pagination .page-item a[rel=prev],
#articles-view #videos-on-course .pagination .page-item a[rel=next],
#course-view #videos-on-course .pagination .page-item a[rel=prev],
#course-view #videos-on-course .pagination .page-item a[rel=next] {
  display: none;
}
#articles-view #videos-on-course .pagination .page-item:last-child,
#course-view #videos-on-course .pagination .page-item:last-child {
  display: none;
}
#articles-view .courses-title-hld,
#course-view .courses-title-hld {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  align-content: center;
  margin-bottom: 24px;
}
@media (max-width: 767.98px) {
  #articles-view .courses-title-hld,
#course-view .courses-title-hld {
    flex-wrap: wrap;
    display: none;
  }
}
#articles-view .courses-title-hld .courses-index,
#course-view .courses-title-hld .courses-index {
  padding: 0px 20px 6px;
  position: relative;
}
#articles-view .courses-title-hld .courses-index h1, #articles-view .courses-title-hld .courses-index .h1,
#course-view .courses-title-hld .courses-index h1,
#course-view .courses-title-hld .courses-index .h1 {
  color: #DFDCC3;
  font-size: 32px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin: 0px;
}
#articles-view .courses-title-hld .courses-text,
#course-view .courses-title-hld .courses-text {
  padding: 0px 20px 6px;
  position: relative;
}
#articles-view .courses-title-hld .courses-text label,
#course-view .courses-title-hld .courses-text label {
  color: #DFDCC3;
  font-size: 32px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin: 0px;
}
#articles-view .courses-title-hld .courses-title,
#course-view .courses-title-hld .courses-title {
  padding: 0px 10px;
  display: flex;
  align-items: center;
  align-content: center;
}
#articles-view .courses-title-hld .courses-title a.remove-filter,
#course-view .courses-title-hld .courses-title a.remove-filter {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #2B5780;
  color: #DEF0E7;
  margin-right: 6px;
  font-size: 0.8125rem;
  text-align: center;
  transition: all 0.4s ease-in-out;
}
#articles-view .courses-title-hld .courses-title a.remove-filter:before,
#course-view .courses-title-hld .courses-title a.remove-filter:before {
  content: "✖";
  color: #DEF0E7;
  font-size: 0.8125rem;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  text-align: center;
}
#articles-view .courses-title-hld .courses-title a.remove-filter:hover, #articles-view .courses-title-hld .courses-title a.remove-filter:focus,
#course-view .courses-title-hld .courses-title a.remove-filter:hover,
#course-view .courses-title-hld .courses-title a.remove-filter:focus {
  background-color: #84D5F7;
  color: #134472;
}
#articles-view .courses-title-hld .courses-title a.remove-filter:hover:before, #articles-view .courses-title-hld .courses-title a.remove-filter:focus:before,
#course-view .courses-title-hld .courses-title a.remove-filter:hover:before,
#course-view .courses-title-hld .courses-title a.remove-filter:focus:before {
  color: #134472;
}
#articles-view .courses-title-hld .courses-title h1, #articles-view .courses-title-hld .courses-title .h1, #articles-view .courses-title-hld .courses-title label,
#course-view .courses-title-hld .courses-title h1,
#course-view .courses-title-hld .courses-title .h1,
#course-view .courses-title-hld .courses-title label {
  color: #DEF0E7;
  font-size: 0.875rem;
  margin: 0px;
  padding: 0px;
}
#articles-view .sidebar-menu-mobile,
#course-view .sidebar-menu-mobile {
  display: none;
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-menu-mobile,
#course-view .sidebar-menu-mobile {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
    margin-bottom: 20px;
  }
  #articles-view .sidebar-menu-mobile label,
#course-view .sidebar-menu-mobile label {
    color: #DEF0E7;
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
  }
  #articles-view .sidebar-menu-mobile button#articles-menu-sidebar,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar {
    display: flex;
    width: 40px;
    height: 40px;
    margin-left: auto;
    justify-content: center;
    align-content: center;
    align-items: center;
    transition: all 0.4s ease-in-out;
    position: relative;
    padding: 0px;
    outline: none;
    border: none;
    text-align: center;
    border-radius: 0px;
    background-color: transparent;
  }
  #articles-view .sidebar-menu-mobile button#articles-menu-sidebar:hover, #articles-view .sidebar-menu-mobile button#articles-menu-sidebar:focus, #articles-view .sidebar-menu-mobile button#articles-menu-sidebar:active,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar:hover,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar:focus,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar:active,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar:hover,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar:focus,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar:active,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar:hover,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar:focus,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar:active {
    box-shadow: none;
    outline: none;
    border: none;
    background-color: transparent;
  }
  #articles-view .sidebar-menu-mobile button#articles-menu-sidebar .menu,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar .menu,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar .menu,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar .menu {
    display: block;
  }
  #articles-view .sidebar-menu-mobile button#articles-menu-sidebar .close,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar .close,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar .close,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar .close {
    display: none;
  }
  #articles-view .sidebar-menu-mobile button#articles-menu-sidebar.opened .menu,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar.opened .menu,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar.opened .menu,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar.opened .menu {
    display: none;
  }
  #articles-view .sidebar-menu-mobile button#articles-menu-sidebar.opened .close,
#articles-view .sidebar-menu-mobile button#courses-menu-sidebar.opened .close,
#course-view .sidebar-menu-mobile button#articles-menu-sidebar.opened .close,
#course-view .sidebar-menu-mobile button#courses-menu-sidebar.opened .close {
    display: block;
  }
}
@media (min-width: 992px) {
  #articles-view .sidebar-boxes,
#course-view .sidebar-boxes {
    display: block !important;
  }
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-boxes,
#course-view .sidebar-boxes {
    display: none;
  }
}
#articles-view .sidebar-box,
#course-view .sidebar-box {
  position: relative;
  padding: 0px;
  border: 1px solid #2B5780;
  border-radius: 14px;
  color: #DEF0E7;
  margin-bottom: 20px;
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-box,
#course-view .sidebar-box {
    width: 100%;
  }
}
#articles-view .sidebar-box.active,
#course-view .sidebar-box.active {
  background-color: #2B5780;
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-box.active .accordion,
#course-view .sidebar-box.active .accordion {
    display: block !important;
  }
}
#articles-view .sidebar-box.always-active,
#course-view .sidebar-box.always-active {
  background-color: #2B5780;
  display: block !important;
}
#articles-view .sidebar-box .sidebar-box-title,
#course-view .sidebar-box .sidebar-box-title {
  position: relative;
  padding: 16px 32px;
  border-radius: 14px 14px 0 0;
  background-color: #466C90;
  color: #fff;
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-box .sidebar-box-title,
#course-view .sidebar-box .sidebar-box-title {
    display: none;
  }
}
#articles-view .sidebar-box .sidebar-box-title h2, #articles-view .sidebar-box .sidebar-box-title .h2,
#course-view .sidebar-box .sidebar-box-title h2,
#course-view .sidebar-box .sidebar-box-title .h2 {
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin: 0px;
}
#articles-view .sidebar-box .sidebar-box-title h2 span, #articles-view .sidebar-box .sidebar-box-title .h2 span, #articles-view .sidebar-box .sidebar-box-title h2 label, #articles-view .sidebar-box .sidebar-box-title .h2 label,
#course-view .sidebar-box .sidebar-box-title h2 span,
#course-view .sidebar-box .sidebar-box-title .h2 span,
#course-view .sidebar-box .sidebar-box-title h2 label,
#course-view .sidebar-box .sidebar-box-title .h2 label {
  margin: 0 4px 0 0px;
}
#articles-view .sidebar-box .sidebar-box-content,
#course-view .sidebar-box .sidebar-box-content {
  position: relative;
  padding: 0 16px 16px;
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-box .sidebar-box-content,
#course-view .sidebar-box .sidebar-box-content {
    padding: 16px;
  }
}
#articles-view .sidebar-box .course-details-top,
#course-view .sidebar-box .course-details-top {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 740px;
}
#articles-view .sidebar-box .course-details-top .course-header,
#course-view .sidebar-box .course-details-top .course-header {
  display: flex;
  width: 100%;
  min-height: 100px;
}
#articles-view .sidebar-box .course-details-top .course-header a.course-videos-link,
#course-view .sidebar-box .course-details-top .course-header a.course-videos-link {
  display: flex;
  width: 100%;
}
@media (max-width: 767.98px) {
  #articles-view .sidebar-box .course-details-top .course-header a.course-videos-link,
#course-view .sidebar-box .course-details-top .course-header a.course-videos-link {
    flex-wrap: wrap;
  }
}
#articles-view .sidebar-box .course-details-top .course-header .course-number,
#course-view .sidebar-box .course-details-top .course-header .course-number {
  width: 40px;
  min-width: 40px;
  text-align: center;
  display: flex;
}
@media (max-width: 767.98px) {
  #articles-view .sidebar-box .course-details-top .course-header .course-number,
#course-view .sidebar-box .course-details-top .course-header .course-number {
    margin: auto;
  }
}
#articles-view .sidebar-box .course-details-top .course-header .course-number span.orderNo,
#course-view .sidebar-box .course-details-top .course-header .course-number span.orderNo {
  color: #DFDCC3;
  font-weight: 700;
  font-size: 26px;
  line-height: 1;
}
#articles-view .sidebar-box .course-details-top .course-header .course-name,
#course-view .sidebar-box .course-details-top .course-header .course-name {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
}
#articles-view .sidebar-box .course-details-top .course-header .course-name h2, #articles-view .sidebar-box .course-details-top .course-header .course-name .h2,
#course-view .sidebar-box .course-details-top .course-header .course-name h2,
#course-view .sidebar-box .course-details-top .course-header .course-name .h2 {
  color: #DFDCC3;
  font-weight: 700px;
  font-size: 18px;
  padding: 0;
  margin: 0px;
}
@media (max-width: 767.98px) {
  #articles-view .sidebar-box .course-details-top .course-header .course-name h2, #articles-view .sidebar-box .course-details-top .course-header .course-name .h2,
#course-view .sidebar-box .course-details-top .course-header .course-name h2,
#course-view .sidebar-box .course-details-top .course-header .course-name .h2 {
    padding: 0;
    text-align: center;
    margin: 0px auto 12px;
    font-size: 1.125rem;
  }
}
#articles-view .sidebar-box .course-details-top .course-content,
#course-view .sidebar-box .course-details-top .course-content {
  padding: 12px 0px 12px 32px;
  display: flex;
}
#articles-view .sidebar-box .course-details-top .course-content label,
#course-view .sidebar-box .course-details-top .course-content label {
  color: #DEF0E7;
  padding-right: 32px;
  display: flex;
  align-content: center;
  align-items: center;
  line-height: 1;
  font-weight: 700;
  font-size: 0.875rem;
}
#articles-view .sidebar-box .course-details-top .course-content label span,
#course-view .sidebar-box .course-details-top .course-content label span {
  max-width: 18px;
  max-height: 18px;
  margin-right: 12px;
}
@media (max-width: 767.98px) {
  #articles-view .sidebar-box .course-details-top .course-content,
#course-view .sidebar-box .course-details-top .course-content {
    padding-left: 0px;
  }
}
#articles-view .sidebar-box .accordion,
#course-view .sidebar-box .accordion {
  padding: 0px;
  position: relative;
}
@media (max-width: 991.98px) {
  #articles-view .sidebar-box .accordion,
#course-view .sidebar-box .accordion {
    display: none;
  }
}
#articles-view .sidebar-box .accordion .accordion-body,
#course-view .sidebar-box .accordion .accordion-body {
  padding: 0px;
}
#articles-view .sidebar-box .accordion .accordion-item,
#course-view .sidebar-box .accordion .accordion-item {
  border-top: 1px solid #47729A;
  position: relative;
  padding-left: 4px;
}
#articles-view .sidebar-box .accordion .accordion-item:first-child,
#course-view .sidebar-box .accordion .accordion-item:first-child {
  border: none;
}
#articles-view .sidebar-box .accordion .accordion-item h3, #articles-view .sidebar-box .accordion .accordion-item .h3,
#course-view .sidebar-box .accordion .accordion-item h3,
#course-view .sidebar-box .accordion .accordion-item .h3 {
  color: #DEF0E7;
  font-weight: 700;
  font-size: 0.875rem;
}
#articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button {
  padding-left: 12px;
  position: relative;
  transition: all 0s ease-in-out;
  font-weight: 700;
  font-size: 0.875rem;
  color: #fff;
}
#articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button:after, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button:after,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button:after,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button:after {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  display: none;
}
#articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed {
  color: #DEF0E7;
}
#articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed:hover, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed:hover, #articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed:focus, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed:focus, #articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed.active, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed.active,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed:hover,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed:hover,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed:focus,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed:focus,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button.collapsed.active,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.collapsed.active {
  color: #fff;
}
#articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button:hover, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button:hover, #articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button:focus, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button:focus, #articles-view .sidebar-box .accordion .accordion-item h3 .accordion-button.active, #articles-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.active,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button:hover,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button:hover,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button:focus,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button:focus,
#course-view .sidebar-box .accordion .accordion-item h3 .accordion-button.active,
#course-view .sidebar-box .accordion .accordion-item .h3 .accordion-button.active {
  color: #fff;
}
#articles-view .sidebar-box .accordion .accordion-item ul,
#course-view .sidebar-box .accordion .accordion-item ul {
  padding-left: 12px;
}
#articles-view .sidebar-box .accordion .accordion-item .chapter-video-link,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link {
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 0px;
  list-style: none;
}
#articles-view .sidebar-box .accordion .accordion-item .chapter-video-link a,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link a {
  position: relative;
  transition: all 0.4s ease-in-out;
  color: #DEF0E7;
  font-size: 0.875rem;
  display: flex;
  padding: 8px;
  border-radius: 14px;
  /*&:before{
  	content: "•";
  	margin-right: 4px;
  }*/
}
#articles-view .sidebar-box .accordion .accordion-item .chapter-video-link a label,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link a label {
  cursor: pointer;
  display: flex;
}
#articles-view .sidebar-box .accordion .accordion-item .chapter-video-link a label span,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link a label span {
  display: inline-block;
  margin-right: 4px;
}
#articles-view .sidebar-box .accordion .accordion-item .chapter-video-link a:hover, #articles-view .sidebar-box .accordion .accordion-item .chapter-video-link a:focus, #articles-view .sidebar-box .accordion .accordion-item .chapter-video-link a.active,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link a:hover,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link a:focus,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link a.active {
  color: #fff;
}
#articles-view .sidebar-box .accordion .accordion-item .chapter-video-link a.active,
#course-view .sidebar-box .accordion .accordion-item .chapter-video-link a.active {
  background-color: #134472;
}
#articles-view .video-list-box,
#course-view .video-list-box {
  position: relative;
  width: 100%;
  padding: 0px;
  margin-bottom: 16px;
  background-color: #DFDCC3;
  display: flex;
  border-radius: 14px;
  height: 100%;
}
#articles-view .video-list-box .video-list-left,
#course-view .video-list-box .video-list-left {
  display: flex;
  flex-direction: column;
  width: 74px;
  min-width: 74px;
  position: relative;
}
#articles-view .video-list-box .video-list-left .video-list-play,
#course-view .video-list-box .video-list-left .video-list-play {
  width: 100%;
  height: 74px;
  min-height: 74px;
  background-color: #DBB378;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 14px 0 14px 0;
}
#articles-view .video-list-box .video-list-left .video-list-duration,
#course-view .video-list-box .video-list-left .video-list-duration {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
}
#articles-view .video-list-box .video-list-left .video-list-duration span.dog-icon,
#course-view .video-list-box .video-list-left .video-list-duration span.dog-icon {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
#articles-view .video-list-box .video-list-left .video-list-duration label,
#course-view .video-list-box .video-list-left .video-list-duration label {
  width: 100%;
  text-align: center;
  margin: 0;
  font-weight: 700;
  font-size: 0.625rem;
  color: #DBB378;
  display: flex;
  justify-content: center;
  white-space: nowrap;
}
#articles-view .video-list-box .video-list-right,
#course-view .video-list-box .video-list-right {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 20px;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  #articles-view .video-list-box .video-list-right,
#course-view .video-list-box .video-list-right {
    padding: 0px 15px;
  }
}
#articles-view .video-list-box .video-list-right .video-list-title,
#course-view .video-list-box .video-list-right .video-list-title {
  position: relative;
  margin-bottom: 12px;
  width: 100%;
  margin-top: 20px;
  min-height: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#articles-view .video-list-box .video-list-right .video-list-title a,
#course-view .video-list-box .video-list-right .video-list-title a {
  display: block;
  width: 100%;
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
#articles-view .video-list-box .video-list-right .video-list-title a h4, #articles-view .video-list-box .video-list-right .video-list-title a .h4,
#course-view .video-list-box .video-list-right .video-list-title a h4,
#course-view .video-list-box .video-list-right .video-list-title a .h4 {
  transition: all 0.2s ease-in-out;
  color: #766F64;
  font-size: 1.125rem;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-weight: 600;
}
#articles-view .video-list-box .video-list-right .video-list-title a label,
#course-view .video-list-box .video-list-right .video-list-title a label {
  font-size: 0.8125rem;
  color: #134472;
}
#articles-view .video-list-box .video-list-right .video-list-title a:hover h4, #articles-view .video-list-box .video-list-right .video-list-title a:hover .h4, #articles-view .video-list-box .video-list-right .video-list-title a:focus h4, #articles-view .video-list-box .video-list-right .video-list-title a:focus .h4,
#course-view .video-list-box .video-list-right .video-list-title a:hover h4,
#course-view .video-list-box .video-list-right .video-list-title a:hover .h4,
#course-view .video-list-box .video-list-right .video-list-title a:focus h4,
#course-view .video-list-box .video-list-right .video-list-title a:focus .h4 {
  color: #DBB378;
}
#articles-view .video-list-box .video-list-right .video-list-image,
#course-view .video-list-box .video-list-right .video-list-image {
  width: 260px;
  margin: 0 auto 20px;
}
@media (max-width: 767.98px) {
  #articles-view .video-list-box .video-list-right .video-list-image,
#course-view .video-list-box .video-list-right .video-list-image {
    width: 100%;
    margin: 0 0 15px;
  }
}
#articles-view .video-list-box .video-list-right .video-list-image img,
#course-view .video-list-box .video-list-right .video-list-image img {
  max-width: 100%;
  border-radius: 14px;
}
@media (max-width: 767.98px) {
  #articles-view .video-list-box .video-list-right .video-list-image img,
#course-view .video-list-box .video-list-right .video-list-image img {
    width: 100%;
  }
}
#articles-view .video-list-box .video-list-right .video-list-description,
#course-view .video-list-box .video-list-right .video-list-description {
  position: relative;
  width: 100%;
}
#articles-view .video-list-box .video-list-right .video-list-description p,
#course-view .video-list-box .video-list-right .video-list-description p {
  width: 97%;
  margin: 0;
  font-size: 0.8125rem;
  color: #766F64;
  text-align: justify;
}
#articles-view .video-list-box .video-list-icon,
#course-view .video-list-box .video-list-icon {
  position: absolute;
  bottom: 8px;
  right: 8px;
}
#articles-view .video-list-box.can-play,
#course-view .video-list-box.can-play {
  background-color: #DEF0E7;
}
#articles-view .video-list-box.can-play .video-list-left .video-list-play,
#course-view .video-list-box.can-play .video-list-left .video-list-play {
  background-color: #84D5F7;
}
#articles-view .video-list-box.can-play .video-list-left .video-list-duration label,
#course-view .video-list-box.can-play .video-list-left .video-list-duration label {
  color: #134472;
}
#articles-view .video-list-box.can-play .video-list-right .video-list-title,
#course-view .video-list-box.can-play .video-list-right .video-list-title {
  cursor: pointer;
}
#articles-view .video-list-box.can-play .video-list-right .video-list-title a h4, #articles-view .video-list-box.can-play .video-list-right .video-list-title a .h4,
#course-view .video-list-box.can-play .video-list-right .video-list-title a h4,
#course-view .video-list-box.can-play .video-list-right .video-list-title a .h4 {
  color: #134472;
}
#articles-view .video-list-box.can-play .video-list-right .video-list-title a label,
#course-view .video-list-box.can-play .video-list-right .video-list-title a label {
  font-size: 0.8125rem;
  color: #134472;
}
#articles-view .video-list-box.can-play .video-list-right .video-list-title a:hover h4, #articles-view .video-list-box.can-play .video-list-right .video-list-title a:hover .h4, #articles-view .video-list-box.can-play .video-list-right .video-list-title a:focus h4, #articles-view .video-list-box.can-play .video-list-right .video-list-title a:focus .h4,
#course-view .video-list-box.can-play .video-list-right .video-list-title a:hover h4,
#course-view .video-list-box.can-play .video-list-right .video-list-title a:hover .h4,
#course-view .video-list-box.can-play .video-list-right .video-list-title a:focus h4,
#course-view .video-list-box.can-play .video-list-right .video-list-title a:focus .h4 {
  color: #84D5F7;
}
#articles-view .video-list-box.can-play .video-list-right .video-list-description p,
#course-view .video-list-box.can-play .video-list-right .video-list-description p {
  color: #134472;
}

#pagination-holder {
  display: flex;
  position: relative;
  width: 100%;
}

.steps-holder {
  display: flex;
  width: 100%;
  max-width: 340px;
  position: relative;
  align-items: center;
  align-content: center;
  margin: 0 auto 60px;
  z-index: 2;
}
@media (max-width: 575.98px) {
  .steps-holder {
    justify-content: space-between;
  }
}
.steps-holder:before {
  content: "";
  height: 3px;
  width: 100%;
  background-color: #134472;
  position: absolute;
  left: 0px;
  top: 4px;
}
.steps-holder .step {
  display: flex;
  white-space: nowrap;
  padding: 30px 32px 0;
}
.steps-holder .step .details-holder {
  display: flex;
}
.steps-holder .step .details-holder label {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
  text-align: center;
  color: #273443;
}
@media (max-width: 575.98px) {
  .steps-holder .step .details-holder label {
    display: none;
  }
}
.steps-holder .step .details-holder:after {
  content: "";
  position: absolute;
  top: -5px;
  width: 20px;
  height: 20px;
  border: 2px solid #DFDCC3;
  border-radius: 100%;
  display: block;
  background-color: #DFDCC3;
  color: #134472;
  font-weight: 700;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 10px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.steps-holder .step.personal-details {
  margin-left: -80px;
}
@media (max-width: 575.98px) {
  .steps-holder .step.personal-details {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.steps-holder .step.personal-details .details-holder:after {
  left: 0px;
}
.steps-holder .step.password-create {
  margin-left: 0px;
}
@media (max-width: 575.98px) {
  .steps-holder .step.password-create {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.steps-holder .step.password-create .details-holder:after {
  left: calc(50% - 12px);
}
.steps-holder .step.payment {
  margin-left: 12px;
}
@media (max-width: 575.98px) {
  .steps-holder .step.payment {
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.steps-holder .step.payment .details-holder:after {
  right: 0%;
}
.steps-holder .step.active .details-holder label {
  color: #134472;
}
@media (max-width: 575.98px) {
  .steps-holder .step.active .details-holder label {
    display: block;
  }
}
.steps-holder .step.active .details-holder:after {
  content: "⚉";
  background-color: #134472;
  border-color: #134472;
  border-width: 4px;
  color: #FFD58E;
}
.steps-holder .step.passed .details-holder label {
  color: #134472;
}
.steps-holder .step.passed .details-holder:after {
  content: "✓";
  background-color: #134472;
  border-color: #134472;
  color: #fff;
}

.acceptable-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 35px 0 15px;
}
@media (max-width: 575.98px) {
  .acceptable-cards {
    width: 100%;
    margin: 0 auto;
  }
  .acceptable-cards .img1 {
    width: 23px;
    height: 9px;
  }
  .acceptable-cards .img2 {
    width: 18px;
    height: 11px;
  }
  .acceptable-cards .img3 {
    width: 27px;
    height: 7px;
  }
  .acceptable-cards .img4 {
    width: 38px;
    height: 7px;
  }
  .acceptable-cards .img5 {
    width: 12px;
    height: 11px;
  }
  .acceptable-cards .img6 {
    width: 29px;
    height: 9px;
  }
  .acceptable-cards .img7 {
    width: 10px;
    height: 12px;
  }
}

.reassurance {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0 15px;
  list-style: none;
}
.reassurance li {
  color: #273443 !important;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1rem !important;
  letter-spacing: 0.25px;
  margin-bottom: 10px;
}

.dropin-container {
  margin-top: 20px;
  margin-bottom: 20px;
}
.dropin-container .braintree-upper-container {
  background-color: transparent !important;
  border-radius: 25px;
}
.dropin-container .braintree-upper-container:before {
  background-color: transparent !important;
}
.dropin-container .braintree-option {
  background-color: transparent !important;
  border-color: #DBB378;
  border-style: solid;
  border-width: 0 2px 2px;
  border-bottom-width: 1px;
}
.dropin-container .braintree-option:last-child {
  border-radius: 0 0 25px 25px;
  border-bottom-width: 2px;
}
.dropin-container .braintree-option:first-child {
  border-radius: 25px 25px 0 0;
  border-top-width: 2px;
  border-bottom-width: 1px;
}
.dropin-container .braintree-option__label, .dropin-container .braintree-sheet__text, .dropin-container .braintree-sheet__label, .dropin-container .braintree-heading {
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem !important;
  color: #134472 !important;
  letter-spacing: 0.25px;
  font-weight: 500;
}
@media screen and (min-width: 960px) {
  .dropin-container .braintree-option__label, .dropin-container .braintree-sheet__text, .dropin-container .braintree-sheet__label, .dropin-container .braintree-heading {
    font-size: 1.125rem !important;
    line-height: 25px;
  }
}
.dropin-container .braintree-heading {
  color: #273443 !important;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
}
.dropin-container .braintree-sheet {
  background-color: transparent !important;
  border: 2px solid #84D5F7;
  border-radius: 25px;
}
.dropin-container .braintree-toggle {
  color: #273443;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  padding: 6px 10px;
  border-radius: 25px;
  background-color: transparent;
  min-width: 50%;
  max-width: 65%;
  font-weight: 700;
}
.dropin-container .braintree-toggle span {
  border-bottom: none;
}
.dropin-container .braintree-sheet {
  border-color: transparent !important;
}
.dropin-container .braintree-sheet .braintree-sheet__content--button {
  background-color: transparent;
  color: #273443;
}
.dropin-container .braintree-sheet input {
  background-color: transparent;
  border: none;
  display: block;
  width: 100%;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  color: #134472;
  outline: none;
  padding: 0px;
  margin: 0;
  height: auto;
  text-transform: uppercase;
  border-radius: 0.25rem;
}
@media screen and (max-width: 359px) {
  .dropin-container .braintree-sheet input {
    font-size: 12px !important;
  }
}
.dropin-container .braintree-sheet input::-webkit-input-placeholder {
  color: #DBB378;
}
.dropin-container .braintree-sheet input::-moz-placeholder {
  color: #DBB378;
}
.dropin-container .braintree-sheet input:-ms-input-placeholder {
  color: #DBB378;
}
.dropin-container .braintree-sheet input:-moz-placeholder {
  color: #DBB378;
}
.dropin-container .braintree-sheet input:hover, .dropin-container .braintree-sheet input:focus {
  outline: none;
  box-shadow: none;
  border: none;
  background-color: transparent;
}
.dropin-container .braintree-sheet .gpay-button.black.short {
  display: block;
  width: 100%;
}
.dropin-container .braintree-sheet .gpay-button.black.short.hover, .dropin-container .braintree-sheet .gpay-button.black.short:hover, .dropin-container .braintree-sheet .gpay-button.black.short:focus {
  background-color: #01508C;
  background-image: url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg);
}
.dropin-container .braintree-sheet .braintree-sheet__content--form {
  padding: 11px 0px;
}
.dropin-container .braintree-sheet .braintree-sheet__header {
  flex-wrap: nowrap;
  border: none;
  padding: 0px;
}
.dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__logo--header {
  display: none;
}
.dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__label,
.dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__text {
  font-size: 1rem !important;
  letter-spacing: 0.25px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  margin-right: 5px;
  color: #273443 !important;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  font-weight: 700;
}
@media screen and (max-width: 359px) {
  .dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__label,
.dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__text {
    font-size: 12px !important;
  }
}
.dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__icons {
  display: flex;
  margin-left: auto;
  width: 100%;
  justify-content: flex-end;
}
.dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__icons .braintree-sheet__card-icon {
  height: 23px;
  width: auto;
  max-height: 23px;
  position: relative;
  display: block;
}
.dropin-container .braintree-sheet .braintree-sheet__header .braintree-sheet__icons .braintree-hidden {
  display: none !important;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form {
  width: 100%;
  position: relative;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  position: relative;
  padding: 0px !important;
  margin: 0px !important;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--has-error {
  border-color: #dc3545;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group.braintree-form__field-group--has-error .braintree-form__label {
  color: #dc3545 !important;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group .braintree-form__field-error {
  position: absolute;
  border-color: #DBF0FF;
  bottom: 8px;
  padding-bottom: 2px;
  padding-left: 16px;
  font-weight: 700;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group .braintree-form__field-error span {
  color: #DBF0FF;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label {
  display: block;
  width: 100%;
  padding: 0 8px 36px 0;
  position: relative;
  align-items: center;
  align-content: center;
  cursor: pointer;
  margin: 0px;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__icon-container {
  display: none;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__label {
  display: block;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  line-height: 1.4;
  color: #273443;
  white-space: nowrap;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 12px;
  padding-left: 16px;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__label .braintree-form__descriptor {
  display: none;
}
@media screen and (max-width: 359px) {
  .dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__label {
    font-size: 12px !important;
    width: 140px;
  }
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__field {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  align-content: center;
  cursor: pointer;
  margin: 0px;
  margin-left: auto;
  background-color: transparent;
  color: #134472;
  border: 2px solid #DBB378;
  border-radius: 25px;
  padding: 0 1rem;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__field .braintree-form__hosted-field {
  border: none !important;
  margin: 0;
}
.dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__field .braintree-form__hosted-field:hover, .dropin-container .braintree-sheet .braintree-sheet__content--form .braintree-form__field-group > label .braintree-form__field .braintree-form__hosted-field:focus {
  border: none;
}

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.dropzone {
  min-height: 225px;
  border: 2px solid #134472;
  background: white;
  padding: 20px 20px;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.dropzone {
  position: relative;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  width: 120px;
  margin: 0.5em;
}

.dropzone .dz-preview .dz-progress {
  display: block;
  height: 15px;
  border: 1px solid #aaa;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  display: block;
  height: 100%;
  width: 0;
  background: green;
}

.dropzone .dz-preview .dz-error-message {
  color: red;
  display: none;
}

.dropzone .dz-preview.dz-error .dz-error-message, .dropzone .dz-preview.dz-error .dz-error-mark {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  display: block;
}

.dropzone .dz-preview .dz-error-mark, .dropzone .dz-preview .dz-success-mark {
  position: absolute;
  display: none;
  left: 30px;
  top: 30px;
  width: 54px;
  height: 58px;
  left: 50%;
  margin-left: -27px;
}

.dropzone {
  background: transparent;
  border-radius: 12px;
  border: 2px solid #DBB378;
  border-image: none;
  margin-left: auto;
  margin-right: auto;
}

.dz-button {
  outline: none;
  box-shadow: none;
  font-size: 0.9375rem;
  line-height: 1.5;
  font-weight: 400;
  min-width: 180px;
  position: relative;
  color: #134472;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  background-color: transparent;
  padding: 0.625rem 1.625rem;
  border-radius: 30px;
  margin-top: 40px;
}
.dz-button:hover, .dz-button:focus {
  background: transparent;
  color: #134472;
}
.dz-button:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0px;
  right: 0px;
  margin: auto;
  top: -20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJSSURBVHgB7ZY9ctNAFMffrqIZZihQrBR0aCioScVQRSaCoXROYHOCyPYBsA8QJE6AcwJIB7EhpqKMuQBRyUCcERUzkXeXt7JlJFmShVIxyX/Gtrxfv/fevrcrgBv97yJQQZppG6qqakEQ+P7Y9f5lbmng3ee2cTmj+zihJZmxFTz8HqsK739/vx5eCqg/sVtAqZMAra7kUeB7P4fuBK4C1HftBhD6FsrJp4TXi6C0aLYM48KzstI4FBu3UdQZMGqCACPdLoD3KNoqAHqw2mnUrPYphs5HYwfT44PDeHdhSHWre4IrmGnYxcjty+ctq/MyE5okeJhQ9SihEsAtq90ThDSzvIrDwlCj5CJloSzg21hCvvLXm/YAe/YhJxPjMAz1CRekdefBo6Mfx87R7fuP5RCzAKkRSm79/vblQ5g0+tNOC2HNvNFpWBgB/Mhn2XY+etUna7xc1O88pDWri5ssHpaGJVda7tG68DJODDqfkw2TSsOkARIiBHihVylPQWZnnjj7FdVh3qCwPbgEM4JFGSq1DKWEBmRn4XHmWkIITybNAkjyTgZNs2xz+skdyHDEYXEom/HN6UfnMMzenAzHsnXnPyg2Yy8gx0uF0Df6s25TVehmVA4Ji0xbw5vDkEdgGPYs70BMLobO6yVQXjEEyJ50O2O0AVwMuGCnQaDsrBik0obsC8/bbO/e8ZmoR3+WR9v56GCM1m4rVDQEpfcgy1vKvqbb8IaYMKC9lcGE+Bz7/KHzGa4qrNuz2m7nDCpoAyoIQ+9Xe1eoCIzvyY2un/4AZVkqs0i9CZkAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 28px 28px;
}

.school-image-holder {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.school-image-holder .image-placement-left {
  margin: 0 15px;
  text-align: center;
  max-width: 200px;
  max-height: 160px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.school-image-holder .image-placement-left .personal-photo {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}
.school-image-holder .image-placement-left .personal-photo img {
  max-width: 100%;
  max-height: 100%;
}
.school-image-holder .image-placement-right {
  display: flex;
  border-left: 1px solid #E0E6ED;
  padding-left: 15px;
  margin-left: 5px;
  position: relative;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  align-content: center;
}
.school-image-holder .image-placement-right .schools-image-description {
  padding: 15px 0px 25px 0px;
  font-size: 12px;
  line-height: 14px;
  color: #7E7E7E;
}
.school-image-holder .image-placement-right .schools-image-description p {
  font-size: 12px;
  line-height: 14px;
  color: #7E7E7E;
}
.school-image-holder .image-placement-right .schools-image-description ul {
  position: relative;
  margin: 0px;
}
.school-image-holder .image-placement-right .schools-image-description ul li {
  font-size: 12px;
  line-height: 14px;
  color: #7E7E7E;
  margin-bottom: 5px;
}
.school-image-holder .image-placement-right .school-images-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px;
}
.school-image-holder .image-placement-right .school-images-btns .icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  width: 32px;
  height: 32px;
  border: 1px solid #ee5b5b;
  border-radius: 100%;
  text-align: center;
  color: #ee5b5b;
  font-size: 15px;
  line-height: normal;
  margin: 5px;
  transition: all 0.4s ease;
}
@media (prefers-reduced-motion: reduce) {
  .school-image-holder .image-placement-right .school-images-btns .icon-btn {
    transition: none;
  }
}
.school-image-holder .image-placement-right .school-images-btns .icon-btn:hover, .school-image-holder .image-placement-right .school-images-btns .icon-btn:focus {
  background: #ee5b5b;
  border-color: #ee5b5b;
  color: #fff;
}

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  touch-action: none;
  user-select: none;
}
.cropper-container img {
  display: block;
  height: 100%;
  image-orientation: 0deg;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%;
}
.cropper-wrap-box, .cropper-canvas, .cropper-drag-box, .cropper-crop-box, .cropper-modal {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.cropper-wrap-box, .cropper-canvas {
  overflow: hidden;
}
.cropper-drag-box {
  background-color: #fff;
  opacity: 0;
}
.cropper-modal {
  background-color: #000;
  opacity: 0.5;
}
.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, 0.75);
  overflow: hidden;
  width: 100%;
}
.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: 0.5;
  position: absolute;
}
.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: calc(100% / 3);
  left: 0;
  top: calc(100% / 3);
  width: 100%;
}
.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: calc(100% / 3);
  top: 0;
  width: calc(100% / 3);
}
.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: 0.75;
  position: absolute;
  top: 50%;
  width: 0;
}
.cropper-center::before, .cropper-center::after {
  background-color: #eee;
  content: " ";
  display: block;
  position: absolute;
}
.cropper-center::before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px;
}
.cropper-center::after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px;
}
.cropper-face, .cropper-line, .cropper-point {
  display: block;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  width: 100%;
}
.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0;
}
.cropper-line {
  background-color: #39f;
}
.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px;
}
.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px;
}
.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px;
}
.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0;
}
.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: 0.75;
  width: 5px;
}
.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%;
}
.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px;
}
.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%;
}
.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px;
}
.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px;
}
.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px;
}
.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px;
}
.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px;
}
@media (min-width: 768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px;
  }
}
@media (min-width: 992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px;
  }
}
@media (min-width: 1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: 0.75;
    width: 5px;
  }
}
.cropper-point.point-se::before {
  background-color: #39f;
  bottom: -50%;
  content: " ";
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%;
}
.cropper-invisible {
  opacity: 0;
}
.cropper-bg {
  background: #e5e5e5;
}
.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0;
}
.cropper-hidden {
  display: none !important;
}
.cropper-move {
  cursor: move;
}
.cropper-crop {
  cursor: crosshair;
}
.cropper-disabled .cropper-drag-box, .cropper-disabled .cropper-face, .cropper-disabled .cropper-line, .cropper-disabled .cropper-point {
  cursor: not-allowed;
}

#page-body {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 67vh;
}
#page-body #frontpage-baner {
  margin-top: -160px;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-baner {
    margin-top: -105px;
  }
}
#page-body #frontpage-baner .full-width-image {
  width: 100%;
  max-height: 900px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-baner .full-width-image {
    max-height: 740px;
  }
}
#page-body #frontpage-baner .full-width-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
  /*object-fit: cover;*/
}
#page-body #frontpage-baner .full-width-image .banner-text-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 2;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-baner .full-width-image .banner-text-holder {
    align-items: flex-end;
    justify-content: center;
  }
}
#page-body #frontpage-baner .full-width-image .banner-text-holder .banner-text {
  margin-left: auto;
  max-width: 435px;
  position: relative;
  text-align: center;
  background-color: rgba(19, 68, 114, 0.7);
  border-radius: 14px;
  padding: 24px 16px;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-baner .full-width-image .banner-text-holder .banner-text {
    margin: 0 auto 30px;
    padding: 20px 8px;
  }
}
#page-body #frontpage-baner .full-width-image .banner-text-holder .banner-text p {
  color: #DEF0E7;
  font-size: 1.125rem;
  text-align: center;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin-bottom: 28px;
}
#page-body #frontpage-baner .full-width-image .banner-text-holder .banner-text .banner-text-btn {
  text-align: center;
}
#page-body #frontpage-baner .full-width-image .banner-text-holder .banner-text .banner-text-btn .btn {
  margin: 0 auto;
  font-weight: 700;
  color: #134472;
  min-width: 280px;
  display: flex;
  max-width: 280px;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-weight: 700;
}
#page-body #frontpage-baner .full-width-image .banner-text-holder .banner-text .banner-text-btn .btn span {
  margin-left: 8px;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-baner .full-width-image .banner-text-holder .banner-text .banner-text-btn .btn {
    min-width: 240px;
    font-size: 0.8125rem;
  }
}
#page-body #frontpage-daniel {
  margin-top: 0;
  position: relative;
}
#page-body #frontpage-daniel .full-width-image {
  width: 100%;
  max-height: 900px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-daniel .full-width-image {
    max-height: 740px;
  }
}
#page-body #frontpage-daniel .full-width-image picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#page-body #frontpage-daniel .full-width-image picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#page-body #frontpage-daniel .full-width-image .daniel-text-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 2;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-daniel .full-width-image .daniel-text-holder {
    align-items: flex-end;
    justify-content: center;
  }
}
#page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text {
  margin-left: auto;
  max-width: 440px;
  position: relative;
  text-align: center;
  background-color: rgba(19, 68, 114, 0.7);
  border-radius: 14px;
  padding: 56px;
}
@media (min-width: 1400px) {
  #page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text {
    margin-right: 2%;
  }
}
@media (max-width: 1199.98px) {
  #page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text {
    padding: 24px 24px;
  }
}
@media (max-width: 991.98px) {
  #page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text {
    padding: 16px 16px;
  }
}
@media (max-width: 575.98px) {
  #page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text {
    margin: auto auto 10px;
    padding: 16px 12px;
  }
}
#page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text p {
  color: #DEF0E7;
  font-size: 1.125rem;
  text-align: center;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin-bottom: 28px;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text p {
    font-size: 1rem;
    margin-bottom: 16px;
  }
}
#page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text .banner-text-btn {
  text-align: center;
}
#page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text .banner-text-btn .btn {
  margin: 0 auto;
  font-weight: 700;
  color: #134472;
  min-width: 280px;
  display: flex;
  max-width: 280px;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-weight: 700;
}
#page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text .banner-text-btn .btn span {
  margin-left: 8px;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-daniel .full-width-image .daniel-text-holder .daniel-text .banner-text-btn .btn {
    min-width: 240px;
    font-size: 0.8125rem;
  }
}
#page-body #frontpage-testimonials-list {
  margin-top: 0;
  position: relative;
  padding-bottom: 0px;
  background-color: #134472;
}
#page-body #frontpage-testimonials-list .testimonial-list-btn {
  position: absolute;
  z-index: 2;
  top: 44%;
  width: 54px;
  height: 54px;
  outline: none;
  border: none;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  text-align: center;
  padding: 0px;
}
#page-body #frontpage-testimonials-list .testimonial-list-btn span {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  border-radius: 100%;
  background-color: #DBB378;
  transition: all 0.4s ease-in-out;
}
#page-body #frontpage-testimonials-list .testimonial-list-btn.prev {
  left: 40px;
}
@media (max-width: 1399.98px) {
  #page-body #frontpage-testimonials-list .testimonial-list-btn.prev {
    top: 15%;
  }
}
@media (max-width: 575.98px) {
  #page-body #frontpage-testimonials-list .testimonial-list-btn.prev {
    left: 0px;
  }
}
#page-body #frontpage-testimonials-list .testimonial-list-btn.next {
  right: 40px;
}
@media (max-width: 1399.98px) {
  #page-body #frontpage-testimonials-list .testimonial-list-btn.next {
    top: 15%;
  }
}
@media (max-width: 575.98px) {
  #page-body #frontpage-testimonials-list .testimonial-list-btn.next {
    right: 0px;
  }
}
#page-body #frontpage-testimonials-list .testimonial-list-btn:hover, #page-body #frontpage-testimonials-list .testimonial-list-btn:focus #page-body #frontpage-testimonials-list .testimonial-list-btn:active {
  outline: none;
  border: none;
  box-shadow: none;
}
#page-body #frontpage-testimonials-list .testimonial-list-btn:hover span, #page-body #frontpage-testimonials-list .testimonial-list-btn:focus #page-body #frontpage-testimonials-list .testimonial-list-btn:active span {
  background-color: #FFD58E;
}
#page-body #frontpage-testimonials-list .full-width-image {
  width: 100%;
  max-height: 900px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-testimonials-list .full-width-image {
    max-height: 740px;
  }
}
#page-body #frontpage-testimonials-list .full-width-image picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#page-body #frontpage-testimonials-list .full-width-image picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  top: 0px;
  left: 0px;
  z-index: 2;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder {
    align-items: flex-end;
    justify-content: center;
  }
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text {
  margin-left: auto;
  max-width: 440px;
  position: relative;
  text-align: center;
  background-color: rgba(19, 68, 114, 0.7);
  border-radius: 14px;
  padding: 56px;
  margin-bottom: 52px;
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text.left-position {
  margin-left: 0px;
  margin-right: auto;
}
@media (min-width: 1400px) {
  #page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text.left-position {
    margin-left: 8%;
  }
}
@media (min-width: 1400px) {
  #page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text {
    margin-right: 8%;
  }
}
@media (max-width: 1199.98px) {
  #page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text {
    padding: 24px 24px;
  }
}
@media (max-width: 991.98px) {
  #page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text {
    padding: 16px 16px;
  }
}
@media (max-width: 575.98px) {
  #page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text {
    margin: auto auto 20px;
    padding: 16px 12px;
  }
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text p {
  color: #DEF0E7;
  font-size: 1rem;
  text-align: left;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin-bottom: 28px;
}
@media (max-width: 575.98px) {
  #page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text p {
    margin-bottom: 16px;
  }
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text .testimonial-stars {
  text-align: left;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text .testimonial-stars .stars-holder {
  display: flex;
  margin-right: 12px;
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text .testimonial-stars .stars-holder span {
  margin-right: 6px;
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text .testimonial-stars .testimonial-author {
  position: relative;
}
#page-body #frontpage-testimonials-list .full-width-image .testimonial-text-holder .testimonial-text .testimonial-stars .testimonial-author label {
  margin: 0px;
  padding: 0px;
  font-weight: 500;
  color: #DEF0E7;
}

#frontpage-videos-list {
  padding: 80px 0px;
  position: relative;
  width: 100%;
}
#frontpage-videos-list .slick-track {
  display: flex !important;
}
#frontpage-videos-list .slick-slide {
  height: inherit !important;
}
#frontpage-videos-list .slick-slide > div {
  height: 100% !important;
}
#frontpage-videos-list .videos-list-btn {
  position: absolute;
  z-index: 2;
  top: 120px;
  width: 54px;
  height: 54px;
  outline: none;
  border: none;
  box-shadow: none;
  transition: all 0.4s ease-in-out;
  background-color: transparent;
  text-align: center;
  padding: 0px;
}
#frontpage-videos-list .videos-list-btn.slick-disabled {
  display: none !important;
}
@media (max-width: 767.98px) {
  #frontpage-videos-list .videos-list-btn {
    top: 75px;
  }
}
#frontpage-videos-list .videos-list-btn span {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  border-radius: 100%;
  background-color: #DBB378;
  transition: all 0.4s ease-in-out;
}
#frontpage-videos-list .videos-list-btn.prev {
  left: 0px;
}
@media (min-width: 1400px) {
  #frontpage-videos-list .videos-list-btn.prev {
    left: -10px;
  }
}
#frontpage-videos-list .videos-list-btn.next {
  right: 0px;
}
@media (min-width: 1400px) {
  #frontpage-videos-list .videos-list-btn.next {
    right: -14px;
  }
}
#frontpage-videos-list .videos-list-btn:hover, #frontpage-videos-list .videos-list-btn:focus #frontpage-videos-list .videos-list-btn:active {
  outline: none;
  border: none;
  box-shadow: none;
}
#frontpage-videos-list .videos-list-btn:hover span, #frontpage-videos-list .videos-list-btn:focus #frontpage-videos-list .videos-list-btn:active span {
  background-color: #FFD58E;
}
#frontpage-videos-list .video-box {
  position: relative;
  padding: 8px;
  height: 100%;
}
@media (max-width: 767.98px) {
  #frontpage-videos-list .video-box {
    padding: 0px;
  }
}
#frontpage-videos-list .video-box .video-box-explain {
  width: 100%;
  border-radius: 14px;
  background-color: #134472;
  display: flex;
  flex-direction: column;
  padding: 22px 32px;
  height: 100%;
  box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-box .video-box-explain {
    padding: 22px 22px;
  }
}
@media (max-width: 575.98px) {
  #frontpage-videos-list .video-box .video-box-explain {
    padding: 8px;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 500px;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header {
  display: flex;
  width: 100%;
  margin-bottom: 44px;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header {
    flex-wrap: wrap;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-number {
  width: 60px;
  min-width: 60px;
  text-align: center;
  display: flex;
  align-self: flex-start;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-number {
    align-self: center;
    margin: auto;
    width: 100%;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-number span.dog-icon {
  color: #DEF0E7;
  font-weight: 700;
  font-size: 84px;
  line-height: 1;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-number span.dog-icon {
    margin: 0px auto;
  }
}
@media (max-width: 575.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-number span.dog-icon {
    margin: 20px auto;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  align-self: flex-end;
}
@media (min-width: 1200px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name {
    height: 100%;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name h3, #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name .h3 {
  color: #DEF0E7;
  font-size: 29px;
  text-align: left;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  padding: 12px 0px 0px 25px;
  margin: 0px;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name h3, #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name .h3 {
    padding: 16px 0px 0px;
    margin: 0 auto;
  }
}
@media (max-width: 767.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name h3, #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name .h3 {
    padding: 0;
    text-align: center;
    margin: 0px auto 12px;
    font-size: 1.125rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name h3, #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-header .videos-name .h3 {
    padding: 16px 0px 0px;
    font-size: 24px;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-description {
  padding: 36px 0px 32px 0px;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-description p {
  color: #DEF0E7;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-description {
    padding-left: 0px;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-description .videos-content {
  margin: 16px 0 40px;
  display: flex;
  align-items: center;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-description .videos-content label {
  display: flex;
  align-items: center;
  margin-right: 32px;
  color: #DEF0E7;
  font-weight: 700;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-top .videos-description .videos-content label span {
  margin-right: 8px;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-content {
  position: relative;
  max-width: 500px;
  margin-top: auto;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-box .video-box-explain .video-box-content {
    padding-left: 0px;
  }
}
#frontpage-videos-list .video-box .video-box-explain .video-box-content .video-frame {
  border-radius: 14px;
  position: relative;
  padding-top: 56.25%;
  width: 99.7%;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-content .video-frame a.load-video-link {
  border: none;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-content .video-frame a.load-video-link.video-link-loaded {
  display: none;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-content .video-frame a.load-video-link picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-content .video-frame a.load-video-link picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#frontpage-videos-list .video-box .video-box-explain .video-box-content iframe {
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 14px;
}
#frontpage-videos-list #videos-details {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: all 0.6s ease-in-out;
  min-height: 100%;
}
#frontpage-videos-list #videos-details.active {
  opacity: 1;
  visibility: visible;
  z-index: 3;
}
@media (max-width: 575.98px) {
  #frontpage-videos-list #videos-details > .container {
    padding: 0 6px;
  }
}
#frontpage-videos-list #videos-details .videos-list-btn {
  top: 40%;
  z-index: 4;
}
@media (max-width: 767.98px) {
  #frontpage-videos-list #videos-details .videos-list-btn {
    top: 75px;
  }
}
#frontpage-videos-list #videos-details .close-details-view {
  position: absolute;
  width: 42px;
  height: 42px;
  right: 25px;
  top: 25px;
  z-index: 2;
}
@media (max-width: 575.98px) {
  #frontpage-videos-list #videos-details .close-details-view {
    right: 30px;
    top: 12px;
  }
}
#frontpage-videos-list .video-details-section {
  position: relative;
  padding: 6px;
  width: 100%;
  min-height: 100%;
  height: 100%;
}
#frontpage-videos-list .video-details-section .video-details-explain {
  width: 100%;
  border-radius: 14px;
  background-color: #134472;
  display: flex;
  flex-direction: column;
  padding: 22px 32px;
  background: #134472;
  border-radius: 14px;
  height: 100%;
  box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
  -ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain {
    padding: 22px 22px;
  }
}
@media (max-width: 991.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain > .row {
    max-width: 640px;
    margin: 0 auto;
  }
}
@media (max-width: 575.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain {
    padding: 0px;
  }
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 100%;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header {
  display: flex;
  width: 100%;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header {
    flex-wrap: wrap;
  }
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header .videos-number {
  width: 60px;
  min-width: 60px;
  text-align: center;
  display: flex;
  align-self: flex-start;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header .videos-number {
    align-self: center;
    margin: auto;
  }
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header .videos-number span.dog-icon {
  color: #DEF0E7;
  font-weight: 700;
  font-size: 84px;
  line-height: 1;
}
@media (max-width: 575.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header .videos-number span.dog-icon {
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header .videos-name {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  align-self: center;
  justify-content: center;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header .videos-name h3, #frontpage-videos-list .video-details-section .video-details-explain .video-details-top .video-header .videos-name .h3 {
  color: #DEF0E7;
  font-size: 32px;
  text-align: center;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .videos-description {
  padding: 36px 0px 12px 100px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .videos-description p {
  color: #DEF0E7;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain .video-details-top .videos-description {
    padding-left: 0px;
  }
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .videos-description .videos-content {
  margin: 16px 0 0px;
  display: flex;
  align-items: center;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .videos-description .videos-content label {
  display: flex;
  align-items: center;
  margin-right: 32px;
  color: #DEF0E7;
  font-weight: 700;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-top .videos-description .videos-content label span {
  margin-right: 8px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video {
  max-width: 960px;
  margin: 0 auto 44px;
  position: relative;
  padding: 36px 0px 12px 100px;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain .video-details-video {
    padding-left: 0px;
  }
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video .video-frame {
  border-radius: 14px;
  position: relative;
  padding-top: 56.25%;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video .video-frame a.load-video-link {
  border: none;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video .video-frame a.load-video-link.video-link-loaded {
  display: none;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video .video-frame a.load-video-link picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video .video-frame a.load-video-link picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video .video-frame a.load-video-link span.dog-icon {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-video .video-frame iframe {
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 14px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content {
  padding-left: 60px;
  padding-top: 28px;
  position: relative;
}
@media (max-width: 1199.98px) {
  #frontpage-videos-list .video-details-section .video-details-explain .video-details-content {
    padding-left: 0px;
  }
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content .videos-cta-hld {
  margin-bottom: 32px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content .videos-cta-hld .btn {
  min-width: 230px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content .accordion {
  padding: 8px 0px 0px 4px;
  position: relative;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content .accordion .accordion-body {
  padding: 0px;
  color: #DEF0E7;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content .accordion .accordion-body p {
  margin-bottom: 28px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content .accordion .accordion-item {
  border: none;
  position: relative;
  margin-bottom: 28px;
}
#frontpage-videos-list .video-details-section .video-details-explain .video-details-content .accordion .accordion-item .accordion-collapse {
  display: block;
}

#video-links-resources {
  position: relative;
  padding: 30px;
  background-color: #DEF0E7;
  border-radius: 14px;
  color: #134472;
}
@media (max-width: 1199.98px) {
  #video-links-resources {
    padding: 24px 16px;
  }
}
@media (max-width: 575.98px) {
  #video-links-resources {
    padding: 12px 8px;
  }
}
#video-links-resources .video-links-resources-filter {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
}
@media (max-width: 575.98px) {
  #video-links-resources .video-links-resources-filter {
    flex-wrap: wrap;
  }
}
#video-links-resources .video-links-resources-filter .filter-title {
  margin-right: 28px;
  position: relative;
}
@media (max-width: 575.98px) {
  #video-links-resources .video-links-resources-filter .filter-title {
    width: 100%;
    margin-bottom: 16px;
  }
}
#video-links-resources .video-links-resources-filter .filter-title h4, #video-links-resources .video-links-resources-filter .filter-title .h4 {
  font-weight: 700;
  font-size: 1rem;
  color: #134472;
  margin: 0px;
  padding: 0px;
}
#video-links-resources .video-links-resources-filter .resources-filter {
  display: flex;
  align-items: center;
  align-content: center;
}
@media (max-width: 575.98px) {
  #video-links-resources .video-links-resources-filter .resources-filter {
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
  }
}
#video-links-resources .video-links-resources-filter .resources-filter .btn {
  min-width: 40px;
  font-weight: 400;
  font-size: 0.8125rem;
  margin-left: 12px;
  border-color: #84D5F7;
  color: #134472;
}
@media (max-width: 575.98px) {
  #video-links-resources .video-links-resources-filter .resources-filter .btn {
    margin: 0px auto 12px 0;
  }
}
#video-links-resources .video-links-resources-filter .resources-filter .btn.active {
  background-color: #134472;
  color: #DEF0E7;
  border-color: #134472;
}
#video-links-resources .video-links-resources-list {
  position: relative;
}
#video-links-resources .video-links-resources-list .card {
  background-color: transparent;
  border: none;
  margin-top: 40px;
}
@media (min-width: 576px) {
  #video-links-resources .video-links-resources-list .card {
    flex-direction: row;
  }
}
#video-links-resources .video-links-resources-list .card .card-image-hld {
  position: relative;
  max-width: 300px;
  min-width: 300px;
  border-radius: 14px;
  max-height: 100%;
  display: flex;
  align-items: flex-start;
  align-content: center;
  margin-right: 24px;
  overflow: hidden;
}
@media (max-width: 1199.98px) {
  #video-links-resources .video-links-resources-list .card .card-image-hld {
    max-width: 180px;
    min-width: 180px;
  }
  #video-links-resources .video-links-resources-list .card .card-image-hld picture {
    height: auto !important;
  }
}
@media (max-width: 575.98px) {
  #video-links-resources .video-links-resources-list .card .card-image-hld {
    max-width: 100%;
    max-height: 100%;
  }
}
#video-links-resources .video-links-resources-list .card .card-image-hld picture {
  width: 100%;
  display: flex;
}
#video-links-resources .video-links-resources-list .card .card-image-hld picture img {
  height: auto;
  width: 100%;
  border-radius: 14px;
}
#video-links-resources .video-links-resources-list .card .card-body {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 12px 0;
}
@media (min-width: 576px) {
  #video-links-resources .video-links-resources-list .card .card-body {
    padding: 0px;
  }
}
#video-links-resources .video-links-resources-list .card .card-body h5, #video-links-resources .video-links-resources-list .card .card-body .h5 {
  font-weight: 700;
  font-size: 1rem;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: #134472;
  margin: 0px 0 16px 0;
  padding: 0px;
}
#video-links-resources .video-links-resources-list .card .card-body p {
  font-size: 0.875rem;
  color: #134472;
  margin: 0px 0 8px 0;
  padding: 0px;
}
#video-links-resources .video-links-resources-list .card .card-body .btn-hld {
  display: flex;
  width: auto;
  position: relative;
  margin-top: 16px;
}
#video-links-resources .video-links-resources-list .card .card-body .btn-hld a.btn {
  padding-left: 0px;
  text-decoration: none;
  text-align: left;
}
#video-links-resources .video-links-resources-list .card .card-body .btn-hld a.btn:hover svg path, #video-links-resources .video-links-resources-list .card .card-body .btn-hld a.btn:focus svg path, #video-links-resources .video-links-resources-list .card .card-body .btn-hld a.btn:active svg path {
  stroke: #84D5F7;
}

#video-details {
  position: relative;
  padding-left: 0px;
}
@media (min-width: 1400px) {
  #video-details {
    padding-left: 16px;
  }
}
@media (max-width: 991.98px) {
  #video-details {
    padding-left: 0px;
  }
}
#video-details .video-title {
  width: 100%;
  position: relative;
  text-align: left;
  margin-bottom: 16px;
}
@media (min-width: 1200px) {
  #video-details .video-title {
    padding-left: 8px;
  }
}
#video-details .video-title h1, #video-details .video-title .h1 {
  color: #fff;
  font-size: 40px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  max-width: 95%;
}
@media (max-width: 767.98px) {
  #video-details .video-title h1, #video-details .video-title .h1 {
    font-size: 24px;
  }
}
#video-details .video-title h1 span, #video-details .video-title .h1 span, #video-details .video-title h1 label, #video-details .video-title .h1 label {
  margin: 0 4px 0 0px;
}
#video-details .video-description {
  color: #E0E6ED;
  max-width: 720px;
}
@media (min-width: 1200px) {
  #video-details .video-description {
    padding-left: 8px;
  }
}
#video-details .video-description h2, #video-details .video-description .h2, #video-details .video-description h3, #video-details .video-description .h3, #video-details .video-description h4, #video-details .video-description .h4, #video-details .video-description h5, #video-details .video-description .h5, #video-details .video-description h6, #video-details .video-description .h6 {
  color: #fff;
}
#video-details .video-description h2, #video-details .video-description .h2, #video-details .video-description h3, #video-details .video-description .h3 {
  margin-top: 40px;
  margin-bottom: 12px;
}
@media (max-width: 767.98px) {
  #video-details .video-description h2, #video-details .video-description .h2, #video-details .video-description h3, #video-details .video-description .h3 {
    margin-top: 24px;
  }
}
#video-details .video-description h4, #video-details .video-description .h4, #video-details .video-description h5, #video-details .video-description .h5, #video-details .video-description h6, #video-details .video-description .h6 {
  margin-top: 24px;
  margin-bottom: 12px;
}
@media (max-width: 767.98px) {
  #video-details .video-description h4, #video-details .video-description .h4, #video-details .video-description h5, #video-details .video-description .h5, #video-details .video-description h6, #video-details .video-description .h6 {
    margin-top: 16px;
  }
}
#video-details .video-description p, #video-details .video-description label, #video-details .video-description mark, #video-details .video-description .mark, #video-details .video-description span, #video-details .video-description li {
  color: #E0E6ED;
}
#video-details .video-description a, #video-details .video-description p a {
  color: #fff;
}
#video-details .video-description blockquote {
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  position: relative;
  color: #DFDCC3;
  padding: 2px 0;
  border-style: solid;
  border-color: #DFDCC3;
  border-width: 0;
  border-left-width: 5px;
  margin: 0 auto 0 24px;
  padding-left: 16px;
  padding-right: 8px;
}
#video-details .video-description blockquote p, #video-details .video-description blockquote li {
  color: #DFDCC3;
}
#video-details .video-description blockquote::before {
  content: "";
  position: absolute;
  left: -40px;
  top: 2px;
}
#video-details .video-description ul {
  padding: 0px 0 12px 0;
  margin: 0px;
  list-style: none;
}
#video-details .video-description ul li {
  padding-left: 22px;
  position: relative;
  margin-bottom: 16px;
}
#video-details .video-description ul li:before {
  content: "⚹";
  color: #DBB378;
  font-size: 20px;
  position: absolute;
  left: 0px;
  top: 1px;
  line-height: 1;
}
#video-details .video-holder {
  max-width: 1040px;
  margin: 0 auto 48px;
  position: relative;
}
#video-details .video-holder .video-frame {
  border-radius: 14px;
  position: relative;
  padding-top: 56.25%;
}
#video-details .video-holder .video-frame a.load-video-link {
  border: none;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
#video-details .video-holder .video-frame a.load-video-link.video-link-loaded {
  display: none;
}
#video-details .video-holder .video-frame a.load-video-link picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#video-details .video-holder .video-frame a.load-video-link picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#video-details .video-holder .video-frame a.load-video-link span.dog-icon {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
#video-details .video-holder .video-frame iframe {
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 14px;
}
#video-details .video-holder .video-frame-holder {
  position: relative;
  background: linear-gradient(180deg, rgba(19, 68, 114, 0) 0%, rgba(21, 53, 82, 0.5) 100%);
  border-radius: 14px;
}
#video-details .video-holder .video-frame-holder iframe {
  border-radius: 14px;
}
#video-details .video-holder .video-frame-holder picture {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 14px;
}
#video-details .video-holder .video-frame-holder picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
  border-radius: 14px;
}
#video-details .video-holder .video-frame-holder .dog-icon {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
}
#video-details .video-holder .video-frame-holder .dog-icon svg {
  width: 100%;
  height: 100%;
}
#video-details .video-pagination-links {
  max-width: 960px;
  margin: 0 auto 30px;
  position: relative;
}

#video-chapter {
  position: relative;
  padding-left: 0px;
  margin-top: 30px;
}
@media (max-width: 1399.98px) {
  #video-chapter {
    padding-left: 16px;
  }
}
@media (max-width: 991.98px) {
  #video-chapter {
    padding-left: 0px;
  }
}
#video-chapter .video-operating-title {
  display: flex;
  margin: 16px 0 40px;
}
@media (min-width: 1200px) {
  #video-chapter .video-operating-title {
    padding-left: 8px;
  }
}
#video-chapter .video-operating-title h2, #video-chapter .video-operating-title .h2 {
  color: #DFDCC3;
  font-size: 1.125rem;
  margin: 0px;
  padding: 0px;
}
#video-chapter .video-shownotes {
  position: relative;
  padding-right: 32px;
  width: 100%;
  font-size: 1rem;
  color: #E0E6ED;
  max-width: 720px;
}
@media (min-width: 1200px) {
  #video-chapter .video-shownotes {
    padding-left: 8px;
  }
}
@media (max-width: 767.98px) {
  #video-chapter .video-shownotes {
    padding-right: 0px;
  }
}
#video-chapter .video-shownotes h2, #video-chapter .video-shownotes .h2, #video-chapter .video-shownotes h3, #video-chapter .video-shownotes .h3, #video-chapter .video-shownotes h4, #video-chapter .video-shownotes .h4, #video-chapter .video-shownotes h5, #video-chapter .video-shownotes .h5, #video-chapter .video-shownotes h6, #video-chapter .video-shownotes .h6 {
  color: #fff;
}
#video-chapter .video-shownotes h2, #video-chapter .video-shownotes .h2, #video-chapter .video-shownotes h3, #video-chapter .video-shownotes .h3 {
  margin-top: 40px;
  margin-bottom: 16px;
}
@media (max-width: 767.98px) {
  #video-chapter .video-shownotes h2, #video-chapter .video-shownotes .h2, #video-chapter .video-shownotes h3, #video-chapter .video-shownotes .h3 {
    margin-top: 24px;
    margin-bottom: 12px;
  }
}
#video-chapter .video-shownotes h4, #video-chapter .video-shownotes .h4, #video-chapter .video-shownotes h5, #video-chapter .video-shownotes .h5, #video-chapter .video-shownotes h6, #video-chapter .video-shownotes .h6 {
  margin-top: 24px;
  margin-bottom: 12px;
}
@media (max-width: 767.98px) {
  #video-chapter .video-shownotes h4, #video-chapter .video-shownotes .h4, #video-chapter .video-shownotes h5, #video-chapter .video-shownotes .h5, #video-chapter .video-shownotes h6, #video-chapter .video-shownotes .h6 {
    margin-top: 16px;
  }
}
#video-chapter .video-shownotes p, #video-chapter .video-shownotes label, #video-chapter .video-shownotes mark, #video-chapter .video-shownotes .mark, #video-chapter .video-shownotes span, #video-chapter .video-shownotes li {
  color: #E0E6ED;
  margin-bottom: 12px;
}
#video-chapter .video-shownotes a, #video-chapter .video-shownotes p a {
  color: #fff;
}
#video-chapter .video-shownotes blockquote {
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  position: relative;
  color: #DFDCC3;
  padding: 2px 0;
  border-style: solid;
  border-color: #DFDCC3;
  border-width: 0;
  border-left-width: 5px;
  margin: 0 auto 0 24px;
  padding-left: 16px;
  padding-right: 8px;
}
#video-chapter .video-shownotes blockquote p, #video-chapter .video-shownotes blockquote li {
  color: #DFDCC3;
}
#video-chapter .video-shownotes blockquote::before {
  content: "";
  position: absolute;
  left: -40px;
  top: 2px;
}
#video-chapter .video-shownotes ul {
  padding: 0px 0 12px 0;
  margin: 0px;
  list-style: none;
}
#video-chapter .video-shownotes ul li {
  padding-left: 22px;
  position: relative;
  margin-bottom: 16px;
}
#video-chapter .video-shownotes ul li:before {
  content: "⚹";
  color: #DBB378;
  font-size: 20px;
  position: absolute;
  left: 0px;
  top: 1px;
  line-height: 1;
}
#video-chapter .chapter-video-box {
  display: block;
  width: 100%;
}
#video-chapter .chapter-video-box .video-box {
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  min-height: 90px;
  padding: 0px 24px;
  border: 1px solid #2B5780;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.4s ease-in-out;
}
#video-chapter .chapter-video-box .video-box .play-icon {
  min-width: 32px;
  margin-right: 16px;
  cursor: pointer;
}
#video-chapter .chapter-video-box .video-box label {
  color: #DEF0E7;
  font-size: 0.875rem;
  margin: 0px;
  width: 100%;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
#video-chapter .chapter-video-box .video-box mark, #video-chapter .chapter-video-box .video-box .mark {
  margin-left: auto;
  display: flex;
  align-items: center;
  align-content: center;
  color: #DEF0E7;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0;
  margin: 0px;
  white-space: nowrap;
  cursor: pointer;
}
#video-chapter .chapter-video-box .video-box mark span, #video-chapter .chapter-video-box .video-box .mark span {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
#video-chapter .chapter-video-box .video-box mark span svg, #video-chapter .chapter-video-box .video-box .mark span svg {
  vertical-align: baseline;
  width: 100%;
  height: 100%;
}
#video-chapter .chapter-video-box .video-box:hover, #video-chapter .chapter-video-box .video-box:focus {
  border-color: #84D5F7;
}
#video-chapter .chapter-video-box .video-box:hover label, #video-chapter .chapter-video-box .video-box:focus label {
  color: #DFDCC3;
}

#video-links-list {
  display: block;
  margin: 20px auto;
}

#products-list {
  padding: 0px 0px 80px;
  width: 100%;
  position: relative;
}
#products-list .product-list-btn {
  position: absolute;
  z-index: 9999;
  top: 245px;
  width: 60px;
  height: 60px;
  outline: none;
  border: none;
  box-shadow: none;
  transition: all 0.6s ease-in-out;
  background-color: transparent;
  text-align: center;
  padding: 0px;
}
@media (min-width: 576px) {
  #products-list .product-list-btn {
    display: none;
  }
}
#products-list .product-list-btn span {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  border-radius: 100%;
  background-color: #134472;
  transition: all 0.6s ease-in-out;
}
#products-list .product-list-btn.prev {
  left: 0px;
}
#products-list .product-list-btn.next {
  right: 0px;
}
#products-list .product-list-btn:hover, #products-list .product-list-btn:focus #products-list .product-list-btn:active {
  outline: none;
  border: none;
  box-shadow: none;
}
#products-list .product-list-btn:hover span, #products-list .product-list-btn:focus #products-list .product-list-btn:active span {
  background-color: #84D5F7;
}
#products-list .products-table {
  display: flex;
  width: 100%;
}
@media (max-width: 991.98px) {
  #products-list .products-table {
    flex-wrap: wrap;
  }
}
#products-list .products-table .products-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30%;
}
@media (max-width: 991.98px) {
  #products-list .products-table .products-left {
    width: 100%;
    max-width: 100%;
    justify-content: center;
  }
}
#products-list .products-table .offer-items {
  width: 100%;
}
#products-list .products-table .products-rigth {
  width: 100%;
  border-radius: 8px;
  background-color: #DEF0E7;
}
@media (min-width: 576px) {
  #products-list .products-table .products-rigth {
    display: flex;
  }
  #products-list .products-table .products-rigth .offer-items {
    display: flex;
  }
  #products-list .products-table .products-rigth .offer-items .offer-box {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  #products-list .products-table .products-rigth .offer-items .offer-box .offer-box-form {
    display: flex;
    flex-direction: column;
  }
}
#products-list .products-table .offers {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 220px;
  position: relative;
  flex-wrap: wrap;
  padding: 16px 0px;
  border: 2px solid transparent;
}
#products-list .products-table .offers.bb-none {
  border: none;
  min-height: 218px;
}
@media (max-width: 991.98px) {
  #products-list .products-table .offers.bb-none {
    min-height: 80px;
  }
}
#products-list .products-table .offers.bb {
  border-bottom-color: #fff;
}
#products-list .products-table .offers.bb.colored {
  border-bottom-color: #2B5780;
}
#products-list .products-table .offers.br {
  border-right-color: #fff;
}
@media (max-width: 575.98px) {
  #products-list .products-table .offers {
    border-right: none !important;
  }
}
#products-list .products-table .offers h3, #products-list .products-table .offers .h3 {
  margin: 0px;
  padding: 0px;
  color: #134472;
  font-size: 32px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: left;
  width: 100%;
}
@media (max-width: 991.98px) {
  #products-list .products-table .offers h3, #products-list .products-table .offers .h3 {
    text-align: center;
  }
}
#products-list .products-table .offers p {
  width: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
  color: #134472;
  font-size: 0.875rem;
  text-align: center;
}
#products-list .products-table .offers .offer-price {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 22px 8px 16px;
  text-align: center;
  align-content: center;
}
#products-list .products-table .offers .offer-price .currency {
  color: #134472;
  font-size: 1.125rem;
  font-weight: 700;
}
#products-list .products-table .offers .offer-price mark, #products-list .products-table .offers .offer-price .mark {
  color: #134472;
  font-size: 52px;
  font-weight: 700;
  line-height: 1;
  padding: 0px;
}
#products-list .products-table .offers .offer-benefits {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#products-list .products-table .offers .offer-benefits label {
  margin: 0 auto;
  display: block;
  border-radius: 34px;
  border: 1px solid #134472;
  padding: 12px 20px;
  text-align: center;
  color: #134472;
  font-weight: 700;
}
#products-list .products-table .offers .offer-benefits.colored label {
  border-color: #DBB378;
  color: #DBB378;
}
#products-list .products-table .course-offers {
  display: flex;
  flex-direction: column;
  background-color: #134472;
  border-radius: 4px 0 0 4px;
  border-top: 2px solid #2B5780;
}
@media (max-width: 991.98px) {
  #products-list .products-table .course-offers {
    display: none;
  }
}
#products-list .products-table .offer-row {
  display: flex;
  min-height: 90px;
  border: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}
@media (max-width: 991.98px) {
  #products-list .products-table .offer-row {
    justify-content: space-between;
    padding: 0 16px;
  }
}
#products-list .products-table .offer-row p, #products-list .products-table .offer-row label {
  color: #134472;
  font-weight: 500;
}
#products-list .products-table .offer-row.bb {
  border-bottom: 2px solid #fff;
}
#products-list .products-table .offer-row.bb.colored {
  border-bottom-color: #2B5780;
}
#products-list .products-table .offer-row.br {
  border-right: 2px solid #fff;
}
#products-list .products-table .offer-row.br.colored {
  border-right-color: #2B5780;
}
@media (max-width: 575.98px) {
  #products-list .products-table .offer-row {
    border-right: none !important;
    flex-wrap: wrap;
  }
}
#products-list .products-table .offer-row .offer-title {
  white-space: nowrap;
  padding: 0px 24px;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
#products-list .products-table .offer-row .offer-title mark, #products-list .products-table .offer-row .offer-title .mark {
  font-weight: 500;
  color: #fff;
}
#products-list .products-table .offer-row .offer-title:before {
  content: "";
  width: 2px;
  top: 0px;
  background-color: #2B5780;
  left: 0px;
  position: absolute;
  display: flex;
  height: 100%;
}
#products-list .products-table .offer-row .offer-column {
  padding: 0 20px;
  text-align: center;
  display: flex;
  max-width: 120px;
  width: 120px;
}
#products-list .products-table .offer-row .offer-column label {
  color: #fff;
  font-size: 0.8125rem;
}
#products-list .products-table .offer-row .offer-column label span {
  text-align: center;
  font-size: 1.125rem;
  color: #fff;
}
#products-list .products-table .offer-row input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
#products-list .products-table .offer-row input ~ .radio-btn {
  width: 18px;
  height: 18px;
  border: 2px solid #134472;
  border-radius: 100%;
  display: inline-block;
  margin-right: 8px;
  position: relative;
  margin-left: auto;
}
@media (min-width: 576px) {
  #products-list .products-table .offer-row input ~ .radio-btn ~ label {
    margin-right: auto;
  }
}
#products-list .products-table .offer-row input:checked ~ .radio-btn:after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #134472;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
  display: block;
  border-radius: 100%;
}
#products-list .products-table .offers-button {
  display: flex;
  min-height: 96px;
  border: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}
#products-list .products-table .offers-button.bb {
  border-bottom: 2px solid #fff;
}
#products-list .products-table .offers-button.bb.colored {
  border-bottom-color: #2B5780;
}
#products-list .products-table .offers-button.br {
  border-right: 2px solid #fff;
}
#products-list .products-table .offers-button.br.colored {
  border-right-color: #2B5780;
}
@media (max-width: 575.98px) {
  #products-list .products-table .offers-button {
    border-right: none !important;
  }
}
#products-list .products-table .offers-button .btn {
  display: flex;
  padding: 17px 28px;
  align-items: center;
  align-content: center;
  max-width: 230px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-weight: 700;
  outline: none;
  box-shadow: none;
}
#products-list .products-table .offers-button .btn.btn-outline-primary {
  color: #134472;
}
#products-list .products-table .offers-button .btn.btn-primary {
  color: #134472;
}

.custom-form-holder {
  position: relative;
  max-width: 560px;
  margin: 0 auto;
  padding-bottom: 40px;
}
.custom-form-holder.full-width {
  max-width: 980px;
}
.custom-form-holder h1, .custom-form-holder .h1 {
  margin: 0px auto 40px;
  padding: 0px;
  color: #134472;
  font-size: 32px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  text-align: center;
  width: 100%;
  font-weight: 400;
}
@media (max-width: 991.98px) {
  .custom-form-holder h1, .custom-form-holder .h1 {
    text-align: center;
  }
}
.custom-form-holder textarea {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 2px solid #DBB378;
  border-radius: 25px;
  padding: 1rem;
  outline: none;
  color: #134472;
  font-weight: 500;
  font-size: 1rem;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.custom-form-holder .form-group {
  margin-bottom: 30px;
  position: relative;
}
.custom-form-holder .form-group .col-form-label {
  padding-top: 0px;
  padding-bottom: 12px;
  padding-left: 16px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #273443;
}
.custom-form-holder .form-group .forgot-pass-link {
  position: absolute;
  right: 16px;
  top: 0px;
  font-weight: 700;
  color: #134472;
  font-size: 0.875rem;
}
.custom-form-holder .form-group .btn {
  margin: 0 auto;
  min-width: 230px;
  align-self: center;
  justify-content: center;
  display: flex;
}
.custom-form-holder .form-group.form-btn {
  padding-top: 28px;
}
.custom-form-holder .form-group.label-content {
  text-align: center;
}
.custom-form-holder .form-group.label-content label {
  color: #134472;
  font-size: 0.875rem;
  margin: 0px;
  padding: 12px 0px;
  text-align: center;
  display: block;
}
.custom-form-holder .form-group.label-content a.link {
  color: #134472;
  font-size: 0.875rem;
  margin-bottom: 12px;
  text-align: center;
  font-weight: 700;
}
.custom-form-holder .form-group.social-content {
  position: relative;
  width: 100%;
}
.custom-form-holder .form-group.social-content a {
  display: flex;
  margin: 0 0px 20px;
  width: 100%;
  min-height: 49px;
  align-items: center;
  align-content: center;
  border-radius: 25px;
  border: 2px solid #DBB378;
  padding: 8px;
}
.custom-form-holder .form-group.social-content a span {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 33px;
  height: 33px;
  min-width: 33px;
}
.custom-form-holder .form-group.social-content a label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  font-weight: 500;
  color: #134472;
  text-align: center;
  line-height: 1;
  margin: 0;
  padding: 0 10px;
}
.custom-form-holder .form-group.social-content a.btn-google span {
  background-color: #DB4437;
}
.custom-form-holder .form-group.social-content a.btn-facebook span {
  background-color: #4267B2;
}
.custom-form-holder .course-offers {
  display: flex;
  flex-direction: column;
  background-color: #134472;
  border-radius: 25px;
  border: none;
  margin-bottom: 40px;
}
.custom-form-holder .offer-row {
  display: flex;
  min-height: 90px;
  border: none;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}
@media (max-width: 991.98px) {
  .custom-form-holder .offer-row {
    justify-content: space-between;
    padding: 0 16px;
  }
}
.custom-form-holder .offer-row p, .custom-form-holder .offer-row label {
  color: #134472;
  font-weight: 500;
}
.custom-form-holder .offer-row.bb {
  border-bottom: 2px solid #fff;
}
.custom-form-holder .offer-row.bb.colored {
  border-bottom-color: #2B5780;
}
.custom-form-holder .offer-row.br {
  border-right: 2px solid #fff;
}
.custom-form-holder .offer-row.br.colored {
  border-right-color: #2B5780;
}
@media (max-width: 575.98px) {
  .custom-form-holder .offer-row {
    border-right: none !important;
    flex-wrap: wrap;
  }
}
.custom-form-holder .offer-row .offer-title {
  white-space: nowrap;
  padding: 0px 24px;
  color: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.custom-form-holder .offer-row .offer-title mark, .custom-form-holder .offer-row .offer-title .mark {
  font-weight: 500;
  font-size: 1.125rem;
  color: #DEF0E7;
}
@media (max-width: 575.98px) {
  .custom-form-holder .offer-row .offer-title {
    padding: 0px 8px;
  }
}
.custom-form-holder .offer-row .offer-price {
  padding: 0 20px;
  text-align: center;
  display: flex;
  margin-left: auto;
  justify-content: center;
  text-align: center;
  align-content: center;
}
.custom-form-holder .offer-row .offer-price .currency {
  color: #DEF0E7;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0px;
  margin: 0 3px 0 0;
  line-height: 1.3;
}
.custom-form-holder .offer-row .offer-price mark, .custom-form-holder .offer-row .offer-price .mark {
  color: #DEF0E7;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1;
  padding: 0px;
}

.field-box {
  width: 100%;
  border-radius: 25px;
  display: block;
  margin: 0 auto 15px;
}
.field-box fieldset {
  border-style: none;
  padding: 0;
  background: transparent;
  border-radius: 25px;
  box-shadow: none;
  border: none;
  width: 100%;
  margin: 0px 0 0px;
  /*input, button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    color: #fff;
  }
  input{
  	-webkit-animation: 1ms void-animation-out;
  	&:-webkit-autofill {
  	  transition: background-color 100000000s;
  	  -webkit-animation: 1ms void-animation-out;
  	}
  }*/
}
.field-box fieldset.paypal {
  margin-top: auto;
}
.field-box fieldset .field {
  width: 100%;
}
.field-box fieldset .field.half-width {
  width: calc(50% - (5px / 2));
}
.field-box fieldset .field.third-width {
  width: calc(33% - (5px / 3));
}
.field-box fieldset .field + .field {
  margin-top: 6px;
}
.field-box fieldset legend {
  float: left;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 12px;
  padding-left: 16px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #273443;
  margin: 0px;
}
.field-box fieldset legend + * {
  clear: both;
}
.field-box fieldset .card-only {
  display: flex;
  min-width: 70px;
  white-space: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-top: 0px;
  padding-bottom: 12px;
  padding-left: 16px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #273443;
  margin: 0px;
}
.field-box fieldset .card-only i {
  margin-right: 4px;
  color: #007ec0;
}
.field-box fieldset .payment-request-available {
  display: none;
}
.field-box fieldset .btn-dark {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 12px auto 0px;
  min-height: 52px;
}
.field-box fieldset .practice-card {
  background-color: transparent;
  border-radius: 25px;
  padding: 1rem;
  border: 2px solid #DBB378;
}
.field-box fieldset #stripe-card {
  padding: 10px;
  margin-bottom: 2px;
}

#pay-paypal-bottom {
  display: flex;
  margin: 0 0px 20px;
  width: 100%;
  min-height: 46px;
  align-items: center;
  align-content: center;
  border-radius: 25px;
  border: 2px solid #DBB378;
  padding: 6px;
}
#pay-paypal-bottom .paypal-logo {
  display: inline-block;
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.6);
  font-family: Verdana, Tahoma;
  font-weight: bold;
  font-size: 26px;
  margin: 0 auto;
}
#pay-paypal-bottom .paypal-logo i:first-child {
  color: #253b80;
}
#pay-paypal-bottom .paypal-logo i:last-child {
  color: #179bd7;
}

.sidebar-account {
  position: relative;
  width: 100%;
}
.sidebar-account .sidebar-content {
  display: flex;
  max-width: 280px;
  flex-direction: column;
  width: 100%;
  background: #134472;
  border-radius: 14px;
  color: #DEF0E7;
  padding: 44px 8px;
}
@media (max-width: 575.98px) {
  .sidebar-account .sidebar-content {
    margin: 0 auto 40px;
  }
}
.sidebar-account .sidebar-content .sidebar-image-hld {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
}
.sidebar-account .sidebar-content .sidebar-image-hld .sidebar-image {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.sidebar-account .sidebar-content .sidebar-image-hld .sidebar-image .image-wrapper {
  margin: auto;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  border: 1px solid #DEF0E7;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.sidebar-account .sidebar-content .sidebar-image-hld .sidebar-image .image-wrapper picture {
  width: 100%;
  height: 100%;
  display: flex;
}
.sidebar-account .sidebar-content .sidebar-image-hld .sidebar-image .image-wrapper picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
.sidebar-account .sidebar-content .sidebar-user {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 8px;
  margin-bottom: 24px;
}
.sidebar-account .sidebar-content .sidebar-user label {
  font-weight: 700;
  font-size: 0.875rem;
  color: #DEF0E7;
  text-align: center;
}
.sidebar-account .sidebar-content .sidebar-user mark, .sidebar-account .sidebar-content .sidebar-user .mark {
  font-size: 0.875rem;
  color: #DEF0E7;
  text-align: center;
}
.sidebar-account .sidebar-content .sidebar-menu {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 8px;
  margin-bottom: 24px;
}
.sidebar-account .sidebar-content .sidebar-menu ul {
  width: 100%;
  list-style: none;
  padding: 0px;
}
.sidebar-account .sidebar-content .sidebar-menu ul li {
  width: 100%;
  text-align: center;
  margin-bottom: 6px;
}
.sidebar-account .sidebar-content .sidebar-menu ul li a {
  display: block;
  text-align: center;
  padding: 6px 0px 0px;
  font-weight: 700;
  font-size: 0.875rem;
  color: #84D5F7;
}
.sidebar-account .sidebar-content .sidebar-menu ul li a span {
  font-weight: 700;
  font-size: 0.875rem;
  color: #84D5F7;
  position: relative;
  transition: all 0.4s ease-in-out;
}
.sidebar-account .sidebar-content .sidebar-menu ul li a span:before {
  content: "●";
  font-size: 1.125rem;
  padding-right: 8px;
  font-weight: 700;
  color: #84D5F7;
  opacity: 0;
  visibility: hidden;
}
.sidebar-account .sidebar-content .sidebar-menu ul li a span:after {
  content: "●";
  font-size: 1.125rem;
  padding-left: 8px;
  font-weight: 700;
  color: #84D5F7;
  opacity: 0;
  visibility: hidden;
}
.sidebar-account .sidebar-content .sidebar-menu ul li a.active span:before, .sidebar-account .sidebar-content .sidebar-menu ul li a:hover span:before, .sidebar-account .sidebar-content .sidebar-menu ul li a:focus span:before {
  opacity: 1;
  visibility: visible;
}
.sidebar-account .sidebar-content .sidebar-menu ul li a.active span:after, .sidebar-account .sidebar-content .sidebar-menu ul li a:hover span:after, .sidebar-account .sidebar-content .sidebar-menu ul li a:focus span:after {
  opacity: 1;
  visibility: visible;
}
.sidebar-account .sidebar-content .sidebar-logout {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}
.sidebar-account .sidebar-content .sidebar-logout a {
  display: block;
  text-align: center;
  padding: 6px 0px 0px;
  font-weight: 700;
  font-size: 0.875rem;
  color: #DFDCC3;
}

.account-image-full {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  margin-bottom: 24px;
}
.account-image-full .account-image {
  margin: auto 0;
  width: 110px;
  min-width: 110px;
  height: 110px;
  min-height: 110px;
  border-radius: 100%;
  border: 2px solid #134472;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.account-image-full .account-image picture {
  width: 100%;
  height: 100%;
  display: flex;
}
.account-image-full .account-image picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
.account-image-full .account-image-buttons {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  margin-left: 40px;
}

#terms {
  margin-bottom: 60px;
}
#terms h1, #terms .h1 {
  margin: 40px auto 60px;
  text-align: center;
  font-size: 28px;
  line-height: 1.4;
  color: #134472;
}
#terms h2, #terms .h2 {
  font-size: 20px;
  line-height: 1.4;
  color: #134472;
  margin: 60px auto 30px;
}
#terms h3, #terms .h3 {
  margin: 40px auto 16px;
  font-size: 18px;
  line-height: 1.4;
  color: #134472;
}
#terms p, #terms li {
  font-size: 1rem;
  line-height: 1.6;
  color: #134472;
  margin-bottom: 16px;
}
#terms p a, #terms li a {
  color: #134472;
}
#terms p a:hover #terms p a:focus, #terms p a:hover #terms li a:focus, #terms li a:hover #terms p a:focus, #terms li a:hover #terms li a:focus {
  color: #84D5F7;
}
#terms p span.beacon-dog-icon, #terms li span.beacon-dog-icon {
  padding-bottom: 4px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
#terms ul {
  margin: 16px auto 16px;
}
#terms ul li {
  margin-bottom: 8px;
}

#testimonials-view {
  position: relative;
}
#testimonials-view .page-title-hld {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 40px;
}
@media (max-width: 575.98px) {
  #testimonials-view .page-title-hld {
    flex-wrap: wrap;
  }
}
#testimonials-view .page-title-hld .page-title {
  display: flex;
}
@media (max-width: 575.98px) {
  #testimonials-view .page-title-hld .page-title {
    margin-bottom: 12px;
  }
}
#testimonials-view .page-title-hld .page-title h1, #testimonials-view .page-title-hld .page-title .h1 {
  color: #134472;
  font-size: 32px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin: 0px;
}
#testimonials-view .page-title-hld .page-popup {
  margin-left: auto;
}
#testimonials-view #testimonials-list {
  position: relative;
  margin-bottom: 80px;
}
#testimonials-view #testimonials-list .testimonial-card {
  background: #134472;
  border-radius: 14px;
  border: none;
  padding: 28px 44px;
}
#testimonials-view #testimonials-list .testimonial-card.testimonial-with-image {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 24px;
}
@media (max-width: 767.98px) {
  #testimonials-view #testimonials-list .testimonial-card.testimonial-with-image {
    flex-direction: column;
  }
}
#testimonials-view #testimonials-list .testimonial-card.testimonial-with-image .testimonial-picture {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 45%;
  min-width: 45%;
  margin-right: 24px;
  border-radius: 14px;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  #testimonials-view #testimonials-list .testimonial-card.testimonial-with-image .testimonial-picture {
    max-width: 100%;
    min-width: 100%;
    margin-right: auto;
    margin-bottom: 24px;
  }
}
#testimonials-view #testimonials-list .testimonial-card.testimonial-with-image .testimonial-picture picture {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 14px;
}
#testimonials-view #testimonials-list .testimonial-card.testimonial-with-image .testimonial-picture picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
  border-radius: 14px;
}
#testimonials-view #testimonials-list .testimonial-card.testimonial-with-image .testimonial-text-holder {
  display: flex;
  flex-direction: column;
}
#testimonials-view #testimonials-list .testimonial-card.contact {
  background: #84D5F7;
}
#testimonials-view #testimonials-list .testimonial-card.contact .testimonial-title h2, #testimonials-view #testimonials-list .testimonial-card.contact .testimonial-title .h2 {
  color: #134472;
}
#testimonials-view #testimonials-list .testimonial-card.contact .testimonial-text-holder .testimonial-row .testimonial-description p {
  color: #134472;
}
#testimonials-view #testimonials-list .testimonial-card.contact .testimonial-text-holder .testimonial-row .testimonial-date label {
  color: #134472;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-title {
  margin-bottom: 6px;
  width: 100%;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-title h2, #testimonials-view #testimonials-list .testimonial-card .testimonial-title .h2 {
  color: #DFDCC3;
  font-weight: 600;
  font-size: 1rem;
  padding: 0;
  margin: 0px;
  color: #DFDCC3;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
@media (max-width: 767.98px) {
  #testimonials-view #testimonials-list .testimonial-card .testimonial-title h2, #testimonials-view #testimonials-list .testimonial-card .testimonial-title .h2 {
    padding: 0;
    text-align: center;
    margin: 0px auto 12px;
  }
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-text-holder {
  display: flex;
  width: 100%;
  flex-direction: column;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-text-holder .testimonial-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-text-holder .testimonial-row .testimonial-description p {
  color: #DFDCC3;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-text-holder .testimonial-row .testimonial-date {
  margin-left: auto;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-text-holder .testimonial-row .testimonial-date label {
  text-align: right;
  margin: 0px;
  color: #DFDCC3;
  font-size: 0.8125rem;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-text-holder .stars-holder {
  display: flex;
  margin-right: 12px;
  margin-bottom: 8px;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-text-holder .stars-holder span {
  margin-right: 6px;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 12px;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-body p, #testimonials-view #testimonials-list .testimonial-card .testimonial-body li {
  font-size: 0.875rem;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #DFDCC3;
  margin-bottom: 16px;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-body ul, #testimonials-view #testimonials-list .testimonial-card .testimonial-body ol {
  padding-left: 0px;
  list-style-position: inside;
}
#testimonials-view #testimonials-list .testimonial-card .testimonial-body ul {
  list-style: none;
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video {
  max-width: 680px;
  margin: 0 auto;
  position: relative;
  padding: 0;
}
@media (max-width: 1199.98px) {
  #testimonials-view #testimonials-list .testimonial-card .video-details-video {
    padding-left: 0px;
  }
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video .video-frame {
  border-radius: 14px;
  position: relative;
  padding-top: 56.25%;
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video .video-frame a.load-video-link {
  border: none;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video .video-frame a.load-video-link.video-link-loaded {
  display: none;
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video .video-frame a.load-video-link picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video .video-frame a.load-video-link picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video .video-frame a.load-video-link span.dog-icon {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
#testimonials-view #testimonials-list .testimonial-card .video-details-video .video-frame iframe {
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 14px;
}
#testimonials-view #testimonials-list #load-more-testimonials {
  display: flex;
  margin: 40px auto;
  max-width: 240px;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
}
#testimonials-view .accordion {
  padding: 8px 0px 0px 4px;
  position: relative;
  margin-bottom: 60px;
}
#testimonials-view .accordion .accordion-body {
  padding: 0px;
}
#testimonials-view .accordion .accordion-item {
  border: none;
  position: relative;
  margin-bottom: 35px;
  border-bottom: 1px solid #DBB378;
}
#testimonials-view .accordion .accordion-item h2, #testimonials-view .accordion .accordion-item .h2 {
  color: #134472;
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  font-weight: 700;
  position: relative;
}
#testimonials-view .accordion .accordion-item h2 .accordion-button, #testimonials-view .accordion .accordion-item .h2 .accordion-button {
  font-weight: 700;
  color: #134472;
  font-size: 1rem;
  padding: 0px 0 16px 50px;
  margin: 0px;
  outline: none;
  background-color: transparent;
  display: flex;
  width: 100%;
  min-height: 50px;
  align-content: center;
  align-items: center;
  position: relative;
  transition: all 0s ease-in-out;
}
#testimonials-view .accordion .accordion-item h2 .accordion-button:after, #testimonials-view .accordion .accordion-item .h2 .accordion-button:after {
  position: absolute;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  background-color: #134472;
  left: 0px;
  top: 0px;
  background-position: center center;
}
#testimonials-view .accordion .accordion-item .accordion-body {
  padding: 16px 50px 30px;
}
@media (max-width: 767.98px) {
  #testimonials-view .accordion .accordion-item .accordion-body {
    padding: 16px 4px 30px;
  }
}
#testimonials-view .accordion .accordion-item .accordion-body p, #testimonials-view .accordion .accordion-item .accordion-body li {
  font-size: 1rem;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: #134472;
  margin-bottom: 16px;
}
#testimonials-view .accordion .accordion-item .accordion-body ul, #testimonials-view .accordion .accordion-item .accordion-body ol {
  padding-left: 0px;
  list-style-position: inside;
}
#testimonials-view .accordion .accordion-item .accordion-body ul {
  list-style: none;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video {
  background: #134472;
  border-radius: 14px;
  border: none;
  padding: 28px 44px;
  color: #DFDCC3;
  max-width: 620px;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-title {
  margin-bottom: 6px;
  width: 100%;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-title h2, #testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-title .h2 {
  color: #DFDCC3;
  font-weight: 600px;
  font-size: 1.125rem;
  padding: 0;
  margin: 0px;
}
@media (max-width: 767.98px) {
  #testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-title h2, #testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-title .h2 {
    padding: 0;
    text-align: center;
    margin: 0px auto 12px;
  }
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .vide-text-holder {
  display: flex;
  width: 100%;
  flex-direction: column;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .vide-text-holder .testimonial-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 12px;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .vide-text-holder .testimonial-row .testimonial-description p {
  color: #DFDCC3;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .vide-text-holder .testimonial-row .testimonial-date {
  margin-left: auto;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .vide-text-holder .testimonial-row .testimonial-date label {
  text-align: right;
  margin: 0px;
  color: #DFDCC3;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .vide-text-holder .stars-holder {
  display: flex;
  margin-right: 12px;
  margin-bottom: 24px;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .vide-text-holder .stars-holder span {
  margin-right: 6px;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video {
  max-width: 680px;
  margin: 0 auto;
  position: relative;
  padding: 0;
}
@media (max-width: 1199.98px) {
  #testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video {
    padding-left: 0px;
  }
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video .video-frame {
  border-radius: 14px;
  position: relative;
  padding-top: 56.25%;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video .video-frame a.load-video-link {
  border: none;
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video .video-frame a.load-video-link.video-link-loaded {
  display: none;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video .video-frame a.load-video-link picture {
  width: 100%;
  height: 100%;
  display: flex;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video .video-frame a.load-video-link picture img {
  object-fit: cover;
  height: auto;
  width: 100%;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video .video-frame a.load-video-link span.dog-icon {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-details-video .video-frame iframe {
  border: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 14px;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-duration {
  width: 100%;
  align-self: center;
  align-items: center;
  align-content: center;
  display: flex;
  padding: 20px 0px;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-duration span.dog-icon {
  text-align: center;
  margin-right: 8px;
  display: flex;
  justify-content: center;
}
#testimonials-view .accordion .accordion-item .accordion-body .faq-video .video-duration label {
  margin: 0;
  font-weight: 700;
  font-size: 0.8125rem;
  color: #DFDCC3;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  line-height: 1;
}

.modal-content {
  background: #F8E2AF;
  border-radius: 12px;
}
.modal-content .form-group {
  margin-bottom: 30px;
  position: relative;
}
.modal-content textarea {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 2px solid #DBB378;
  border-radius: 25px;
  padding: 1rem;
  outline: none;
  color: #134472;
  font-weight: 500;
  font-size: 1rem;
  font-family: "Libre Franklin", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.modal-content .modal-footer {
  border: none;
}
.modal-content .modal-header {
  position: relative;
  flex-direction: column;
  padding: 52px 8px 40px;
  border: none;
}
.modal-content .modal-header .modal-title {
  text-align: center;
  color: #134472;
  font-size: 32px;
  font-family: "Lora", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  margin: 0px;
}
.modal-content .modal-header .modal-description {
  padding-top: 20px;
  text-align: center;
}
.modal-content .modal-header .modal-description p {
  color: #273443;
  font-size: 0.875rem;
  font-weight: 400;
}
.modal-content .modal-header .btn-close-modal {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 20px;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0px;
  background-color: transparent;
  outline: none;
  border: none;
}
.modal-content .modal-header .btn-close-modal span {
  border: 1px solid #134472;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}
.modal-content .modal-header .btn-close-modal:hover span, .modal-content .modal-header .btn-close-modal:focus span {
  background-color: #134472;
}
.modal-content .modal-header .btn-close-modal:hover span svg path, .modal-content .modal-header .btn-close-modal:focus span svg path {
  fill: #DEF0E7;
}
.modal-content .modal-body {
  border: none;
}
.modal-content .set-rating-stars {
  padding-left: 16px;
}
.modal-content .set-rating-stars .rating-stars {
  position: relative;
  display: block;
  width: 150px;
  height: 20px;
}
.modal-content .set-rating-stars .rating-stars #_1 {
  right: 120px;
}
.modal-content .set-rating-stars .rating-stars #_2 {
  right: 90px;
}
.modal-content .set-rating-stars .rating-stars #_3 {
  right: 60px;
}
.modal-content .set-rating-stars .rating-stars #_4 {
  right: 30px;
}
.modal-content .set-rating-stars .rating-stars #_5 {
  right: 0px;
}
.modal-content .set-rating-stars .rating-stars .rb {
  width: 30px;
  height: 20px;
  margin: 0px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  direction: rtl;
}
.modal-content .set-rating-stars .rating-stars .rb ~ span {
  width: 30px;
  text-align: center;
  float: right;
}
.modal-content .set-rating-stars .rating-stars .rb ~ .fas {
  display: none;
}
.modal-content .set-rating-stars .rating-stars .rb:checked ~ span.far, .modal-content .set-rating-stars .rating-stars .rb:hover ~ span.far {
  display: none;
}
.modal-content .set-rating-stars .rating-stars .rb:checked ~ span.fas, .modal-content .set-rating-stars .rating-stars .rb:hover ~ span.fas {
  display: block;
}
.modal-content .set-rating-stars .rating-stars .rb:checked ~ span {
  color: #3A76CE;
}
.modal-content .set-rating-stars .rating-stars .rb:hover ~ span {
  color: #3A76CE;
}
.modal-content .set-rating-stars .rating-stars .rb:hover ~ .rb:checked ~ span {
  color: #3A76CE;
}
.modal-content .set-rating-stars .rating-stars .rb:checked:hover ~ span {
  color: #3A76CE;
}
.modal-content .set-rating-stars .rating-stars .rb:checked:hover {
  cursor: default;
}
.modal-content .set-rating-stars .rating-stars .rb:checked ~ .rb:hover ~ span.fas {
  color: #D3DCE6;
}
.modal-content .set-rating-stars .rating-stars .rb:checked ~ .rb:hover ~ span.far {
  color: #3b3;
}