$container-max-widths: (
  sm: 1340px,
  md: 1350px,
  lg: 1360px,
  xl: 1380px,
  xxl: 1480px
);

$white:    #fff;
$gray-100: #F1F3F8;
$gray-200: #EBEEF6;
$gray-300: #E0E6ED;
$gray-400: #D3DCE6;
$gray-500: #B0BBCB;
$gray-600: #8492A5;
$gray-700: #3B4857;
$gray-800: #273443;
$gray-900: #273443;
$black:    #000;


$dark-blue:    #134472;
$light-blue:   #84D5F7;
$light-green:  #DEF0E7;
$yellow-arrows:  #DBB378;
$yellow-btn:  #FFD58E;
$yellow-headline:  #DFDCC3;
$yellow-bg:  #F8E2AF;


$blue:    #134472;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$primary:       $light-blue;
$secondary:     $yellow-btn;
$success:       $light-green;
$info:          $yellow-arrows;
$warning:       $yellow-headline;
$danger:        $red;
$light:         $light-green;
$dark:          $dark-blue;



$body-bg:       #F8E2AF;
$body-color:    $gray-800;


$link-color:                              $dark-blue;
$link-decoration:                         null;
$link-shade-percentage:                   0%;
$link-hover-color:                        $light-blue;
$link-hover-decoration:                   null;


$paragraph-margin-bottom:   0rem !default;


$font-family-sans-serif:      'Libre Franklin', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       'Lora', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;

$font-size-base:              1rem; 
$font-size-sm:                0.875rem;
$font-size-lg:                1.125rem;

$line-height-base:            1.5;
$line-height-sm:              1.5;
$line-height-lg:              1.5;


$small-font-size:             0.8125rem;
$sub-sup-font-size:           0.625rem;
$mark-bg:                     transparent;


$input-btn-padding-y:         0.625rem;
$input-btn-padding-x:         1.625rem;
$btn-white-space:             nowrap; 
$btn-border-width:            2px;
$btn-border-radius:           30px;
$btn-border-radius-sm:        30px;
$btn-border-radius-lg:        30px;


$input-padding-y:                       1rem;
$input-padding-x:                       1rem;
$input-font-family:                     $font-family-sans-serif;
$input-font-size:                       $font-size-base;
$input-font-weight:                     500;
$input-line-height:                     1;

$input-padding-y-sm:                    0.625rem;
$input-padding-x-sm:                    0.625rem;
$input-font-size-sm:                    $font-size-sm;

$input-padding-y-lg:                    1rem;
$input-padding-x-lg:                    1rem;
$input-font-size-lg:                    $font-size-base;

$input-bg:                              transparent;
$input-disabled-bg:                     $yellow-btn;
$input-disabled-border-color:           $yellow-arrows;

$input-color:                           $dark;
$input-border-color:                    $yellow-arrows;
$input-border-width:                    2px;
$input-box-shadow:                      0;

$input-border-radius:                   25px;
$input-border-radius-sm:                25px;
$input-border-radius-lg:                25px;

$input-focus-bg:                        transparent;
$input-focus-border-color:              tint-color($yellow-arrows, 50%);
$input-focus-color:                     $dark;
$input-focus-width:                     0rem;
$input-focus-box-shadow:                0;

$input-placeholder-color:               $yellow-arrows;
$input-plaintext-color:                 $yellow-arrows;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y);
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2);

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out;




$form-select-bg-size:               9px 5px; 
$form-select-indicator-color:       $input-color;
$form-select-indicator:             url("data:image/svg+xml,<svg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(#clip0)'><path d='M4.76692 4.88664C4.6195 5.03779 4.3805 5.03779 4.23308 4.88664L0.111319 0.66069C-0.126482 0.416879 0.0419378 0 0.378238 0L8.62176 0C8.95806 0 9.12648 0.416879 8.88868 0.66069L4.76692 4.88664Z' fill='#DBB378'/></g><defs><clipPath id='clip0'><rect width='9' height='5' fill='white'/></clipPath></defs></svg>");

$form-select-line-height:         1.3;
$form-select-bg:                  $yellow-bg;
$form-select-border-width:        2px;
$form-select-border-color:        $yellow-arrows;
$form-select-border-radius:       25px;
$form-select-box-shadow:          0;

$form-select-focus-border-color:  tint-color($yellow-arrows, 50%);
$form-select-focus-width:         0rem;
$form-select-focus-box-shadow:    0;




$nav-link-padding-y:          null;
$nav-link-padding-x:          null;


// Navbar

$navbar-padding-y:            null;
$navbar-padding-x:            null;



// Pagination

$pagination-padding-y:              .375rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;

$pagination-color:                  $dark-blue;
$pagination-bg:                     $yellow-headline;
$pagination-border-color:           $yellow-arrows;

$pagination-focus-color:            $dark-blue;
$pagination-focus-bg:               $yellow-arrows;
$pagination-focus-box-shadow:       0;
$pagination-focus-outline:          0;

$pagination-hover-color:            $dark-blue;
$pagination-hover-bg:               $yellow-arrows;
$pagination-hover-border-color:     $yellow-arrows;

$pagination-active-color:           $dark-blue;
$pagination-active-bg:              $yellow-arrows;
$pagination-active-border-color:    $yellow-arrows;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;

$pagination-border-radius-sm:       0;
$pagination-border-radius-lg:       0;


// Breadcrumbs

$breadcrumb-font-size:              $font-size-sm;
$breadcrumb-padding-y:              0;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding-x:         .5rem;
$breadcrumb-margin-bottom:          2rem;
$breadcrumb-bg:                     null;
$breadcrumb-divider-color:          #B3B39D;
$breadcrumb-active-color:           #869390;
$breadcrumb-divider:                quote("›");
$breadcrumb-divider-flipped:        $breadcrumb-divider;
$breadcrumb-border-radius:          null;


// Accordion
$accordion-padding-y:                     1rem;
$accordion-padding-x:                     0;
$accordion-color:                         $light;
$accordion-bg:                            transparent;
$accordion-border-width:                  0;
$accordion-border-color:                  transparent;
$accordion-border-radius:                 0;

$accordion-body-padding-y:                $sub-sup-font-size;
$accordion-body-padding-x:                0;

$accordion-button-padding-y:              1rem;
$accordion-button-padding-x:              0;
$accordion-button-color:                  transparent;
$accordion-button-bg:                     transparent;
$accordion-transition:                    null;
$accordion-button-active-bg:              transparent;
$accordion-button-active-color:           $light;

$accordion-button-focus-border-color:     null;
$accordion-button-focus-box-shadow:       0;

$accordion-icon-width:                    $sub-sup-font-size;
$accordion-icon-color:                    $accordion-color;
$accordion-icon-active-color:             $accordion-button-active-color;
$accordion-icon-transition:               transform .2s ease-in-out;
$accordion-icon-transform:                rotate(90deg);


$accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' ><g><path fill='#{$accordion-icon-active-color}' d='M 13.121094 6.515625 L 4.234375 0.429688 C 3.824219 0.148438 3.410156 0 3.066406 0 C 2.402344 0 1.992188 0.535156 1.992188 1.425781 L 1.992188 14.574219 C 1.992188 15.46875 2.402344 16 3.066406 16 C 3.410156 16 3.816406 15.851562 4.226562 15.570312 L 13.121094 9.484375 C 13.691406 9.09375 14.007812 8.566406 14.007812 8 C 14.007812 7.433594 13.695312 6.90625 13.121094 6.515625 Z M 13.121094 6.515625 '/></g></svg>");
$accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' ><g><path fill='#{$accordion-icon-active-color}' d='M 13.121094 6.515625 L 4.234375 0.429688 C 3.824219 0.148438 3.410156 0 3.066406 0 C 2.402344 0 1.992188 0.535156 1.992188 1.425781 L 1.992188 14.574219 C 1.992188 15.46875 2.402344 16 3.066406 16 C 3.410156 16 3.816406 15.851562 4.226562 15.570312 L 13.121094 9.484375 C 13.691406 9.09375 14.007812 8.566406 14.007812 8 C 14.007812 7.433594 13.695312 6.90625 13.121094 6.515625 Z M 13.121094 6.515625 '/></g></svg>");




// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-vertical-align:   middle;
$table-border-color:          $dark;
