#testimonials-view{
	position: relative;
	.page-title-hld{
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
		margin-bottom: 40px;
		@include media-breakpoint-down(sm) {
			flex-wrap: wrap;
		}
		.page-title{
			display: flex;
			@include media-breakpoint-down(sm) {
				margin-bottom: 12px;
			}
			h1{
				color: $dark;
				font-size: 32px;
				font-family: $font-family-monospace;
				margin: 0px;
			}
		}
		.page-popup{
			margin-left: auto;
			a{

			}
		}
	}
	#testimonials-list{
		position: relative;
		margin-bottom: 80px;
		.testimonial-card{
			background: $dark;
			border-radius: 14px;
			border: none;
			padding: 28px 44px;
			&.testimonial-with-image{
				display: flex;
				width: 100%;
				flex-direction: row;
				padding: 24px;
				@include media-breakpoint-down(md) {
					flex-direction: column;					
				}
				.testimonial-picture{
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					align-content: center;
					max-width: 45%;
					min-width: 45%;
					margin-right: 24px;
					border-radius: 14px;
					overflow: hidden;
					@include media-breakpoint-down(md) {
						max-width: 100%;
						min-width: 100%;	
						margin-right: auto;
						margin-bottom: 24px;				
					}
					picture{
					    width: 100%;
					    height: 100%;
					    display: flex;	
					    border-radius: 14px;		 
						img{
							object-fit: cover; 
						    height: auto;
						    width:100%;
						    border-radius: 14px;
						}
					}
				}
				.testimonial-text-holder{
					display: flex;
					flex-direction: column;
				}
			}
			&.contact{
				background: $light-blue;
				.testimonial-title{
					h2{
						color: $dark;
					}
				}
				.testimonial-text-holder{
					.testimonial-row{
						.testimonial-description{
							p{
								color: $dark;
							}
						}
						.testimonial-date{
							label{
								color: $dark;
							}
						}
					}
				}
			}
			.testimonial-title{
				margin-bottom: 6px;
				width: 100%;
				h2{
					color: $yellow-headline;
					font-weight: 600;					
					font-size: $font-size-base;	
					padding: 0;
					margin: 0px;
					color: $yellow-headline;
					font-family: $font-family-monospace;
					@include media-breakpoint-down(md) {
						padding: 0;
						text-align: center;
						margin: 0px auto 12px;						
					}
				}
			}
			.testimonial-text-holder{
				display: flex;
				width: 100%;
				flex-direction: column;
				.testimonial-row{
					display: flex;
					justify-content: space-between;
					margin-bottom: 8px;
					.testimonial-description{
						p{
							color: $yellow-headline;
						}
					}
					.testimonial-date{
						margin-left: auto;
						label{
							text-align: right;
							margin: 0px;
							color: $yellow-headline;
							font-size: $small-font-size;
						}
					}
				}
				.stars-holder{
					display: flex;
					margin-right: 12px;
					margin-bottom: 8px;
					span{
						margin-right: 6px;
					}
				}
			}
			.testimonial-body{
				display: flex;
				width: 100%;
				flex-direction: column;
				margin-top: 12px;
				p,li{
					font-size: $font-size-sm;
					font-family: $font-family-sans-serif;
					color: $yellow-headline;
					margin-bottom: 16px;
				}
				ul, ol{
					padding-left: 0px;
					list-style-position: inside;
				}
				ul{
					list-style: none;
				}
			}
			.video-details-video{
				max-width: 680px;
				margin: 0 auto;
				position: relative;
				padding: 0;	
				@include media-breakpoint-down(xl){
					padding-left: 0px;				
				}
				.video-frame{
					border-radius: 14px;
					position: relative;
					padding-top: 56.25%;
					a.load-video-link{
						border: none;
						position: absolute;
						top: 0;
						left: 0px;
						height: 100%;
						width: 100%;
						border-radius: 14px;
						display: flex;
						justify-content: center;
						align-items: center;
						align-content: center;
						cursor: pointer;
						overflow: hidden;
						outline: none;
						&.video-link-loaded{
							display: none;
						}
						picture{
						    width: 100%;
						    height: 100%;
						    display: flex;			 
							img{
								object-fit: cover; 
							    height: auto;
							    width:100%;
							}
						}
						span.dog-icon{
							width: 90px;
							height: 90px;
							position: absolute;
							top: 0px;
							left: 0px;
							bottom: 0px;
							right: 0px;
							margin: auto;
							text-align: center;
							cursor: pointer;
						}
					}
					iframe{
						border: none;
						position: absolute;
						top: 0;
						height: 100%;
						width: 100%;
						border-radius: 14px;
					}
				}
			}

		}
		#load-more-testimonials{
			display: flex;
			margin: 40px auto;
			max-width: 240px;
			text-align: center;
			justify-content: center;
			align-items: center;
			align-content: center;

		}
	}
	.accordion{
		padding: 8px 0px 0px 4px;
		position: relative;
		margin-bottom: 60px;
		.accordion-body{
			padding: 0px;
		}
		.accordion-item{
			border:none;
			position: relative;
			margin-bottom: 35px;
			border-bottom: 1px solid $yellow-arrows;
			h2{
				color: $dark;
				font-size: $font-size-base;
				padding: 0px;
				margin: 0px;
				font-weight: 700;
				position: relative;				
				.accordion-button{
					font-weight: 700;
					color: $dark;
					font-size: $font-size-base;
					padding: 0px 0 16px 50px;
					margin: 0px;
					outline: none;
					background-color: transparent;
					display: flex;
					width: 100%;
					min-height: 50px;
					align-content: center;
					align-items: center;
					position: relative;
					transition: all 0s ease-in-out;
					&:after{
						position:absolute;
						width:32px;
						height: 32px;
						display: flex;
						justify-content: center;
						border-radius: 4px;
						background-color: $dark;
						left: 0px;
						top: 0px;
						background-position: center center;
					}
				}


				
			}
			.accordion-body{
				padding: 16px 50px 30px;
				@include media-breakpoint-down(md) {
					padding: 16px 4px 30px;					
				}
				p,li{
					font-size: $font-size-base;
					font-family: $font-family-sans-serif;
					color: $dark;
					margin-bottom: 16px;
				}
				ul, ol{
					padding-left: 0px;
					list-style-position: inside;
				}
				ul{
					list-style: none;
				}
				.faq-video{					
					background: $dark;
					border-radius: 14px;
					border: none;
					padding: 28px 44px;
					color: $yellow-headline;
					max-width: 620px;
					.video-title{
						margin-bottom: 6px;
						width: 100%;
						h2{
							color: $yellow-headline;
							font-weight: 600px;					
							font-size: $font-size-lg;	
							padding: 0;
							margin: 0px;
							@include media-breakpoint-down(md) {
								padding: 0;
								text-align: center;
								margin: 0px auto 12px;						
							}
						}
					}
					.vide-text-holder{
						display: flex;
						width: 100%;
						flex-direction: column;
						.testimonial-row{
							display: flex;
							justify-content: space-between;
							align-items: center;
							align-content: center;
							margin-bottom: 12px;
							.testimonial-description{
								p{
									color: $yellow-headline;
								}
							}
							.testimonial-date{
								margin-left: auto;
								label{
									text-align: right;
									margin: 0px;
									color: $yellow-headline;
								}
							}
						}
						.stars-holder{
							display: flex;
							margin-right: 12px;
							margin-bottom: 24px;
							span{
								margin-right: 6px;
							}
						}
					}
					.video-details-video{
						max-width: 680px;
						margin: 0 auto;
						position: relative;
						padding: 0;	
						@include media-breakpoint-down(xl){
							padding-left: 0px;				
						}
						.video-frame{
							border-radius: 14px;
							position: relative;
							padding-top: 56.25%;
							a.load-video-link{
								border: none;
								position: absolute;
								top: 0;
								left: 0px;
								height: 100%;
								width: 100%;
								border-radius: 14px;
								display: flex;
								justify-content: center;
								align-items: center;
								align-content: center;
								cursor: pointer;
								overflow: hidden;
								outline: none;
								&.video-link-loaded{
									display: none;
								}
								picture{
								    width: 100%;
								    height: 100%;
								    display: flex;			 
									img{
										object-fit: cover; 
									    height: auto;
									    width:100%;
									}
								}
								span.dog-icon{
									width: 90px;
									height: 90px;
									position: absolute;
									top: 0px;
									left: 0px;
									bottom: 0px;
									right: 0px;
									margin: auto;
									text-align: center;
									cursor: pointer;
								}
							}
							iframe{
								border: none;
								position: absolute;
								top: 0;
								height: 100%;
								width: 100%;
								border-radius: 14px;
							}
						}
					}
					.video-duration{
						width: 100%;
						align-self: center;
						align-items: center;
						align-content: center;
						display: flex;
						padding: 20px 0px;
						span.dog-icon{
							text-align: center;
							margin-right: 8px;
							display: flex;
							justify-content: center;
						}
						label{
							margin: 0;
							font-weight: 700;
							font-size: $small-font-size;
							color: $yellow-headline;
							display: flex;
							justify-content: center;
							white-space: nowrap;
							line-height: 1;
						}
					}
				
				}
			}

		}
	}
}



.modal-content{
	background: $yellow-bg;
	border-radius: 12px;
	.form-group{
		margin-bottom: 30px;
		position: relative;
	}
	textarea{
		display: block;
		width: 100%;
		background-color: transparent;
		border: 2px solid $yellow-arrows;
		border-radius: 25px;
		padding: 1rem;
		outline: none;
		color: $dark;
		font-weight: 500;
		font-size: $font-size-base;
		font-family: $font-family-sans-serif;
	}
	.modal-footer{
		border: none;
	}
	.modal-header{
		position: relative;
		flex-direction: column;
		padding: 52px 8px 40px;
		border: none;
		.modal-title{
			text-align: center;
			color: $dark;
			font-size: 32px;
			font-family: $font-family-monospace;
			margin: 0px;
		}
		.modal-description{
			padding-top: 20px;
			text-align: center;
			p{
				color: $gray-800;
				font-size: $font-size-sm;
				font-weight: 400;
			}
		}		
		.btn-close-modal{
			position: absolute;
			width: 40px;
			height: 40px;
			right: 20px;
			top: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			padding: 0px;
			background-color: transparent;
			outline: none;
			border: none;
			span{
				border: 1px solid $dark;
				border-radius: 100%;
				display: flex;
				justify-content: center;
				width: 100%;
				height: 100%;
				align-content: center;
				align-items: center;
				background-color: transparent;
				transition: all 0.4s ease-in-out;
			}
			&:hover,
			&:focus{
				span{
					background-color: $dark;
					svg{
						path{
							fill: $light;
						}
					}
				}
			}

		}
	}
	.modal-body{
		border: none;
	}
	.set-rating-stars{
		padding-left: 16px;
		.rating-stars{
			position: relative;
			display: block;
			width: 150px;
			height: 20px;							
			#_1 {
			  right: 120px;
			}
			#_2 {
			  right: 90px;
			}
			#_3 {
			  right: 60px;
			}
			#_4 {
			  right: 30px;
			}
			#_5 {
			  right: 0px;
			}
			.rb {
			  width: 30px;
			  height: 20px;
			  margin: 0px;
			  position: absolute;
			  opacity: 0;
			  cursor: pointer;
			  z-index: 1;
			  direction: rtl;
			  ~ span{
			  	width: 30px;
			  	text-align: center;						
			  	float: right;
			  }
			  ~ .fas{
			  	display: none;
			  }
			  &:checked ~ span.far,						
			  &:hover	~ span.far{
			  	display:none;
			  }	
			  &:checked ~ span.fas,						
			  &:hover	~ span.fas{
			  	display:block;
			  }	
			}

			.rb:checked ~ span {
			  color: #3A76CE;
			}

			.rb:hover ~ span {
			  color: #3A76CE;
			}

			.rb:hover ~ .rb:checked ~ span {
			  color: #3A76CE;
			}

			.rb:checked:hover ~ span {
			  color: #3A76CE;
			}

			.rb:checked:hover {
			  cursor: default;
			}

			.rb:checked ~ .rb:hover ~ span.fas {
			  color: #D3DCE6;
			}
			.rb:checked ~ .rb:hover ~ span.far {
			  color: #3b3;
			}
		}
	}
}