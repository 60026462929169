#page-body{opacity: 1 !important; visibility: visible !important;}

#top-header{
	position: relative;
	z-index: 2;
	width: 100%;
	.navbar-brand{
		margin: 0px;
		padding:1.75rem 2.75rem 1.75rem 0;
		min-width: 225px;
		img{
			max-width: 100%;
			max-height: 100%;
		}
		@include media-breakpoint-down(lg) {
			padding:1rem 0.75rem 1rem 0;
		}
		@include media-breakpoint-down(sm) {
			padding:0;
			min-width: 100px;
		}
	}
	aside{
		@include media-breakpoint-up(lg) {
			min-width: 350px;
		}
		@include media-breakpoint-down(sm) {
			position: fixed;
			width: 100%;
			height: 100%;
			background-color: $dark;
			color: $light;
			left: 0px;
			top: 0px;
			z-index: 2;
			transition: all 0.6s ease-in-out;
		}
	}
	.navbar-toggler{
		padding: 0px;
		outline: none;
		border: none;
		width: 100%;
		text-align: center;
		border-radius: 0px;
		&#mobile-full-close{
			
		}
		&:hover,
		&:focus,
		&:active{
			box-shadow: none;
			outline: none;
			border: none;
			background-color: transparent;
		}
	}
	.login-button-box{
		font-weight: 600;
		font-size: $small-font-size;
		outline: none;
		&.mobile-color{
			padding-right: 0px;
			min-width: 40px;
		}
		@include media-breakpoint-up(md) {
			span{
				display: none;
			}
		}
		@include media-breakpoint-down(md) {
			border: none;
			&:hover,
			&:focus,
			&:active{
				background-color: transparent;
			}
			label{
				display: none;
			}
		}
	}
	.navbar{
		@include media-breakpoint-up(sm) {
		    flex-direction: row;
		    flex-wrap: nowrap;
		    width: 100%;		    
			.navbar-nav{
			    flex-direction: row;
			    width: 100%;
			    justify-content: flex-end;
			 }
			.nav-item{
				padding: 0 0.75rem;
				.nav-link{
					font-size: $font-size-base;
					font-weight: 700;
					color: $dark;
					padding: 0px;
					position: relative;
					transition: all 0.6s ease-in-out;
					&:after{
						content:"";
						height: 2px;
						bottom: -3px;
						position: absolute;
						background-color: transparent;
						width: 110%;
						left: -2%;
					}
					&.active,
					&:hover,
					&:focus{
						color: shade-color($dark, 80%);
						&:after{
							background-color: shade-color($dark, 80%);
						}

					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			height: 100%;
			padding: 0px;
			.helper-menu{
				width: 100%;
				padding:0 16px;
			}
			.navbar-nav{
				margin: auto;
				height: calc(100% - 160px);
				padding: 0 0 1rem 0;
				.nav-item{
					padding:0.75rem;
					margin-bottom: 40px;
					.nav-link{
						font-size: $font-size-base;
						font-weight: 700;
						color: $light;
						position: relative;
						text-align: center;
						transition: all 0.6s ease-in-out;
						&:after{
							content:"";
							height: 2px;
							bottom: -3px;
							position: absolute;
							background-color: transparent;
							width: 110%;
							left: -2%;
						}
						&.active,
						&:hover,
						&:focus{
							color: shade-color($light, 80%);
							&:after{
								background-color: shade-color($light, 80%);
							}

						}
					}
				}
			}
		}
	}
}


.footer-content{
	width: 100%;
	background-color: $dark;
	padding: 44px 0px;
	.footer-row{
		width: 100%;
		> .col-12{
			align-items:center;
			align-content:center;
		}
		.footer-navbar{
			padding: 0px;
			margin: 0px;
			list-style: none;
			display: flex;
			color: $light;	
			height: 100%;
			align-items:center;
			align-content:center;
			.form-control{
				min-height:24px;
				font-size: $font-size-sm !important;
				background-color: $body-bg !important;
				color:$dark-blue !important;
				outline: none !important;
				box-shadow:none !important;
				&:hover,
				&:focus{
					font-size: $font-size-sm !important;
					border-color: $yellow-arrows !important;
					background-color: $yellow-arrows !important;
					color:$dark-blue !important;
				}
				&::-webkit-input-placeholder {
				    color: $dark-blue !important;
				}
				&::-moz-placeholder {
				    color: $dark-blue !important;
				}
				&:-ms-input-placeholder {
				    color: $dark-blue !important;
				}
				&:-moz-placeholder {
				    color: $dark-blue !important;
				}
			}		
			.nav-item{
				margin-right: 42px;
				.nav-link{
					padding: 0px;
					color: $light;
					font-weight: 700;
					&:hover,
					&:focus{
						color:$white;
					}
				}
			}
			&.justify-content-end{
				.nav-item{
					margin-right: 0px;
					margin-left: 42px;
					.nav-link{
						font-weight: 500;
					}
				}
			}
			@include media-breakpoint-down(md) {
				flex-direction: column;
				.nav-item{
					margin: 12px auto !important;
					width: 100%;
					text-align: center;
				}
			}
		}
		.copyright{
			margin-top: 36px;
			padding: 0px;
			color: $light;
			@include media-breakpoint-down(md) {
				text-align: center;
				width: 100%;
			}
		}
	}
}


.breadcrumb-list{
	@include media-breakpoint-down(sm) {
		overflow: hidden;
		width: 100%;
		nav{
			overflow: auto;
			max-width: 100%;
			width: auto;
			ol.breadcrumb{
				flex-wrap: nowrap;			
				.breadcrumb-item{
					display: flex;
					flex-wrap: nowrap;
					white-space: nowrap;
					a{
						white-space: nowrap;
					}
				}
			}
		}
	}
}


.grecaptcha-badge { visibility: hidden; }
.search-title{
	width: 100%;
	position: relative;
	text-align: left;
	margin-bottom: 24px;
	h3{
		color: $yellow-headline;
		font-size: 24px;
		font-family: $font-family-monospace;
		margin: 0px auto 24px;
		max-width: 100%;
		@include media-breakpoint-down(md) {
			font-size: 24px;
		}
		span,label{
			margin: 0 4px 0 0px;
		}
	}
	p{
		color: $yellow-headline;
	}
}