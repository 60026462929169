.sidebar-account{
	position: relative;
	width: 100%;
	.sidebar-content{
		display: flex;
		max-width: 280px;
		flex-direction: column;
		width: 100%;
		background: $dark;
		border-radius: 14px;
		color: $light;
		padding: 44px 8px;
		@include media-breakpoint-down(sm) {
			margin: 0 auto 40px;
		}
		.sidebar-image-hld{
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0px;
			.sidebar-image{
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: center;
				margin-bottom: 24px;
				.image-wrapper{
					margin: auto;
					width: 52px;
					height: 52px;
					border-radius: 100%;
					border: 1px solid $light;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					picture{
					  width: 100%;
					  height: 100%;
					  display: flex;					 
						img{
							object-fit: cover; 
						    height: auto;
						    width:100%;
						}
					}
				}
			}
		}
		.sidebar-user{
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0 8px;
			margin-bottom: 24px;
			label{
				font-weight: 700;
				font-size: $font-size-sm;
				color: $light;
				text-align: center;
			}
			mark{
				font-size: $font-size-sm;
				color: $light;
				text-align: center;
			}			
		}
		.sidebar-menu{
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0 8px;
			margin-bottom: 24px;
			ul{
				width: 100%;
				list-style: none;
				padding: 0px;
				li{
					width: 100%;
					text-align: center;
					margin-bottom: 6px;
					a{
						display: block;
						text-align: center;
						padding: 6px 0px 0px;
						font-weight: 700;
						font-size: $font-size-sm;
						color: $light-blue;
						span{
							font-weight: 700;
							font-size: $font-size-sm;
							color: $light-blue;
							position: relative;
							transition: all 0.4s ease-in-out;
							&:before{
									content: "●";
									font-size: $font-size-lg;
									padding-right: 8px;
									font-weight: 700;
									color: $light-blue;
									opacity: 0;
									visibility: hidden;
								}
								&:after{
									content: "●";
									font-size: $font-size-lg;
									padding-left: 8px;
									font-weight: 700;
									color: $light-blue;
									opacity: 0;
									visibility: hidden;
								}
						}
						&.active,
						&:hover,
						&:focus{
							span{
								&:before{
									opacity: 1;
									visibility: visible;
								}
								&:after{
									opacity: 1;
									visibility: visible;
								}
							}

						}
					}
				}
			}
		}
		.sidebar-logout{
			display: flex;
			width: 100%;
			justify-content: center;
			align-items: center;
			margin-top: auto;
			a{
				display: block;
				text-align: center;
				padding: 6px 0px 0px;
				font-weight: 700;
				font-size: $font-size-sm;
				color: $yellow-headline;
			}
		}
	}
	
}


.account-image-full{
	display: flex;
	width: 100%;
	align-items: center;
	align-content: center;
	margin-bottom: 24px;
	.account-image{
		margin: auto 0;
		width: 110px;
		min-width: 110px;
		height: 110px;
		min-height: 110px;
		border-radius: 100%;
		border: 2px solid $dark;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		picture{
		  width: 100%;
		  height: 100%;
		  display: flex;					 
			img{
				object-fit: cover; 
			    height: auto;
			    width:100%;
			}
		}
	}
	.account-image-buttons{
		display: flex;
		width: 100%;
		align-items: center;
		align-content: center;
		margin-left: 40px;
	}
}



#terms{
	margin-bottom: 60px;
	h1{
		margin: 40px auto 60px;
		text-align: center;
		
		font-size: 28px;
		line-height: 1.4;
		color: $dark;
	}
	h2{
		
		font-size: 20px;
		line-height: 1.4;
		color: $dark;
		margin: 60px auto 30px;
	}
	h3{
		
		margin: 40px auto 16px;
		font-size: 18px;
		line-height: 1.4;
		color: $dark;
	}
	p,li{
		font-size: $font-size-base;
		line-height: 1.6;
		color: $dark;
		margin-bottom: 16px;
		
		a{
			color: $dark-blue;
			&:hover
			&:focus{
				color: $light-blue
			}
		}
		span.beacon-dog-icon{
			padding-bottom: 4px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
		}
	}
	ul{
		margin: 16px auto 16px;
		li{
			margin-bottom: 8px;
		}
	}
}
