#page-body{
	position: relative;
	z-index: 1;
	width: 100%;
	min-height:67vh;
	#frontpage-baner{
		margin-top: -160px;
		@include media-breakpoint-down(sm) {
			margin-top: -105px;
		}
		.full-width-image{
			width:100%;
			max-height: 900px;
			overflow: hidden;
			position: relative;
			@include media-breakpoint-down(sm) {
				max-height: 740px;
			}
			img{
				max-width:100%;
			    width:100%;
			    height:auto;
				/*object-fit: cover;*/
			}
			.banner-text-holder{
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				top: 0px;
				left: 0px;
				z-index: 2;
				@include media-breakpoint-down(sm) {
					align-items: flex-end;
					justify-content: center;
				}
				.banner-text{
					margin-left: auto;
					max-width: 435px;
					position: relative;
					text-align: center;
					background-color: rgba(19,68,114,0.7);
					border-radius: 14px;
					padding: 24px 16px;
					@include media-breakpoint-down(sm) {
						margin: 0 auto 30px;
						padding: 20px 8px;
					}
					p{
						color: $light;
						font-size: $font-size-lg;
						text-align: center;
						font-family: $font-family-monospace;
						margin-bottom: 28px;
					}
					.banner-text-btn{
						text-align: center;
						.btn{
							margin: 0 auto;
							font-weight: 700;
							color: $dark;
							min-width: 280px;
							display: flex;
							max-width: 280px;
							align-items: center;
							justify-content: center;
							align-content: center;
							font-weight: 700;
							span{
								margin-left: 8px;
							}
							@include media-breakpoint-down(sm) {
								min-width: 240px;
								font-size: $small-font-size;
							}
						}
					}
				}
			}
		}
	}
	#frontpage-daniel{
		margin-top: 0;
		position: relative;
		.full-width-image{
			width:100%;
			max-height: 900px;
			overflow: hidden;
			position: relative;
			@include media-breakpoint-down(sm) {
				max-height: 740px;
			}
			picture{
			  width: 100%;
			  height: 100%;
			  display: flex;
				img{
					object-fit: cover;
				    height: auto;
				    width:100%;
				}
			}
			.daniel-text-holder{
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				top: 0px;
				left: 0px;
				z-index: 2;
				@include media-breakpoint-down(sm) {
					align-items: flex-end;
					justify-content: center;
				}
				.daniel-text{
					margin-left: auto;
					max-width: 440px;
					position: relative;
					text-align: center;
					background-color: rgba(19,68,114,0.7);
					border-radius: 14px;
					padding: 56px;
					@include media-breakpoint-up(xxl) {
						margin-right: 2%;
					}
					@include media-breakpoint-down(xl) {
						padding: 24px 24px;
					}
					@include media-breakpoint-down(lg) {
						padding: 16px 16px;
					}
					@include media-breakpoint-down(sm) {
						margin: auto auto 10px;
						padding: 16px 12px;
					}
					p{
						color: $light;
						font-size: $font-size-lg;
						text-align: center;
						font-family: $font-family-monospace;
						margin-bottom: 28px;
						@include media-breakpoint-down(sm) {
							font-size: $font-size-base;
							margin-bottom: 16px;
						}
					}
					.banner-text-btn{
						text-align: center;
						.btn{
							margin: 0 auto;
							font-weight: 700;
							color: $dark;
							min-width: 280px;
							display: flex;
							max-width: 280px;
							align-items: center;
							justify-content: center;
							align-content: center;
							font-weight: 700;
							span{
								margin-left: 8px;
							}
							@include media-breakpoint-down(sm) {
								min-width: 240px;
								font-size: $small-font-size;
							}
						}
					}
				}
			}
		}
	}
	#frontpage-testimonials-list{
		margin-top: 0;
		position: relative;
		padding-bottom: 0px;
		background-color: $dark-blue;
		.testimonial-list-btn{
			position: absolute;
			z-index: 2;
			top: 44%;
			width: 54px;
			height: 54px;
			outline: none;
			border: none;
			box-shadow: none;
			transition: all 0.4s ease-in-out;
			background-color: transparent;
			text-align: center;
			padding: 0px;
			span{
				width: 54px;
				height: 54px;
				display: flex;
				justify-content: center;
				align-items: center;
				align-content: center;
				position: relative;
				border-radius: 100%;
				background-color: $yellow-arrows;
				transition: all 0.4s ease-in-out;
			}
			&.prev{
				left: 40px;
				@include media-breakpoint-down(xxl) {
					top: 15%;
				}
				@include media-breakpoint-down(sm) {
					left: 0px;
				}
			}
			&.next{
				right: 40px;
				@include media-breakpoint-down(xxl) {
					top: 15%;
				}
				@include media-breakpoint-down(sm) {
					right: 0px;
				}
			}
			&:hover,
			&:focus
			&:active{
				outline: none;
				border: none;
				box-shadow: none;
				span{
					background-color: $yellow-btn;
				}
			}
		}
		.full-width-image{
			width:100%;
			max-height: 900px;
			overflow: hidden;
			position: relative;
			@include media-breakpoint-down(sm) {
				max-height: 740px;
			}
			picture{
			  width: 100%;
			  height: 100%;
			  display: flex;
				img{
					object-fit: cover;
				    height: auto;
				    width:100%;
				}
			}
			.testimonial-text-holder{
				position: absolute;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: flex-end;
				top: 0px;
				left: 0px;
				z-index: 2;
				@include media-breakpoint-down(sm) {
					align-items: flex-end;
					justify-content: center;
				}
				.testimonial-text{
					margin-left: auto;
					max-width: 440px;
					position: relative;
					text-align: center;
					background-color: rgba(19,68,114,0.7);
					border-radius: 14px;
					padding: 56px;
					margin-bottom: 52px;
					&.left-position{
						margin-left: 0px;
						margin-right: auto;
						@include media-breakpoint-up(xxl) {
							margin-left: 8%;
						}
					}
					@include media-breakpoint-up(xxl) {
						margin-right: 8%;
					}
					@include media-breakpoint-down(xl) {
						padding: 24px 24px;
					}
					@include media-breakpoint-down(lg) {
						padding: 16px 16px;
					}
					@include media-breakpoint-down(sm) {
						margin: auto auto 20px;
						padding: 16px 12px;
					}
					p{
						color: $light;
						font-size: $font-size-base;
						text-align: left;
						font-family: $font-family-monospace;
						margin-bottom: 28px;
						@include media-breakpoint-down(sm) {
							margin-bottom: 16px;
						}
					}
					.testimonial-stars{
						text-align: left;
						display: flex;
						align-content: center;
						align-items: center;
						justify-content: flex-start;
						align-self: flex-start;
						.stars-holder{
							display: flex;
							margin-right: 12px;
							span{
								margin-right: 6px;
							}
						}
						.testimonial-author{
							position: relative;
							label{
								margin: 0px;
								padding: 0px;
								font-weight: 500;
								color: $light;

							}
						}
					}
				}
			}
		}
	}
}



#frontpage-videos-list{
	padding: 80px 0px;
	position: relative;
	width: 100%;
	.slick-track
	{
	    display: flex !important;
	}
	.slick-slide
	{
	    height: inherit !important;
	    > div{
	    	height: 100% !important;
	    }
	}
	.videos-list-btn{
		position: absolute;
		z-index: 2;
		top: 120px;
		width: 54px;
		height: 54px;
		outline: none;
		border: none;
		box-shadow: none;
		transition: all 0.4s ease-in-out;
		background-color: transparent;
		text-align: center;
		padding: 0px;
		&.slick-disabled{
			display: none !important;
		}
		@include media-breakpoint-down(md) {
			top: 75px;
		}
		span{
			width: 54px;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			position: relative;
			border-radius: 100%;
			background-color: $yellow-arrows;
			transition: all 0.4s ease-in-out;
		}
		&.prev{
			left: 0px;
			@include media-breakpoint-up(xxl) {
				left: -10px;
			}
		}
		&.next{
			right: 0px;
			@include media-breakpoint-up(xxl) {
				right: -14px;
			}
		}
		&:hover,
		&:focus
		&:active{
			outline: none;
			border: none;
			box-shadow: none;
			span{
				background-color: $yellow-btn;
			}
		}
	}
	.video-box{
		position: relative;
		padding: 8px;
		height: 100%;
		@include media-breakpoint-down(md) {
			padding: 0px;
		}
		.video-box-explain{
			width: 100%;
			border-radius: 14px;
			background-color: $dark;
			display: flex;
			flex-direction: column;
			padding: 22px 32px;
			height: 100%;
			box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			@include media-breakpoint-down(xl) {
				padding: 22px 22px;
			}
			@include media-breakpoint-down(sm) {
				padding: 8px;
			}
			.video-box-top{
				display: flex;
				width: 100%;
				flex-direction: column;
				max-width: 500px;
				.videos-header{
					display: flex;
					width: 100%;
					margin-bottom: 44px;
					@include media-breakpoint-down(xl) {
						flex-wrap: wrap;
					}
					.videos-number{
						width: 60px;
						min-width: 60px;
						text-align: center;
						display: flex;
						align-self: flex-start;
						@include media-breakpoint-down(xl) {
							align-self: center;
							margin: auto;
							width: 100%;
						}
						span.dog-icon{
							color: $light;
							font-weight: 700;
							font-size: 84px;
							line-height: 1;
							@include media-breakpoint-down(xl) {
								margin: 0px auto;
							}
							@include media-breakpoint-down(sm) {
								margin: 20px auto;
							}
						}
					}
					.videos-name{
						display: flex;
						width: 100%;
						align-items: center;
						align-content: center;
						align-self: flex-end;
						@include media-breakpoint-up(xl) {
							height: 100%;
						}

						h3{
							color: $light;
							font-size: 29px;
							text-align: left;
							font-family: $font-family-monospace;
							padding: 12px 0px 0px 25px;
							margin: 0px;
							@include media-breakpoint-down(xl) {
								padding: 16px 0px 0px;
								margin: 0 auto;
							}
							@include media-breakpoint-down(md) {
								padding: 0;
								text-align: center;
								margin: 0px auto 12px;
								font-size: $font-size-lg;
							}
							@include media-breakpoint-between(sm, md) {
								padding: 16px 0px 0px;
								font-size: 24px;
							}
						}
					}
				}
				.videos-description{
					padding: 36px 0px 32px 0px;
					p{
						color: $light;
					}
					@include media-breakpoint-down(xl){
						padding-left: 0px;
					}
					.videos-content{
						margin: 16px 0 40px;
						display: flex;
						align-items: center;
						label{
							display: flex;
							align-items: center;
							margin-right: 32px;
							color: $light;
							font-weight: 700;
							span{
								margin-right: 8px;
							}
						}
					}
				}
			}
			.video-box-content{
				position: relative;
				max-width: 500px;
				margin-top: auto;
				@include media-breakpoint-down(xl){
					padding-left: 0px;
				}
				.video-frame{
					border-radius: 14px;
					position: relative;
					padding-top: 56.25%;
					width: 99.7%;
					a.load-video-link{
						border: none;
						position: absolute;
						top: 0;
						left: 0px;
						height: 100%;
						width: 100%;
						border-radius: 14px;
						display: flex;
						justify-content: center;
						align-items: center;
						align-content: center;
						cursor: pointer;
						overflow: hidden;
						outline: none;
						&.video-link-loaded{
							display: none;
						}
						picture{
						    width: 100%;
						    height: 100%;
						    display: flex;
							img{
								object-fit: cover;
							    height: auto;
							    width:100%;
							}
						}
					}
				}
				iframe{
					border: none;
					position: absolute;
					top: 0;
					height: 100%;
					width: 100%;
					border-radius: 14px;
				}
			}
		}

	}
	#videos-details{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		opacity: 0;
		visibility: hidden;
		z-index: -1;
		transition: all 0.6s ease-in-out;
		min-height: 100%;
		&.active{
			opacity: 1;
			visibility: visible;
			z-index: 3;
		}
		@include media-breakpoint-down(sm) {
			> .container{
				padding: 0 6px;
			}
		}
		.videos-list-btn{
			top: 40%;
			z-index: 4;
			@include media-breakpoint-down(md) {
				top: 75px;
			}
		}
		.close-details-view{
			position: absolute;
			width: 42px;
			height: 42px;
			right: 25px;
			top: 25px;
			z-index: 2;
			@include media-breakpoint-down(sm) {
				right: 30px;
				top: 12px;
			}
		}
	}
	.video-details-section{
		position: relative;
		padding: 6px;
		width: 100%;
		min-height: 100%;
		height: 100%;
		.video-details-explain{
			width: 100%;
			border-radius: 14px;
			background-color: $dark;
			display: flex;
			flex-direction: column;
			padding: 22px 32px;
			background: $dark;
			border-radius: 14px;
			height: 100%;
			box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-moz-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-webkit-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			-ms-box-shadow: 0px -1px 4px rgba(59, 72, 87, 0.2);
			@include media-breakpoint-down(xl) {
				padding: 22px 22px;
			}
			@include media-breakpoint-down(lg) {
				> .row{
					max-width: 640px;
					margin: 0 auto;
				}
			}
			@include media-breakpoint-down(sm) {
				padding: 0px;
			}
			.video-details-top{
				display: flex;
				width: 100%;
				flex-direction: column;
				max-width: 100%;
				.video-header{
					display: flex;
					width: 100%;
					@include media-breakpoint-down(xl) {
						flex-wrap: wrap;
					}
					.videos-number{
						width: 60px;
						min-width: 60px;
						text-align: center;
						display: flex;
						align-self: flex-start;
						@include media-breakpoint-down(xl) {
							align-self: center;
							margin: auto;
						}
						span.dog-icon{
							color: $light;
							font-weight: 700;
							font-size: 84px;
							line-height: 1;
							@include media-breakpoint-down(sm) {
								margin-bottom: 20px;
								margin-top: 20px;
							}
						}
					}
					.videos-name{
						display: flex;
						width: 100%;
						align-items: center;
						align-content: center;
						align-self: center;
						justify-content: center;
						h3{
							color: $light;
							font-size: 32px;
							text-align: center;
							font-family: $font-family-monospace;
							font-weight: 400;
							padding: 0px;
							margin: 0px;
						}
					}
				}
				.videos-description{
					padding: 36px 0px 12px 100px;
					p{
						color: $light;
					}
					@include media-breakpoint-down(xl){
						padding-left: 0px;

					}
					.videos-content{
						margin: 16px 0 0px;
						display: flex;
						align-items: center;
						label{
							display: flex;
							align-items: center;
							margin-right: 32px;
							color: $light;
							font-weight: 700;
							span{
								margin-right: 8px;
							}
						}
					}
				}
			}
			.video-details-video{
				max-width: 960px;
				margin: 0 auto 44px;
				position: relative;
				padding: 36px 0px 12px 100px;
				@include media-breakpoint-down(xl){
					padding-left: 0px;
				}
				.video-frame{
					border-radius: 14px;
					position: relative;
					padding-top: 56.25%;
					a.load-video-link{
						border: none;
						position: absolute;
						top: 0;
						left: 0px;
						height: 100%;
						width: 100%;
						border-radius: 14px;
						display: flex;
						justify-content: center;
						align-items: center;
						align-content: center;
						cursor: pointer;
						overflow: hidden;
						outline: none;
						&.video-link-loaded{
							display: none;
						}
						picture{
						    width: 100%;
						    height: 100%;
						    display: flex;
							img{
								object-fit: cover;
							    height: auto;
							    width:100%;
							}
						}
						span.dog-icon{
							width: 90px;
							height: 90px;
							position: absolute;
							top: 0px;
							left: 0px;
							bottom: 0px;
							right: 0px;
							margin: auto;
							text-align: center;
							cursor: pointer;
						}
					}
					iframe{
						border: none;
						position: absolute;
						top: 0;
						height: 100%;
						width: 100%;
						border-radius: 14px;
					}
				}
			}
			.video-details-content{
				padding-left: 60px;
				padding-top: 28px;
				position: relative;
				@include media-breakpoint-down(xl){
					padding-left: 0px;
				}
				.videos-cta-hld{
					margin-bottom: 32px;
					.btn{
						min-width: 230px;
					}
				}
				.accordion{
					padding: 8px 0px 0px 4px;
					position: relative;
					.accordion-body{
						padding: 0px;
						color: $light;
						p{
							margin-bottom: 28px;
						}
					}
					.accordion-item{
						border:none;
						position: relative;
						margin-bottom: 28px;
						.accordion-collapse{
							display: block;
						}
					}
				}

			}
		}

	}
}
